import { SunIcon } from '@chakra-ui/icons';
import { InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { SegmentType } from '../../../../types';

export default function SegmentTypeSelect ({ segmentType, setSegmentType }: { segmentType: SegmentType, setSegmentType: (arg0: SegmentType) => void }) {
  return (
    <InputGroup maxW='15rem' bg='orange.50'>
      <InputLeftElement pointerEvents='none'>
        <SunIcon />
      </InputLeftElement>
      <Select
        style={{ paddingInlineStart: '2.2rem' }}
        onChange={(e) => { setSegmentType(e.target.value as SegmentType); }}
        value={segmentType}
        aria-label='SegmentTypeSelect'
      >
        {/* TODO generate from list */}
        <option value={SegmentType.FUND_RECIPIENT}>Service Providers</option>
        <option value={SegmentType.FUND}>Funds</option>
        <option value={SegmentType.INVESTMENT}>Investments</option>
        <option value={SegmentType.VULNERABILITY_GROUP}>Vulnerability Groups</option>
        <option value={SegmentType.REGION}>Geographic Regions</option>
        <option value={SegmentType.ETHNIC_GROUP}>Ethnic Groups</option>
        <option value={SegmentType.STATE}>Service User Lifecycle</option>
        <option value={SegmentType.GENDER}>Genders</option>
        <option value={SegmentType.AGE_RANGE}>Age Ranges</option>
      </Select>
    </InputGroup>
  );
}

import { Button, Card, Container, Flex, Heading, SimpleGrid, Spacer, Stat, StatHelpText, StatLabel, StatNumber, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetFund } from '../../../hooks/useFunds';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import { type IFundExtended } from '../../../types';
import EditFundModal from './partials/EditFundModal';
import ManageInvestments from './partials/ManageInvestments';

export default function FundView () {
  const { id } = useParams();
  const editModal = useDisclosure();
  const { data, isLoading, isError, error, refetch } = useGetFund(id as string);

  useEffect(() => {
    if (!editModal.isOpen) {
      void refetch();
    }
  }, [editModal.isOpen]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const fund = data?.docs as IFundExtended;

  return (
    <Container py="4" maxW="6xl">
      <Link to="/portfolio/funds">&larr; Back to Funds</Link>

      <Flex my="4">
        <Heading>{fund.name}</Heading>
        <Spacer />
        <Button onClick={editModal.onOpen} size="sm" colorScheme="blue">Edit</Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" py="4">
        <Stat as={Card} p="4">
          <StatLabel>Total Invested</StatLabel>
          <StatNumber><Currency value={fund.sumInvestmentsValue} /></StatNumber>
          <StatHelpText>Fund Value: <Currency value={fund.value} /></StatHelpText>
        </Stat>
        <Stat as={Card} p="4">
          <StatLabel>Service Users</StatLabel>
          <StatNumber>{fund.countServiceUsers}</StatNumber>
          <StatHelpText>Investments: {fund.countInvestments}</StatHelpText>
        </Stat>
      </SimpleGrid>

      <ManageInvestments fund={fund} onChange={() => { void refetch(); }} />

      <EditFundModal fund={fund} disclosure={editModal} />

    </Container>
  );
}

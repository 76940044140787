import { Heading } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const slap = keyframes(`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.8) rotate(50deg); }
  55% { transform: scale(1.8) rotate(55deg); }
  60% { transform: scale(1.8) rotate(45deg); }
  65% { transform: scale(1.8) rotate(55deg); }
  70% { transform: scale(1.8) rotate(45deg); }
  75% { transform: scale(1.8) rotate(55deg); }
  80% { transform: scale(1.8) rotate(45deg); }
  100% { transform: scale(1) rotate(0deg); }
`);

const animation = `${slap} cubic-bezier(0.6, 0, 0.5, 1) 1.1s 1`;

export default function Wave () {
  return (
    <Heading
      ml={1}
      w={50}
      h={50}
      _hover={{
        animation,
        cursor: 'grab',
      }}
    >
        👋
    </Heading>
  );
}

import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box } from '@chakra-ui/react';

export default function DangerZone ({ children }: { children: React.ReactNode }) {
  return (
    <Accordion allowMultiple backgroundColor="red.50" >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left' color="red" fontWeight="bold">DANGER ZONE</Box>
          </AccordionButton>
        </h2>
        <AccordionPanel p={4}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

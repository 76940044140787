import { Box, Card, Heading, Link, Stack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useActivity } from '../../../../hooks/useActivity';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import Activity from '../../../../partials/output/Activity';

export default function ActivityWidget () {
  const { data, isLoading, error } = useActivity({ limit: 5 });

  if (isLoading) return <Loading />;
  if (error != null) return <ErrorAlert error={error} />;

  return (
    <Card p={4}>
      <Heading size='md'>Recent Activity</Heading>

      <Stack spacing={4} mt={4}>
        {data?.docs.map((activity) => (
          <Box key={activity.logId} mb={2}>
            <Activity activity={activity} />
          </Box>
        ))}
      </Stack>

      <Box textAlign='right'>
        <Link as={RouterLink} to='/activity'>View all activity</Link>
      </Box>
    </Card>
  );
}

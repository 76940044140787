import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { type IInvestmentExtended } from '../types';
import { apiClient, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

// All investments assigned to a recipient
export function useListInvestments () {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListInvestments', page],
    queryFn: async () => {
      const url = API_URL.fundRecipient.investments;
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IInvestmentExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

import { Avatar, Button, Container, Flex, Heading, Spacer, Table, Tbody, Td, Th, Tr, useDisclosure } from '@chakra-ui/react';
import { useListTeam } from '../../../hooks/useProfile';
import useSession from '../../../hooks/useSession';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import DateTime from '../../../partials/output/DateTime';
import Email from '../../../partials/output/Email';
import UserStateTag from '../../../partials/output/UserStateTag';
import { type IUserExtended } from '../../../types';
import InviteUserToTeamModal from './partials/InviteUserToTeamModal';

export default function MyTeam () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isError, error, refetch } = useListTeam();
  const { session } = useSession();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const users = data?.docs as IUserExtended[];

  const onSuccess = () => {
    void refetch();
    onClose();
  };

  return (
    <Container py="4" maxW="8xl">
      <Flex mb={4}>
        <Heading>My Team</Heading>
        <Spacer />
        {session?.organisation.isFundManager === true && (
          <Button onClick={onOpen} colorScheme="green">Invite User</Button>
        )}
      </Flex>

      <Table variant="simple" mt="4">
        <Tbody>
          <Tr>
            <Th pl="1"></Th>
            <Th>Name</Th>
            <Th>Login</Th>
            <Th>State</Th>
            <Th>Last Login At</Th>
            <Th pr="1"></Th>
          </Tr>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td pl="1">
                <Avatar size='sm' name={user.name} />
              </Td>
              <Td>{user.name}</Td>
              <Td><Email email={user.login} /></Td>
              <Td><UserStateTag state={user.state} /></Td>
              <Td><DateTime date={user.lastLoginAt} /></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <InviteUserToTeamModal
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Container>
  );
}

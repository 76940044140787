import { Box, Button, ButtonGroup, Center, Flex, Spacer, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import { type IAnalyticsSegment } from '../../../../types';
import paths from '../../Dashboard/partials/ukRegionsPaths.json';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

interface PathData {
  value: number
  path: {
    key: string
    label: string
    toolTip?: JSX.Element
    d: string
  }
}

interface RegionDefinition {
  label: string
  itemKey: keyof IAnalyticsSegment
  path: {
    key: string
    label: string
    toolTip?: JSX.Element
    d: string
  }
}

const REGIONS: RegionDefinition[] = [
  {
    label: 'North East',
    itemKey: 'countRegionNorthEast',
    path: paths.north_east
  },
  {
    label: 'North West',
    itemKey: 'countRegionNorthWest',
    path: paths.north_west
  },
  {
    label: 'Yorkshire & Humber',
    itemKey: 'countRegionYorkshireHumber',
    path: paths.yorkshire_and_the_humber
  },
  {
    label: 'East Midlands',
    itemKey: 'countRegionEastMidlands',
    path: paths.east_midlands
  },
  {
    label: 'West Midlands',
    itemKey: 'countRegionWestMidlands',
    path: paths.west_midlands
  },
  {
    label: 'East England',
    itemKey: 'countRegionEastEngland',
    path: paths.east_of_england
  },
  {
    label: 'London',
    itemKey: 'countRegionLondon',
    path: paths.london
  },
  {
    label: 'South East',
    itemKey: 'countRegionSouthEast',
    path: paths.south_east
  },
  {
    label: 'South West',
    itemKey: 'countRegionSouthWest',
    path: paths.south_west
  },
  {
    label: 'Scotland',
    itemKey: 'countRegionScotland',
    path: paths.scotland
  },
  {
    label: 'Northern Ireland',
    itemKey: 'countRegionNorthernIreland',
    path: paths.northern_ireland
  },
  {
    label: 'Wales',
    itemKey: 'countRegionWales',
    path: paths.wales
  },
];

function RegionsTable ({ row, isVisible }: { row: AnalyticsSegmentRange, isVisible: boolean }) {
  const max = Math.max(...REGIONS.map(region => row.d2[region.itemKey] as number));

  return (
    <Table variant='simple' size='sm' display={isVisible ? 'table' : 'none'} >
      <Thead>
        <Tr>
          <Th>Region</Th>
          <Th>Service Users</Th>
        </Tr>
      </Thead>
      <Tbody>
        {REGIONS.map(region => (
          <Tr key={region.label}>
            <Td>{region.label}</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={region.itemKey} max={max} barColor='#38A169' />
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function RegionPath ({ data, maxValue }: { data: PathData, maxValue: number }) {
  const [isHover, setIsHover] = useState(false);

  const baseRegionStyle = {
    fill: '#38A169',
    stroke: 'RGBA(0, 0, 0, 0.5)',
    strokeWidth: '1',
    cursor: 'pointer',
  };

  return (
    <Tooltip
      hasArrow
      label={
        <Box>
          <Text>{data.path.label}</Text>
          <Text>{data.value} Service Users</Text>
        </Box>
      }
    >
      <path
        d={data.path.d}
        id={data.path.key}
        style={{
          ...baseRegionStyle,
          fillOpacity: (data.value === 0) ? 0 : data.value / maxValue,
          strokeWidth: isHover ? '3' : '1',
          zIndex: isHover ? 9999 : 0,
        }}
        onMouseEnter={() => { setIsHover(true); }}
        onMouseLeave={() => { setIsHover(false); }}
      />
    </Tooltip>
  );
}

function RegionsMap ({ row, isVisible }: { row: AnalyticsSegmentRange, isVisible: boolean }) {
  const mapScale = 0.5;

  const regionPaths = REGIONS.map(region => ({
    path: region.path,
    value: row.d2[region.itemKey] as number,
  }));

  const maxValue = Math.max(...regionPaths.map(v => v.value));

  const rectStyle = {
    fill: 'none',
    stroke: 'RGBA(0, 0, 0, 0.5)',
    strokeWidth: '1',
  };

  return (
    <Box display={isVisible ? 'block' : 'none'}>
      <Center height={{ base: 'auto', md: '516px' }} role="figure" >
        <svg width={mapScale * 690} height={mapScale * 982} viewBox='0 0 690 982'>
          <rect width="180" height="275" x="469.79999" y="8" id="shetlands-orkneys-frame" style={rectStyle} />
          {regionPaths.map((d, i) => <RegionPath key={d.path.key} data={d} maxValue={maxValue} />)}
        </svg>
      </Center>
    </Box>
  );
}

export default function RegionChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const [showMap, setShowMap] = useState(true);

  return (
    <Stack spacing={4}>
      <Flex align='center'>
        <HeadingToolTip size='sm' label='Distribution of Service Users by UK regions'>UK Regions</HeadingToolTip>
        <Spacer />
        <ButtonGroup isAttached variant='outline'>
          <Button size='xs' isDisabled={showMap} onClick={() => { setShowMap(true); }} >Map</Button>
          <Button size='xs' isDisabled={!showMap} onClick={() => { setShowMap(false); }} >Table</Button>
        </ButtonGroup>
      </Flex>

      <RegionsTable row={row} isVisible={!showMap} />
      <RegionsMap row={row} isVisible={showMap} />
    </Stack >
  );
}

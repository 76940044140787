import axios, { type AxiosRequestConfig } from 'axios';
import { endSession, loadSession, saveSession } from '../hooks/useSession';
import { type AuthResponse } from '../types';
import { API_URL } from './constants';
import parseResponse from './parseResponse';

export interface APIItemResponse<Item> {
  docs: Item
};

export interface APIPaginatedResponse<Item> {
  docs: Item[]
  page: number
  pageSize: number
  pageCount: number
  totalCount: number
};

export interface APIPaginatedQuery extends AxiosRequestConfig {
  params: {
    page?: number
    pageSize?: number
  }
};

const axiosConfig = {
  timeout: 2500 // 2.5 seconds
};

// Client for API requests
export const apiClient = axios.create(axiosConfig);

// Client for token refresh only
const refreshClient = axios.create(axiosConfig);

// Refresh session using refresh token
export const refreshSession = async () => {
  const session = loadSession();
  const url = API_URL.auth.refreshToken;
  const response = await refreshClient.get<APIItemResponse<AuthResponse>>(url, {
    headers: { Authorization: `Bearer ${session?.refreshToken as string}` }
  });

  saveSession(response.data.docs);
  return response.data.docs;
};

// Request interceptor
apiClient.interceptors.request.use(async (config) => {
  let session = loadSession();
  if (session === null) return config;

  // If access token expired, refresh it
  if (session?.accessTokenExpiry < new Date()) {
    session = await refreshSession();
  }

  // Set access token in request header
  config.headers.Authorization = `Bearer ${session?.accessToken}`;
  return config;
});

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    response.data = parseResponse(response.data);
    return response;
  },
  async (error) => {
    if (error.response != null) {
      const errors = error.response.data.errors;
      const message = (errors.length === 1) ? errors[0].message : 'Bad Request';

      // If token is invalid, clear session
      [
        /invalid jwt/i,
        /token missing/i,
        /'malformed authorization header/i,
      ].some((msg) => msg.test(message)) && endSession();

      return await Promise.reject(new Error(message));
    }

    // Network error
    return await Promise.reject(error);
  }
);

import useSession from './useSession';

export default function usePermissions () {
  const { session } = useSession();
  return {
    isEnabled: session?.user.isEnabled ?? false,
    isRoot: session?.user.isRoot ?? false,
    isFundManager: session?.organisation.isFundManager ?? false,
    isFundRecipient: session?.organisation.isFundRecipient ?? false,
  };
}

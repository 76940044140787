import { Container, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSuccessToast } from '../../../hooks/useToast';
import ChangePasswordForm from './partials/ChangePasswordForm';

export default function ChangePassword () {
  const success = useSuccessToast();
  const navigate = useNavigate();

  const onSuccess = async () => {
    success('Password changed');
    navigate('/');
  };

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">Change Password</Heading>
      <ChangePasswordForm onSuccess={onSuccess}/>
    </Container>
  );
}

import { type EthnicGroupCode } from '../utils/ethnicGroups';
import { type VulnerabilityGroupCode } from '../utils/vulnerabilityGroups';
import { type ILocalAuthority } from './LocalAuthority';
import { type AgeRange, type GenderCode, type MoveOnCode } from './ServiceUser';

export enum ServiceUserState {
  AwaitingService = 'AWAITING_SERVICE',
  WithService = 'WITH_SERVICE',
  MovedOnPositive = 'MOVED_ON_POSITIVE',
  MovedOnNegative = 'MOVED_ON_NEGATIVE',
  MovedOnIndeterminate = 'MOVED_ON_INDETERMINATE',
}

export interface IServiceUserExtended {
  id: string
  versionId: string
  effectiveFrom: Date
  effectiveTo: Date | null
  fundRecipientId: string
  fundRecipientName: string
  userId: string
  userName: string
  userLogin: string
  userIsInactive: boolean
  userIsDeleted: boolean
  fundManagerId: string
  fundManagerName: string
  fundId: string
  fundName: string
  fundValue: number
  fundCurrency: 'GBP'
  investmentId: string
  investmentValue: number
  investmentCurrency: 'GBP'
  investmentCreatedAt: Date
  identifier: string | null
  yearOfBirth: number | null
  age: number | null
  ageRangeCode: AgeRange | null
  ageRangeDescription: string | null
  localAuthorityCode: ILocalAuthority['gssCode'] | null
  localAuthorityNiceName: ILocalAuthority['niceName'] | null
  localAuthorityOfficialName: ILocalAuthority['officialName'] | null
  localAuthorityRegion: ILocalAuthority['region'] | null
  localAuthorityNation: ILocalAuthority['nation'] | null
  localAuthorityIsCurrent: boolean | null
  genderCode: GenderCode | null
  genderDescription: string | null
  numDependents: number | null
  ethnicGroupCode: EthnicGroupCode | null
  ethnicGroupDescription: string | null
  ethnicGroupSection: string | null
  ethnicGroupSectionDescription: string | null
  vulnerabilityGroupCodes: VulnerabilityGroupCode[] | null
  createdAt: Date
  serviceEndDate: string | null
  serviceStartDate: string | null
  moveOnCode: MoveOnCode | null
  daysWaiting: number
  daysDuration: number | null
  state: ServiceUserState
}

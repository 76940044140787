import { Button, ButtonGroup, Center, Flex, Spacer, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import CircleChart from '../../../../partials/output/CircleChart';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import { type IAnalyticsSegment } from '../../../../types';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

const VULNERABILITY_GROUPS: Array<{ code: string, label: string, itemKey: keyof IAnalyticsSegment }> = [
  {
    code: 'LTU',
    label: 'People experiencing long term unemployment',
    itemKey: 'countVulnerabilityGroupLtu',
  },
  {
    code: 'HLN',
    label: 'People experiencing homelessness',
    itemKey: 'countVulnerabilityGroupHln',
  },
  {
    code: 'PFE',
    label: 'People living in poverty and/or financial exclusion',
    itemKey: 'countVulnerabilityGroupPfe',
  },
  {
    code: 'ADD',
    label: 'People dealing with addiction issues',
    itemKey: 'countVulnerabilityGroupAdd',
  },
  {
    code: 'ILL',
    label: 'People with long-term health conditions/life threatening or terminal illness',
    itemKey: 'countVulnerabilityGroupIll',
  },
  {
    code: 'LDN',
    label: 'People with learning disabilities and other neurodivergence',
    itemKey: 'countVulnerabilityGroupLdn',
  },
  {
    code: 'MHN',
    label: 'People with mental health needs',
    itemKey: 'countVulnerabilityGroupMhn',
  },
  {
    code: 'PHD',
    label: 'People with physical disabilities or sensory impairments',
    itemKey: 'countVulnerabilityGroupPhd',
  },
  {
    code: 'CAR',
    label: 'Voluntary carers',
    itemKey: 'countVulnerabilityGroupCar',
  },
  {
    code: 'VLP',
    label: 'Vulnerable parents',
    itemKey: 'countVulnerabilityGroupVlp',
  },
  {
    code: 'VLC',
    label: 'Vulnerable children',
    itemKey: 'countVulnerabilityGroupVlc',
  },
  {
    code: 'VYP',
    label: 'Vulnerable young people',
    itemKey: 'countVulnerabilityGroupVyp',
  },
  {
    code: 'OLD',
    label: 'Older people',
    itemKey: 'countVulnerabilityGroupOld',
  },
  {
    code: 'EXO',
    label: 'Ex/Offenders and prisoners',
    itemKey: 'countVulnerabilityGroupExo',
  },
  {
    code: 'ABU',
    label: 'People who have experienced crime or abuse',
    itemKey: 'countVulnerabilityGroupAbu',
  },
  {
    code: 'REF',
    label: 'Refugees, asylum seekers, undocumented and other migrants',
    itemKey: 'countVulnerabilityGroupRef',
  }
];

function VulnerabilityGroupsTable ({ row, isVisible }: { row: AnalyticsSegmentRange, isVisible: boolean }) {
  const max = Math.max(...VULNERABILITY_GROUPS.map(group => row.d2[group.itemKey] as number));

  return (
    <Table variant='simple' size='sm' display={isVisible ? 'table' : 'none'} >
      <Thead>
        <Tr>
          <Th>Vulnerability Group</Th>
          <Th>Service Users</Th>
        </Tr>
      </Thead>
      <Tbody>
        {VULNERABILITY_GROUPS.map((group) => (
          <Tr key={group.label}>
            <Td>{group.label}</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={group.itemKey} max={max} barColor='yellow.400' />
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function VulnerabilityGroupsCircleChart ({ row, isVisible }: Readonly<{ row: AnalyticsSegmentRange, isVisible: boolean }>) {
  const data = VULNERABILITY_GROUPS.map(group => ({
    key: group.code,
    label: group.label,
    value: row.d2[group.itemKey] as number
  }));

  return (
    <Center display={isVisible ? 'flex' : 'none'} height={{ base: 'auto', md: '500px' }} >
      <CircleChart data={data} />
    </Center>
  );
}

export default function VulnerabilityGroupsChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const [showChart, setShowChart] = useState(true);

  return (
    <Stack spacing={4}>
      <Flex align='center'>
        <HeadingToolTip size='sm' label='Distribution of Service Users by vulnerability groups'>Vulnerability Groups</HeadingToolTip>
        <Spacer />
        <ButtonGroup isAttached variant='outline'>
          <Button size='xs' isDisabled={showChart} onClick={() => { setShowChart(true); }} >Chart</Button>
          <Button size='xs' isDisabled={!showChart} onClick={() => { setShowChart(false); }} >Table</Button>
        </ButtonGroup>
      </Flex>

      <VulnerabilityGroupsTable row={row} isVisible={!showChart} />
      <VulnerabilityGroupsCircleChart row={row} isVisible={showChart} />
    </Stack >
  );
}

// Adapted from the Good Finance Outcomes Matrix
// Ref: https://www.goodfinance.org.uk/measuring-social-impact/outcomes-matrix
// Data Retrieved: 18 June 2023

export interface VulnerabilityGroup {
  code: string
  shortLabel: string
  label: string
}

export type VulnerabilityGroupCode = typeof vulnerabilityGroups[number]['code'];

export const vulnerabilityGroups: VulnerabilityGroup[] = [
  { code: 'LTU', shortLabel: 'Unemployment', label: 'People experiencing long term unemployment' },
  { code: 'HLN', shortLabel: 'Homelessness', label: 'People experiencing homelessness' },
  { code: 'PFE', shortLabel: 'Poverty', label: 'People living in poverty and/or financial exclusion' },
  { code: 'ADD', shortLabel: 'Addiction', label: 'People dealing with addiction issues' },
  { code: 'ILL', shortLabel: 'Long-term Health', label: 'People with long-term health conditions/life threatening or terminal illness' },
  { code: 'LDN', shortLabel: 'Learning Disability', label: 'People with learning disabilities and other neurodivergence' },
  { code: 'MHN', shortLabel: 'Mental Health', label: 'People with mental health needs' },
  { code: 'PHD', shortLabel: 'Physical Disability', label: 'People with physical disabilities or sensory impairments' },
  { code: 'CAR', shortLabel: 'Voluntary Carers', label: 'Voluntary carers' },
  { code: 'VLP', shortLabel: 'Vulnerable Parents', label: 'Vulnerable parents' },
  { code: 'VLC', shortLabel: 'Vulnerable Children', label: 'Vulnerable children' },
  { code: 'VYP', shortLabel: 'Vulnerable Youths', label: 'Vulnerable young people' },
  { code: 'OLD', shortLabel: 'Older People', label: 'Older people' },
  { code: 'EXO', shortLabel: 'Ex/Offenders', label: 'Ex/Offenders and prisoners' },
  { code: 'ABU', shortLabel: 'Experienced Crime', label: 'People who have experienced crime or abuse' },
  { code: 'REF', shortLabel: 'Migrants', label: 'Refugees, asylum seekers, undocumented and other migrants' }
];

/**
 * Get a Vulnerability Group from a code
 * @param code
 * @returns
 */
export function getVulnerabilityGroupByCode (code: string): VulnerabilityGroup | undefined {
  return vulnerabilityGroups.find(row => row.code === code);
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { type IOrganisation, type IOrganisationExtended } from '../types';
import { type CreateRelationship, type DeleteRelationship } from '../types/Relationship';
import { apiClient, type APIItemResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useCreateRelationship () {
  return useMutation({
    mutationFn: async (relationship: CreateRelationship) => {
      const url = API_URL.relationship.create;
      const { data } = await apiClient.post(url, relationship);
      return data;
    },
  });
}

export function useDeleteRelationship () {
  return useMutation({
    mutationFn: async (relationship: DeleteRelationship) => {
      const url = API_URL.relationship.delete;
      const { data } = await apiClient.delete(url, { data: relationship });
      return data;
    },
  });
}

export function useGetChildrenFor (parentId: string) {
  return useQuery({
    queryKey: ['useGetChildrenFor', parentId],
    queryFn: async () => {
      const url = API_URL.relationship.forParent(parentId);
      // Parents get full details of children: IOrganisationExtended[]
      const { data } = await apiClient.get<APIItemResponse<IOrganisationExtended[]>>(url);
      return data;
    },
  });
}

export function useGetParentsFor (childId: string) {
  return useQuery({
    queryKey: ['useGetParentsFor', childId],
    queryFn: async () => {
      const url = API_URL.relationship.forChild(childId);
      // Children get limited details of parents: IOrganisation[]
      const { data } = await apiClient.get<APIItemResponse<IOrganisation[]>>(url);
      return data;
    },
  });
}

import { useMutation } from '@tanstack/react-query';
import { TNCS_CHECKSUM, TNCS_VERSION } from '../partials/AcceptTnCsModal';
import { type AuthResponse } from '../types';
import { apiClient, refreshSession, type APIItemResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';
import { loadSession } from './useSession';

export default function useTnCs () {
  const session = loadSession();
  const tncVersion = session?.tncVersion ?? 0;

  // TnCs required if accepted version is less than current version
  const isTnCsRequired = tncVersion < TNCS_VERSION;

  const acceptTnCs = useMutation({
    mutationFn: async () => {
      const url = API_URL.auth.acceptTnCs;
      const payload = { version: TNCS_VERSION, checksum: TNCS_CHECKSUM };
      const response = await apiClient.post<APIItemResponse<AuthResponse>>(url, payload);
      return response.data.docs;
    },
    onSuccess: async () => {
      await refreshSession();
      window.location.reload();
    },
  });

  return { isTnCsRequired, ...acceptTnCs };
}

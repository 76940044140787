// Ethnic Group Codes
// Source: https://www.ethnicity-facts-figures.service.gov.uk/style-guide/ethnic-groups
// Codes used in 2021 Census
// Retrieved 9 June 2023
//
// Labels from Ethnic Groups used in 2021 Census
// Codes are internal only

export interface EthnicGroup {
  value: string
  label: string
  heading: Headings
}

export type EthnicGroupCode = typeof ethnicGroupsFlat[number]['value'];

export enum Headings {
  Asian = 'Asian or Asian British',
  Black = 'Black, Black British, Caribbean or African',
  Mixed = 'Mixed or multiple ethnic groups',
  White = 'White',
  Other = 'Other ethnic group'
}

// Structured for compatibility with EthnicGroupSelect
export const ethnicGroups = [
  {
    label: Headings.Asian,
    options: [
      { value: 'AI', label: 'Indian' },
      { value: 'AP', label: 'Pakistani' },
      { value: 'AB', label: 'Bangladeshi' },
      { value: 'AC', label: 'Chinese' },
      { value: 'AO', label: 'Any other Asian background' },
    ]
  },
  {
    label: Headings.Black,
    options: [
      { value: 'BC', label: 'Caribbean' },
      { value: 'BA', label: 'African' },
      { value: 'BO', label: 'Any other Black, Black British, or Caribbean background' },
    ]
  },
  {
    label: Headings.Mixed,
    options: [
      { value: 'MBC', label: 'White and Black Caribbean' },
      { value: 'MBA', label: 'White and Black African' },
      { value: 'MWA', label: 'White and Asian' },
      { value: 'MO', label: 'Any other Mixed or multiple ethnic background' },
    ]
  },
  {
    label: Headings.White,
    options: [
      { value: 'WB', label: 'English, Welsh, Scottish, Northern Irish or British' },
      { value: 'WI', label: 'Irish' },
      { value: 'WG', label: 'Gypsy or Irish Traveller' },
      { value: 'WR', label: 'Roma' },
      { value: 'WO', label: 'Any other White background' },
    ]
  },
  {
    label: Headings.Other,
    options: [
      { value: 'OA', label: 'Arab' },
      { value: 'OO', label: 'Any other ethnic group' }
    ]
  }
];

// Flat array of the above
export const ethnicGroupsFlat: EthnicGroup[] = ethnicGroups.reduce((acc, curr) => {
  const options = curr.options.map(o => ({ ...o, heading: curr.label }));
  return acc.concat(options as any);
}, []);

/**
 * Get Ethnic Group from a code
 * @param code
 * @returns
 */
export function getEthnicGroupFromCode (code: string | null) {
  return ethnicGroupsFlat.find(g => g.value === code);
}

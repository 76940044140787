import { Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

export default function GenderChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const max = Math.max(...[
    row.d2.countGenderMale,
    row.d2.countGenderFemale,
    row.d2.countGenderNotSay,
    row.d2.countGenderOther,
    row.d2.countGenderNull,
  ]);

  return (
    <Stack spacing={4}>
      <HeadingToolTip size='sm' label='Distribution of Service Users by gender'>Gender</HeadingToolTip>
      <Table variant='simple' size='sm' >
        <Thead>
          <Tr>
            <Th>Gender</Th>
            <Th>Service Users</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td lineHeight='24px'>Male</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={'countGenderMale'} max={max} barColor='pink.500' />
          </Tr>
          <Tr>
            <Td lineHeight='24px'>Female</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={'countGenderFemale'} max={max} barColor='pink.500' />
          </Tr>
          <Tr>
            <Td lineHeight='24px'>Prefers Not To Say</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={'countGenderNotSay'} max={max} barColor='pink.500' />
          </Tr>
          <Tr>
            <Td lineHeight='24px'>Other</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={'countGenderOther'} max={max} barColor='pink.500' />
          </Tr>
          <Tr>
            <Td lineHeight='24px'>None Set</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={'countGenderNull'} max={max} barColor='pink.500' />
          </Tr>
        </Tbody>
      </Table>
    </Stack >
  );
}

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Card, Table, Tr, Tbody, Td, Link, Heading, Box, Text } from '@chakra-ui/react';
import { useNewsFeed } from '../../../../hooks/useNewsFeed';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';

export default function NewsWidget () {
  const { data, isError, isLoading, error } = useNewsFeed({ items: 3 });

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  return (
    <Card p={4}>
      <Heading size='md'>News & Announcements</Heading>
      <Table>
        <Tbody>
          {data?.items?.map(item => (
            <Tr key={item.url}>
              <Td pl={1}>
                <Link isExternal href={item.url}>{item.title} <ExternalLinkIcon mx='2px' /></Link>
              </Td>
              <Td pr={1} isNumeric>
                <Text fontSize='sm' color='gray.500'>{item.date_published.toDateString()}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box mt={4} textAlign='right'>
        <Link href='https://impactgo.uk/news' isExternal>
          View all news <ExternalLinkIcon mx='2px' />
        </Link>
      </Box>
    </Card>
  );
}

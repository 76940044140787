import { useQuery } from '@tanstack/react-query';
import { type IUserActivity } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useActivity ({ limit }: { limit: number }) {
  const url = API_URL.activity;
  const opts: APIPaginatedQuery = { params: { pageSize: limit } };

  return useQuery({
    queryKey: ['Activity', limit],
    queryFn: async () => {
      const { data } = await apiClient.get<APIItemResponse<IUserActivity[]>>(url, opts);
      return data;
    },
  });
}

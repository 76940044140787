import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export default function ErrorAlert ({ error }: { error: Error | null }) {
  if (error === null) return null;
  // FUTURE scroll to error on render
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle fontWeight='normal'>{error.message}</AlertTitle>
    </Alert>
  );
}

import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import useTnCs from '../../hooks/useTnCs';
import { AcceptTnCsModal } from '../AcceptTnCsModal';
import Loading from './Loading';
import NavBar from './NavBar';

export default function DashboardLayout () {
  const navigate = useNavigate();
  const { isTnCsRequired } = useTnCs();
  const { loading, session } = useSession();

  useEffect(() => {
    // Send to login if not authenticated
    if (session?.accessToken === undefined) navigate('/login');
  }, [session]);

  if (loading) return <Loading />;

  // Show TnC's acceptance modal if not yet accepted
  if (isTnCsRequired) return <AcceptTnCsModal />;

  return (
    <>
      <NavBar as="header" position="fixed" width="100%" />
      <Box
        as="main"
        bottom="0"
        left="0"
        overflowX="scroll"
        position="fixed"
        right="0"
        top="57px"
      >
        <Outlet />
      </Box >
    </>
  );
}

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type IInvestmentExtended, type IOrganisationChild, type IUserExtended, type InviteUser } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListServiceProviders () {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListOrganisations', page],
    queryFn: async () => {
      const url = API_URL.serviceProvider.list;
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IOrganisationChild>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useGetServiceProvider (id: string) {
  const queryClient = useQueryClient();
  const queryKey = ['useGetServiceProvider', id];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.serviceProvider.get(id);
      const { data } = await apiClient.get<APIItemResponse<IOrganisationChild>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

export function useListInvestmentsForServiceProvider (id: string) {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListInvestmentsForServiceProvider', id, page],
    queryFn: async () => {
      const url = API_URL.serviceProvider.getInvestments(id);
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IInvestmentExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useListUsersForServiceProvider (id: string) {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListUsersForServiceProvider', id, page],
    queryFn: async () => {
      const url = API_URL.serviceProvider.getUsers(id);
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IUserExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useInviteUserToServiceProvider (id: string) {
  return useMutation({
    mutationFn: async (serviceUser: InviteUser) => {
      const url = API_URL.serviceProvider.inviteUser(id);
      const { data } = await apiClient.post<APIItemResponse<IUserExtended>>(url, serviceUser);
      return data;
    },
  });
}

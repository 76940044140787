import { type EthnicGroupCode } from '../utils/ethnicGroups';
import { type VulnerabilityGroupCode } from '../utils/vulnerabilityGroups';
import { type ILocalAuthority } from './LocalAuthority';

export enum GenderCode {
  NotSay = 'NOT_SAY',
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
}

export enum MoveOnCode {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Indeterminate = 'INDETERMINATE',
}
export enum AgeRange {
  Under16 = 'UNDER_16',
  Age16To24 = '16_24',
  Age25To50 = '25_50',
  Over50 = 'OVER_50',
}

export interface IServiceUser {
  id: string
  versionId: string
  organisationId: string
  investmentId: string
  userId: string
  createdAt: Date
  effectiveFrom: Date
  effectiveTo: Date | null
  identifier: string | null
  yearOfBirth: number | null
  genderCode: GenderCode | null
  numDependents: number | null
  ethnicGroupCode: EthnicGroupCode | null
  vulnerabilityGroupCodes: VulnerabilityGroupCode[] | null
  localAuthorityCode: ILocalAuthority['gssCode'] | null
  serviceEndDate: string | null
  serviceStartDate: string | null
  moveOnCode: MoveOnCode | null
}

export type CreateServiceUser = Pick<IServiceUser, 'investmentId' | 'identifier' | 'yearOfBirth' | 'genderCode' | 'numDependents' | 'ethnicGroupCode' | 'vulnerabilityGroupCodes' | 'localAuthorityCode' | 'serviceEndDate' | 'serviceStartDate' | 'moveOnCode'>;
export type UpdateServiceUser = Pick<IServiceUser, 'id' | 'investmentId' | 'identifier' | 'yearOfBirth' | 'genderCode' | 'numDependents' | 'ethnicGroupCode' | 'vulnerabilityGroupCodes' | 'localAuthorityCode' | 'serviceEndDate' | 'serviceStartDate' | 'moveOnCode'>;

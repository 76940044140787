/**
 * Returns true if text appears to contain a common identifier
 * @param text
 * @returns
 */
export const containsCommonIdentifier = (text: string): boolean => {
  const regExps = [
    reNationalInsuranceNumber,
    rePostCode,
    reUKPassportNumber,
    reUKDrivingLicenceNumber,
    reNHSNumber,
    reUKPhoneNumber,
    reEmailAddress,
    reIPAddress,
  ];

  return regExps.some((regEx) => regEx.test(text));
};

// RegEx for common identification numbers.
// These RegExp are approximate matchers, and should not be used to definitively validate ID formats.
// They are intended to recognise if some text _may_ contain identifiable information.
// Users should be prompted to confirm if the text does contain identifiable information and if so, to redact it.

// National Insurance Number
// Ref: https://stackoverflow.com/questions/10204378/regular-expression-to-validate-uk-national-insurance-number
const reNationalInsuranceNumber = /(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)/i;

// Post Code
// Ref: https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
const rePostCode = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i;

// Passport Number
// Ref: https://community.zscaler.com/zenith/s/question/0D54u00009jZpJGCA0/international-passport-numbers-regex
const reUKPassportNumber = /\d{9}/i;

// Driving Licence Number
// Ref: https://stackoverflow.com/questions/45493570/validate-uk-driving-license-regex-in-javascript
const reUKDrivingLicenceNumber = /[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}/i;

// NHS Number
// Ref: https://help.relativity.com/RelativityOne/Content/Relativity/Relativity_Redact/Regular_expression_examples.htm
const reNHSNumber = /\d{3}\s\d{3}\s\d{4}/i;

// Phone number
// Ref: https://help.relativity.com/RelativityOne/Content/Relativity/Relativity_Redact/Regular_expression_examples.htm
const reUKPhoneNumber = /([0O]?[1lI][1lI])?[4A][4A][\dOIlZEASB]{10,11}/i;

// Email address
// Ref: https://regexr.com/3e48o
const reEmailAddress = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/i;

// IP Address
// Ref: https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch07s16.html
const reIPAddress = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}/i;

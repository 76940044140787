import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, type InputProps } from '@chakra-ui/react';
import { type UseFormRegister, type FieldErrors } from 'react-hook-form';

interface PropsType extends InputProps {
  name: string
  label?: string
  helperText?: string
  register: UseFormRegister<any>
  errors: FieldErrors<any>
}

export function TextInput (props: Readonly<PropsType>) {
  const { name, label, helperText, register, errors, ...inputProps } = props;
  const hasError = name in errors;
  const hasLabel = label !== undefined;
  const hasHelperText = helperText !== undefined;

  return (
    <FormControl isInvalid={name in errors}>
      {hasLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input id={name} autoComplete='off' {...register(name)} {...inputProps}/>
      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : hasHelperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

import { API_BASE } from './config';

export const API_URL = {
  auth: {
    login: `${API_BASE}/auth/login`,
    whoAmI: `${API_BASE}/auth/whoami`,
    refreshToken: `${API_BASE}/auth/refresh`,
    forgottenPassword: `${API_BASE}/auth/password/forgotten`,
    resetPassword: `${API_BASE}/auth/password`,
    acceptTnCs: `${API_BASE}/auth/accept-tncs`,
  },
  profile: {
    profile: `${API_BASE}/profile`,
    password: `${API_BASE}/profile/change-password`,
    team: `${API_BASE}/profile/team`,
    invite: `${API_BASE}/profile/team`,
  },
  organisation: {
    list: `${API_BASE}/organisation`,
    create: `${API_BASE}/organisation`,
    update: (id: string) => `${API_BASE}/organisation/${id}`,
    get: (id: string) => `${API_BASE}/organisation/${id}`,
  },
  user: {
    list: `${API_BASE}/user`,
    create: `${API_BASE}/user`,
    update: (id: string) => `${API_BASE}/user/${id}`,
    get: (id: string) => `${API_BASE}/user/${id}`,
    delete: (id: string) => `${API_BASE}/user/${id}`,
  },
  relationship: {
    create: `${API_BASE}/relationship`,
    delete: `${API_BASE}/relationship`,
    forParent: (id: string) => `${API_BASE}/relationship/parent/${id}`, // Children for Parent
    forChild: (id: string) => `${API_BASE}/relationship/child/${id}`, // Parents for Child
  },
  fund: {
    list: `${API_BASE}/fund`,
    create: `${API_BASE}/fund`,
    update: (id: string) => `${API_BASE}/fund/${id}`,
    get: (id: string) => `${API_BASE}/fund/${id}`,
  },
  investment: {
    list: (fundId: string) => `${API_BASE}/fund/${fundId}/investment`,
    create: (fundId: string) => `${API_BASE}/fund/${fundId}/investment`,
    delete: (fundId: string, investmentId: string) => `${API_BASE}/fund/${fundId}/investment/${investmentId}`,
  },
  serviceProvider: {
    list: `${API_BASE}/service-provider`,
    get: (id: string) => `${API_BASE}/service-provider/${id}`,
    getInvestments: (id: string) => `${API_BASE}/service-provider/${id}/investments`,
    getUsers: (id: string) => `${API_BASE}/service-provider/${id}/users`,
    inviteUser: (id: string) => `${API_BASE}/service-provider/${id}/users`,
  },
  serviceUser: {
    list: `${API_BASE}/service-user`,
    create: `${API_BASE}/service-user`,
    update: (id: string) => `${API_BASE}/service-user/${id}`,
    get: (id: string) => `${API_BASE}/service-user/${id}`,
  },
  fundRecipient: {
    investments: `${API_BASE}/recipient/investments`,
    fundManagers: `${API_BASE}/recipient/fund-managers`,
  },
  segment: {
    data: `${API_BASE}/segment`,
    download: `${API_BASE}/segment/download`,
  },
  overview: {
    serviceUser: `${API_BASE}/overview/service-user`,
    investment: `${API_BASE}/overview/investment`,
  },
  activity: `${API_BASE}/activity`,
};

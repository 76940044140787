import { Box, Button, Stack, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import AgeRangeTag from '../../../../partials/output/AgeRangeTag';
import ServiceDates from '../../../../partials/output/ServiceDates';
import ServiceUserStateTag from '../../../../partials/output/ServiceUserStateTag';
import VulnerabilityGroupTags from '../../../../partials/output/VulnerabilityGroupCodeTags';
import { type IServiceUserExtended } from '../../../../types';
import { getEthnicGroupFromCode } from '../../../../utils/ethnicGroups';
import { getLocalAuthorityByCode } from '../../../../utils/localAuthorities';

interface PropsType {
  serviceUsers: IServiceUserExtended[]
  editServiceUser: (serviceUser: IServiceUserExtended) => void
}

const sx = { verticalAlign: 'top' };

export default function ServiceUsersTable ({ serviceUsers, editServiceUser }: Readonly<PropsType>) {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Identifier</Th>
          <Th>State</Th>
          <Th>Time with Service</Th>
          <Th>Vulnerability Groups</Th>
          <Th>Gender</Th>
          <Th>Year of Birth</Th>
          <Th>Num Dependents</Th>
          <Th>Ethnic Group</Th>
          <Th>Local Authority</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {serviceUsers.map((serviceUser) => (
          <Tr key={serviceUser.id} >
            <Td sx={sx}><Tag><code>{serviceUser.identifier}</code></Tag></Td>
            <Td sx={sx}><ServiceUserStateTag state={serviceUser.state} /></Td>
            <Td sx={sx}><ServiceDates serviceUser={serviceUser} /></Td>
            <Td sx={sx}><VulnerabilityGroupTags codes={serviceUser.vulnerabilityGroupCodes} /></Td>
            <Td sx={sx}>{serviceUser.genderCode}</Td>
            <Td sx={sx}>
              <Stack>
                <Box> {serviceUser.yearOfBirth} </Box>
                <AgeRangeTag ageRange={serviceUser.ageRangeCode} />
              </Stack>
            </Td>
            <Td sx={sx}>{serviceUser.numDependents}</Td>
            <Td sx={sx}>{getEthnicGroupFromCode(serviceUser.ethnicGroupCode)?.label}</Td>
            <Td sx={sx}>{getLocalAuthorityByCode(serviceUser.localAuthorityCode)?.EN}</Td>
            <Td sx={sx}>
              <Button size='sm' colorScheme='teal' onClick={() => { editServiceUser(serviceUser); }}>Edit</Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Container, Heading, Image, Link, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import { useSuccessToast } from '../../hooks/useToast';

// Image from https://www.freepik.com/free-vector/employees-giving-hands-helping-colleagues-walk-upstairs_7732609.htm

export default function Support () {
  const session = useSession();
  const success = useSuccessToast();

  const diagnostics = JSON.stringify({
    timestamp: new Date().toISOString(),
    env: process.env,
    session: {
      user: session?.session?.user,
      organisation: session?.session?.organisation,
      accessTokenExpiry: session?.session?.accessTokenExpiry,
      refreshTokenExpiry: session?.session?.refreshTokenExpiry,
      tncVersion: session?.session?.tncVersion,
    },
    window: {
      history: {
        length: window.history.length,
      },
      isSecureContext: window.isSecureContext,
      location: window.location,
      name: window.name,
      navigator: {
        cookieEnabled: window.navigator.cookieEnabled,
        hardwareConcurrency: window.navigator.hardwareConcurrency,
        language: window.navigator.language,
        languages: window.navigator.languages,
        maxTouchPoints: window.navigator.maxTouchPoints,
        onLine: window.navigator.onLine,
        pdfViewerEnabled: window.navigator.pdfViewerEnabled,
        userAgent: window.navigator.userAgent,
        webdriver: window.navigator.webdriver,
      },
      opener: window.opener,
      origin: window.origin,
      screen: {
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        pixelDepth: window.screen.pixelDepth,
        width: window.screen.width,
      },
    },
  }, null, 2);

  return (
    <Container as={Stack} py={4} maxW="3xl" spacing={4}>
      <Image src='/support.jpg' alt='Support' />

      <Heading>Support</Heading>

      <Text fontSize='large'>
        If you have any questions or need help, please email me at
      </Text>

      <Text fontSize='x-large'>
        <Link as={RouterLink} to="mailto:support@impactgo.uk" fontWeight='bold'>support@impactgo.uk</Link>
      </Text>

      <Accordion allowToggle mt={8}>
        <AccordionItem>
          <AccordionButton color='blackAlpha.500' _expanded={{ color: 'blackAlpha.900' }}>
            <Box as="span" flex='1'>Diagnostic Information</Box>
          </AccordionButton>
          <AccordionPanel as={Stack} spacing={4} pb={4} px={0}>
            <Text
              as='pre'
              onClick={async (e: any) => {
                await navigator.clipboard.writeText(e.target.innerHTML);
                success('Copied to clipboard');
              }}
              sx={{
                backgroundColor: 'blackAlpha.100',
                fontFamily: 'monospace',
                padding: 4,
                borderRadius: 4,
                overflowX: 'auto',
              }}
              _hover={{
                backgroundColor: 'blackAlpha.200',
                cursor: 'pointer',
              }}
            >{diagnostics}</Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

    </Container>
  );
}

import { Avatar, Box, Button, Center, Flex, Heading, Spacer, Stack, Table, Tbody, Td, Text, Th, Tr, useDisclosure } from '@chakra-ui/react';
import { useForgottenPassword } from '../../../../hooks/useAuthentication';
import { useListUsersForServiceProvider } from '../../../../hooks/useServiceProvider';
import { useErrorToast, useSuccessToast } from '../../../../hooks/useToast';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import DateTime from '../../../../partials/output/DateTime';
import Email from '../../../../partials/output/Email';
import UserStateTag from '../../../../partials/output/UserStateTag';
import { type IUserExtended } from '../../../../types';
import InviteUserModal from './InviteUserModal';

export default function ManageUsers ({ organisationId }: { organisationId: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const success = useSuccessToast();
  const danger = useErrorToast();
  const { data, isLoading, isError, error, refetch } = useListUsersForServiceProvider(organisationId);

  const forgottenPassword = useForgottenPassword();

  const onForgottenPassword = (user: IUserExtended) => {
    forgottenPassword.mutate({ login: user.login }, {
      onSuccess: () => success(`Password reset email sent to ${user.login}`),
      onError: (error) => danger(error.message)
    });
  };

  const onSuccess = () => {
    void refetch();
    onClose();
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const users = data?.docs as IUserExtended[];

  if (users.length === 0) {
    return (
      <>
        <Heading size="lg">User Accounts</Heading>
        <Center as={Stack} mt={8} spacing={8} textAlign='center'>
          <Text fontSize="lg" color="gray.500">Invite a User to this Service Provider</Text>
          <Button onClick={onOpen} colorScheme="blue" mb="4">Invite User</Button>
          <Text color="gray.500">
            They will receive an email to activate their account.<br />
            Then they can log in to their Dashboard to manage Service Users
          </Text>
        </Center>

        <InviteUserModal
          organisationId={organisationId}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </>
    );
  }

  return (
    <Box mt="8">
      <Flex>
        <Heading mb="4" size="lg">User Accounts</Heading>
        <Spacer />
        <Button onClick={onOpen} colorScheme="green" mb="4" size="sm">Invite User</Button>
      </Flex>
      <Table variant="simple" mt="4">
        <Tbody>
          <Tr>
            <Th pl="1"></Th>
            <Th>Name</Th>
            <Th>Login</Th>
            <Th>State</Th>
            <Th>Last Login At</Th>
            <Th pr="1"></Th>
          </Tr>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td pl="1">
                <Avatar size='sm' name={user.name} />
              </Td>
              <Td>{user.name}</Td>
              <Td><Email email={user.login} /></Td>
              <Td><UserStateTag state={user.state} /></Td>
              <Td><DateTime date={user.lastLoginAt} /></Td>
              <Td pr="1" textAlign="right">
                <Button
                  size="xs"
                  colorScheme='yellow'
                  aria-label='password-reset'
                  isDisabled={user.state === 'DEACTIVATED'}
                  onClick={() => { onForgottenPassword(user); }}
                >
                  Reset Password
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <InviteUserModal
        organisationId={organisationId}
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Box>
  );
}

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Link, type InputProps, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { type FieldErrors, type Control, Controller } from 'react-hook-form';
import { containsCommonIdentifier } from '../../utils/commonIdentifiers.ts';
import { containsCommonName } from '../../utils/commonNames';

interface PropsType extends InputProps {
  name: string
  helperText?: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

function ValidationWarning () {
  return (
    <Alert mt={2} status='warning'>
      <AlertIcon />
      <Stack spacing={2}>
        <p>This <i>may</i> be a common identifier or real name.</p>
        <p>
          For the safety of service users ensure that record IDs are known only to you.
          Do not use real names or common identifiers such as post code or NHS number.<br />
        </p>
        <p>
          <Link href='https://chakra-ui.com' isExternal textDecoration='underline'>
            More information <ExternalLinkIcon mx='2px' />
          </Link>
        </p>
      </Stack>
    </Alert>
  );
}

export function IdentifierInput (props: Readonly<PropsType>) {
  const { name, helperText, control, errors, ...inputProps } = props;
  const [hasWarning, setHasWarning] = useState<boolean>(false);
  const hasError = name in errors;
  const hasHelperText = helperText !== undefined;

  const showMessage = () => {
    if (hasWarning) return <ValidationWarning />;
    if (hasError) return <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>;
    if (hasHelperText) return <FormHelperText>{helperText}</FormHelperText>;
    return null;
  };

  return (
    <FormControl isInvalid={name in errors}>
      <FormLabel htmlFor={name}>Record ID*</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            id={name}
            name={name}
            autoComplete='off'
            placeholder="ID-123"
            onChange={(ev) => {
              const value = ev.target.value;
              field.onChange(value);
              setHasWarning(containsCommonIdentifier(value) || containsCommonName(value));
            }}
            value={field.value ?? ''}
            {...inputProps}
          />
        )}
      />
      {showMessage()}
    </FormControl>
  );
}

import { Box, Card, Container, Heading, Stack, Text } from '@chakra-ui/react';
import { useActivity } from '../../hooks/useActivity';
import ErrorAlert from '../../partials/layout/ErrorAlert';
import Loading from '../../partials/layout/Loading';
import Activity from '../../partials/output/Activity';

export default function ActivityFeed () {
  const { data, isLoading, error } = useActivity({ limit: 500 });

  if (isLoading) return <Loading />;
  if (error != null) return <ErrorAlert error={error} />;

  return (
    <Container py={4} maxW="3xl">
      <Heading>Activity</Heading>

      <Stack spacing={4} mt={6}>
        {data?.docs.map((activity) => (
          <Card key={activity.logId} p={4}>
            <Activity activity={activity} />
          </Card>
        ))}
      </Stack>

      <Box textAlign='center' py={4}>
        <Text color='gray.400'>Showing activity over the past 30 days</Text>
      </Box>
    </Container>
  );
}

export function round (value: number, precision: number) {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export function monthDiff (start: Date | string, end: Date | string) {
  const d1 = new Date(start);
  const d2 = new Date(end);
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

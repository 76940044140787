import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type CreateFund, type IFundExtended, type UpdateFund } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListFunds () {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListFunds', page],
    queryFn: async () => {
      const url = API_URL.fund.list;
      const options: APIPaginatedQuery = { params: { page, pageSize: 10 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IFundExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useCreateFund () {
  return useMutation({
    mutationFn: async (fund: CreateFund) => {
      const url = API_URL.fund.create;
      const { data } = await apiClient.post<APIItemResponse<IFundExtended>>(url, fund);
      return data;
    },
  });
}

export function useUpdateFund () {
  return useMutation({
    mutationFn: async (fund: UpdateFund) => {
      const url = API_URL.fund.update(fund.id);
      const { data } = await apiClient.post<APIItemResponse<IFundExtended>>(url, fund);
      return data;
    },
  });
}

export function useGetFund (id: string) {
  const queryClient = useQueryClient();
  const queryKey = ['useGetFund', id];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.fund.get(id);
      const { data } = await apiClient.get<APIItemResponse<IFundExtended>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

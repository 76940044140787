import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type CreateOrganisation, type IOrganisationExtended, type UpdateOrganisation } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListOrganisations ({ pageSize = 10 }: { pageSize?: number } = {}) {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListOrganisations', pageSize, page],
    queryFn: async () => {
      const url = API_URL.organisation.list;
      const options: APIPaginatedQuery = { params: { page, pageSize } };
      const { data } = await apiClient.get<APIPaginatedResponse<IOrganisationExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useCreateOrganisation () {
  return useMutation({
    mutationFn: async (organisation: CreateOrganisation) => {
      const url = API_URL.organisation.create;
      const { data } = await apiClient.post<APIItemResponse<IOrganisationExtended>>(url, organisation);
      return data;
    },
  });
}

export function useUpdateOrganisation () {
  return useMutation({
    mutationFn: async (organisation: UpdateOrganisation) => {
      const id = organisation.id;
      const url = API_URL.organisation.update(id);
      const { data } = await apiClient.post<APIItemResponse<IOrganisationExtended>>(url, organisation);
      return data;
    },
  });
}

export function useGetOrganisation (id: string) {
  const queryClient = useQueryClient();
  const queryKey = ['useGetOrganisation', id];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.organisation.get(id);
      const { data } = await apiClient.get<APIItemResponse<IOrganisationExtended>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

import { Container, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useGetProfile } from '../../../hooks/useProfile';
import { useSuccessToast } from '../../../hooks/useToast';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import { type IUserExtended } from '../../../types';
import ProfileForm from './partials/ProfileForm';

export default function MyProfile () {
  const success = useSuccessToast();
  const navigate = useNavigate();

  const { data, isLoading, isError, error, clearCache } = useGetProfile();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const onSuccess = async () => {
    await clearCache();
    success('Profile updated', 'You may need to logout/in to see changes');
    navigate('/');
  };

  const user = data?.docs as IUserExtended;

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">My Profile</Heading>
      <ProfileForm profile={user} onSuccess={onSuccess} />
    </Container>
  );
}

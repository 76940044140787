import { Button, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import PublicLayout from '../../partials/layout/PublicLayout';
import LoginForm from './partials/LoginForm';

export default function Login () {
  const navigate = useNavigate();
  const { session, startSession } = useSession();

  useEffect(() => {
    // This handles the post-login redirect following the call to startSession
    if (session?.accessToken !== undefined) navigate('/');
  }, [session]);

  // FUTURE Show MFA configuration if MFA is required
  // FUTURE Show MFA challenge if MFA is required
  // FUTURE Show password reset if password is expired
  // FUTURE Show Organisation Selection if multiple organisations are available

  return (
    <PublicLayout>
      <Stack spacing={8}>
        <LoginForm onSuccess={startSession} />
        <Button variant="link" as={Link} to="/forgot-password">Forgot Password?</Button>
      </Stack>
    </PublicLayout>
  );
}

import { Box, Card, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { type IServiceUserOverview } from '../../../../types';
import { round } from '../../../../utils/utils';

function TableRow ({ heading, value, maxValue, totalUsers }: { heading: string, value: number, maxValue: number, totalUsers: number }) {
  const barWidth = (value / maxValue) * 100;
  const percUsers = (value: number) => round((value / totalUsers) * 100, 2);

  return (
    <Tr>
      <Td>{heading}</Td>
      <Td isNumeric>{value}</Td>
      <Td width='100px' p={0}>
        <Box height='20px' w={`${barWidth}%`} backgroundColor='teal.500' />
      </Td>
      <Td isNumeric>{percUsers(value)}%</Td>
    </Tr>
  );
}

export default function StateWidget ({ data }: { data: IServiceUserOverview }) {
  const maxValue = Math.max(...[
    data.countStateAwaitingService,
    data.countStateWithService,
    data.countStateMovedOnPositive,
    data.countStateMovedOnNegative,
    data.countStateMovedOnIndeterminate,
  ]);

  return (
    <Card p={4}>
      <Table size='sm' variant='simple'>
        <Thead>
          <Tr>
            <Th>Service Lifecycle</Th>
            <Th colSpan={3}>Service Users</Th>
          </Tr>
        </Thead>
        <Tbody>
          <TableRow
            heading='Awaiting Service'
            value={data.countStateAwaitingService}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='With Service'
            value={data.countStateWithService}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Positive Move On'
            value={data.countStateMovedOnPositive}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Negative Move On'
            value={data.countStateMovedOnNegative}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Indeterminate Move On'
            value={data.countStateMovedOnIndeterminate}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
        </Tbody>
      </Table>
    </Card>
  );
}

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useUpdateServiceUser } from '../../../../hooks/useServiceUsers';
import { useSuccessToast } from '../../../../hooks/useToast';
import DateInput from '../../../../partials/input/DateInput';
import EthnicGroupSelect from '../../../../partials/input/EthnicGroupSelect';
import GenderSelect from '../../../../partials/input/GenderSelect';
import { IdentifierInput } from '../../../../partials/input/IdentifierInput';
import InvestmentSelect from '../../../../partials/input/InvestmentSelect';
import LocalAuthoritySelect from '../../../../partials/input/LocalAuthoritySelect';
import MoveOnCodeSelect from '../../../../partials/input/MoveOnCodeSelect';
import NumberOfDependentsSelect from '../../../../partials/input/NumberOfDependentsSelect';
import VulnerabilityGroupSelect from '../../../../partials/input/VulnerabilityGroupSelect';
import YearOfBirthSelect from '../../../../partials/input/YearOfBirthSelect';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import { type IServiceUserExtended } from '../../../../types';
import { type GenderCode, type MoveOnCode } from '../../../../types/ServiceUser';
import { schema } from './validationSchema';

interface PropsType {
  serviceUser: IServiceUserExtended
  disclosure: { isOpen: boolean, onClose: () => void }
}

interface FieldTypes {
  id: string
  identifier: string | null
  investmentId: string
  vulnerabilityGroupCodes: string[] | null
  genderCode: GenderCode | null
  yearOfBirth: number | null
  numDependents: number | null
  ethnicGroupCode: string | null
  localAuthorityCode: string | null
  serviceEndDate: string | null
  serviceStartDate: string | null
  moveOnCode: MoveOnCode | null
}

export default function EditServiceUserModal ({ serviceUser, disclosure }: Readonly<PropsType>) {
  const update = useUpdateServiceUser();
  const success = useSuccessToast();

  const form = useForm<FieldTypes>({
    values: serviceUser,
    resolver: joiResolver(schema),
  });

  const closeModal = () => {
    disclosure.onClose();
    update.reset();
    form.reset();
  };

  const onSubmit = form.handleSubmit(async (data) => {
    update.mutate(data, {
      onSuccess: () => {
        success('Service User Updated');
        closeModal();
      },
    });
  });

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={closeModal}
      scrollBehavior='inside'
      size='2xl'
    >
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalCloseButton tabIndex={-1} />
          <ModalHeader>Edit Service User</ModalHeader>

          <ModalBody>
            <Stack spacing={5}>
              <ErrorAlert error={update.error} />

              <IdentifierInput
                name="identifier"
                helperText='An ID for the service user from your internal systems'
                control={form.control}
                errors={form.formState.errors}
                tabIndex={0}
              />

              <InvestmentSelect
                name="investmentId"
                helperText='The investment the service user is supported by'
                control={form.control}
                errors={form.formState.errors}
              />

              <VulnerabilityGroupSelect
                name="vulnerabilityGroupCodes"
                control={form.control}
                errors={form.formState.errors}
              />

              <GenderSelect
                name="genderCode"
                control={form.control}
                errors={form.formState.errors}
              />

              <YearOfBirthSelect
                name="yearOfBirth"
                control={form.control}
                errors={form.formState.errors}
              />

              <NumberOfDependentsSelect
                name="numDependents"
                control={form.control}
                errors={form.formState.errors}
              />

              <EthnicGroupSelect
                name="ethnicGroupCode"
                control={form.control}
                errors={form.formState.errors}
              />

              <LocalAuthoritySelect
                name="localAuthorityCode"
                control={form.control}
                errors={form.formState.errors}
              />

              <DateInput
                name='serviceStartDate'
                label='Service Start Date'
                helperText='The date the service user began receiving support'
                control={form.control}
                errors={form.formState.errors}
              />

              <DateInput
                name='serviceEndDate'
                label='Service End Date'
                helperText='The date the service user stopped receiving support'
                control={form.control}
                errors={form.formState.errors}
              />

              <MoveOnCodeSelect
                name="moveOnCode"
                control={form.control}
                errors={form.formState.errors}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button aria-label="cancel" onClick={closeModal} variant="outline" mr={3}>Cancel</Button>
            <Button aria-label='submit' type="submit" colorScheme='blue' isDisabled={!form.formState.isDirty}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

import Joi from 'joi';
import { GenderCode } from '../../../../types/ServiceUser';

const isEmpty = (value: any) => value === null || value === undefined;

export const schema = Joi
  .object({
    id: Joi.string(),
    identifier: Joi.string().required().label('Record ID'),
    investmentId: Joi.string().required().label('Investment'),
    vulnerabilityGroupCodes: Joi.any().allow(null).label('Vulnerability Groups'),
    genderCode: Joi.string().valid(...Object.values(GenderCode)).allow(null).label('Gender'),
    yearOfBirth: Joi.number().allow(null).label('Year of Birth'),
    numDependents: Joi.number().allow(null).label('Number of Dependents'),
    ethnicGroupCode: Joi.string().allow(null).label('Ethic Group'),
    localAuthorityCode: Joi.string().allow(null).label('Local Authority'),
    serviceStartDate: Joi.custom((value, helpers) => {
      const [{ serviceEndDate, moveOnCode }] = helpers.state.ancestors;

      if (isEmpty(value) && (!isEmpty(serviceEndDate) || !isEmpty(moveOnCode))) {
        return helpers.message({ custom: 'Start date required if end date or move on reason provided' });
      }

      if (value === 'Invalid Date') {
        return helpers.message({ custom: 'Start date must be valid' });
      }

      return value;
    }),
    serviceEndDate: Joi.custom((value, helpers) => {
      const [{ moveOnCode }] = helpers.state.ancestors;

      if (isEmpty(value) && !isEmpty(moveOnCode)) {
        return helpers.message({ custom: 'End date required if move on reason provided' });
      }

      if (value === 'Invalid Date') {
        return helpers.message({ custom: 'End date must be valid' });
      }

      return value;
    }),
    moveOnCode: Joi.custom((value, helpers) => {
      const [{ serviceEndDate }] = helpers.state.ancestors;

      if (isEmpty(value) && !isEmpty(serviceEndDate)) {
        return helpers.message({ custom: 'Move On Reason required if end date provided' });
      }

      return value;
    }),
  })
  .options({
    stripUnknown: true,
  });


import { FormControl, FormErrorMessage, FormLabel, type SelectProps } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type Control, Controller, type FieldErrors } from 'react-hook-form';
import { ReactSelectStyles } from '../../theme';
import { ethnicGroupsFlat } from '../../utils/ethnicGroups';

interface PropsType extends SelectProps {
  name: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

export default function EthnicGroupSelect ({ name, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={name}>Ethnic Group</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            isClearable={true}
            isSearchable={true}
            placeholder="Select Ethnic Group..."
            aria-labelledby={`${name}-label`}
            options={ethnicGroupsFlat}
            onChange={(val: any) => { field.onChange(val?.value ?? null); }}
            value={ethnicGroupsFlat?.find((option) => option.value === field.value)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        )}
      />
      {hasError && <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>}
    </FormControl>
  );
}

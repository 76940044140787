import { Box, Button, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useResetPassword } from '../../../hooks/useAuthentication';
import { TextInput } from '../../../partials/input/TextInput';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import PasswordProgress from '../../../partials/output/PasswordProgres';
import { type ResetPasswordRequest } from '../../../types';

interface PropsTypes {
  passwordSecret: string
  onSuccess?: () => void
  onError?: (error: Error, variables: ResetPasswordRequest, context: unknown) => void
}

const schema = Joi.object({
  passwordSecret: Joi.string().required(),
  newPassword1: Joi.string().required()
    .min(8)
    .max(128)
    .regex(/[ -~]*[a-z][ -~]*/, 'reLowerCase') // at least 1 lower-case
    .regex(/[ -~]*[A-Z][ -~]*/, 'reUpperCase') // at least 1 upper-case
    .regex(/[ -~]*[0-9][ -~]*/, 'reNumber') // at least 1 number
    .label('Password')
    .messages({
      'string.min': '{#label} must be at least 8 characters',
      'string.max': '{#label} must be at most 128 characters',
      'string.pattern.name': '{#label} must contain at least 1 upper case, 1 lower case, 1 number',
    }),
  newPassword2: Joi.any()
    .valid(Joi.ref('newPassword1')).required()
    .label('Confirm Password')
    .messages({ 'any.only': 'Passwords must match' })
});

export default function SetPasswordForm ({ passwordSecret, onSuccess, onError }: Readonly<PropsTypes>) {
  const { mutate, error } = useResetPassword();
  const { register, handleSubmit, watch, formState: { errors } } = useForm<ResetPasswordRequest>({
    defaultValues: { passwordSecret },
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit((data) => {
    mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={error} />

      <TextInput
        name="newPassword1"
        type="password"
        placeholder="New Password"
        register={register}
        errors={errors}
      />

      <TextInput
        name="newPassword2"
        type="password"
        placeholder="Confirm Password"
        register={register}
        errors={errors}
      />

      <PasswordProgress password={watch('newPassword1')} />

      <Box textAlign='left' fontSize='sm' color='blackAlpha.700'>
        <UnorderedList>
          <ListItem>Minimum 8 characters</ListItem>
          <ListItem>Requires 1 upper case, 1 lower case, 1 number</ListItem>
          <ListItem>Special characters: @$!%*?&-_</ListItem>
        </UnorderedList>
      </Box>

      <Button type="submit" colorScheme='blue'>Continue</Button>
    </Stack>
  );
}

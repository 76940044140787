import { Tag } from '@chakra-ui/react';
import { AgeRange } from '../../types/ServiceUser';

export default function AgeRangeTag ({ ageRange }: Readonly<{ ageRange: AgeRange | null }>) {
  if (ageRange === AgeRange.Under16) return (<Tag minW='105px' backgroundColor='cyan.50'>Under 16</Tag>);
  if (ageRange === AgeRange.Age16To24) return (<Tag minW='105px' backgroundColor='cyan.100'>16 - 24</Tag>);
  if (ageRange === AgeRange.Age25To50) return (<Tag minW='105px' backgroundColor='cyan.200'>25 - 50</Tag>);
  if (ageRange === AgeRange.Over50) return (<Tag minW='105px' backgroundColor='cyan.300'>Over 50</Tag>);
  return <Tag minW='105px' backgroundColor='gray.50'>Not Provided</Tag>;
}

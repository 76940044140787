import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type ChangePasswordRequest, type IUserExtended, type InviteUser, type TeamSearchQuery, type UpdateProfile } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useGetProfile () {
  const queryClient = useQueryClient();
  const queryKey = ['useGetProfile'];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.profile.profile;
      const { data } = await apiClient.get<APIItemResponse<IUserExtended>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

export function useUpdateProfile () {
  return useMutation({
    mutationFn: async (user: UpdateProfile) => {
      const url = API_URL.profile.profile;
      const { data } = await apiClient.post<APIItemResponse<IUserExtended>>(url, user);
      return data;
    },
  });
}

export function useChangePassword () {
  return useMutation({
    mutationFn: async (request: ChangePasswordRequest) => {
      const url = API_URL.profile.password;
      const { data } = await apiClient.post(url, request);
      return data;
    },
  });
}

export function useListTeam (searchText: string = '') {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListTeam', page, searchText],
    queryFn: async () => {
      const url = API_URL.profile.team;
      const options: TeamSearchQuery = { params: { searchText, page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IUserExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useInviteUserToTeam () {
  return useMutation({
    mutationFn: async (user: InviteUser) => {
      const url = API_URL.profile.invite;
      const { data } = await apiClient.post<APIItemResponse<IUserExtended>>(url, user);
      return data;
    },
  });
}

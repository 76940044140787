import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  type AuthResponse,
  type ForgottenPasswordRequest,
  type LoginRequest,
  type ResetPasswordRequest,
  type WhoAmIResponse
} from '../types';
import { apiClient, type APIItemResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useLogin () {
  return useMutation({
    mutationFn: async ({ login, password }: LoginRequest) => {
      const url = API_URL.auth.login;
      const options = { auth: { username: login, password } };
      const response = await apiClient.get<APIItemResponse<AuthResponse>>(url, options);
      return response.data.docs;
    }
  });
}

export function useWhoAmI () {
  const queryClient = useQueryClient();
  const queryKey = ['useWhoAmI'];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.auth.whoAmI;
      const { data } = await apiClient.get<APIItemResponse<WhoAmIResponse>>(url);
      return data;
    }
  });

  return { ...query, clearCache };
}

export function useForgottenPassword () {
  return useMutation({
    mutationFn: async ({ login }: ForgottenPasswordRequest) => {
      const url = API_URL.auth.forgottenPassword;
      await apiClient.post<APIItemResponse<'ok'>>(url, { login });
    }
  });
}

export function useResetPassword () {
  return useMutation({
    mutationFn: async (request: ResetPasswordRequest) => {
      const url = API_URL.auth.resetPassword;
      return await apiClient.post<APIItemResponse<'ok'>>(url, request);
    }
  });
}

import { Card, Center, SimpleGrid, Stack, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { useInvestmentsOverview, useServiceUserOverview } from '../../../hooks/useOverview';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import { type IInvestmentOverview, type IServiceUserOverview } from '../../../types';
import AgeRangeWidget from './partials/AgeRangeWidget';
import EthnicGroupsWidget from './partials/EthnicGroupsWidget';
import FundManagersWidget from './partials/FundManagersWidget';
import GenderWidget from './partials/GenderWidget';
import NewsWidget from './partials/NewsWidget';
import RegionsWidget from './partials/RegionsWidget';
import StateWidget from './partials/StateWidget';
import VulnerabilityGroupsWidget from './partials/VulnerabilityGroupsWidget';

const spacing = 6;

export default function DashboardForFundRecipient () {
  const suOverview = useServiceUserOverview();
  const invOverview = useInvestmentsOverview();

  if (suOverview.isLoading || invOverview.isLoading) return <Loading />;

  if (suOverview.error != null) return <ErrorAlert error={suOverview.error} />;
  if (invOverview.error != null) return <ErrorAlert error={invOverview.error} />;

  const serviceUsers = suOverview.data?.docs as IServiceUserOverview;
  const investments = invOverview.data?.docs as IInvestmentOverview;

  // Show welcome guide if there are no service users
  if (serviceUsers.countServiceUsers === 0) {
    return (
      <Stack spacing={spacing}>
        <Center>
          <Text fontSize="lg" color="gray.500">
            TODO Welcome guide for Service Provider
          </Text>
        </Center>
      </Stack>
    );
  }

  return (
    <Stack spacing={spacing}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={spacing}>

        <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
          <Stat as={Card} p={4}>
            <StatLabel>Service Users</StatLabel>
            <StatNumber fontSize='4xl'>{serviceUsers.countServiceUsers}</StatNumber>
            {/* <StatHelpText>{serviceUsers.countServiceUsers} total</StatHelpText> */}
          </Stat>

          <Stat as={Card} p={4}>
            <StatLabel>Dependents</StatLabel>
            <StatNumber fontSize='4xl'>{serviceUsers.sumNumDependents}</StatNumber>
            {/* <StatHelpText>{serviceUsers.sumNumDependents} total</StatHelpText> */}
          </Stat>

          <Stat as={Card} p={4}>
            <StatLabel>Total Investment Received</StatLabel>
            <StatNumber fontSize='4xl'><Currency value={investments.sumInvestmentValue} /></StatNumber>
          </Stat>

          <Stat as={Card} p={4}>
            <StatLabel>Av. Investment / Service User</StatLabel>
            <StatNumber fontSize='4xl'><Currency value={investments.avInvestmentPerServiceUser} /></StatNumber>
          </Stat>
        </SimpleGrid>

        <NewsWidget />

      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={spacing}>
        <VulnerabilityGroupsWidget data={serviceUsers} />
        <RegionsWidget data={serviceUsers} />
        <EthnicGroupsWidget data={serviceUsers} />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={spacing}>
        <StateWidget data={serviceUsers} />
        <GenderWidget data={serviceUsers} />
        <AgeRangeWidget data={serviceUsers} />
      </SimpleGrid>

      <FundManagersWidget />
    </Stack>
  );
}

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useInviteUserToServiceProvider } from '../../../../hooks/useServiceProvider';
import { useSuccessToast } from '../../../../hooks/useToast';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';

interface FieldTypes {
  name: string
  login: string
}

const schema = Joi.object({
  name: Joi.string().required(),
  login: Joi.string().required().email({ tlds: { allow: false } }),
}).options({ stripUnknown: true });

export default function InviteUserModal ({ organisationId, isOpen, onClose, onSuccess }: Readonly<{ organisationId: string, isOpen: boolean, onClose: () => void, onSuccess: () => void }>) {
  const success = useSuccessToast();
  const invite = useInviteUserToServiceProvider(organisationId);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const closeModal = () => {
    onClose();
    invite.reset();
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    invite.mutate(data, {
      onSuccess: () => {
        success(`User invitation sent to ${data.login}`);
        closeModal();
        onSuccess();
      }
    });
  });

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size='lg'>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Invite User</ModalHeader>
        <ModalCloseButton />

        <ModalBody pb="6">
          <ErrorAlert error={invite.error} />
          <Stack spacing={6}>
            <TextInput
              name="name"
              label="Name"
              placeholder="Charlie Bucket"
              register={register}
              errors={errors}
            />

            <TextInput
              name="login"
              type="email"
              label="Email"
              placeholder="charlie.bucket@example.com"
              register={register}
              errors={errors}
            />

            <Stack color='gray.500' spacing={4}>
              <Text>Inviting users to your account may provide them with access to sensitive information. Please ensure email addresses are entered correctly.</Text>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button aria-label='submit' type="submit" colorScheme='blue' mr="4">Submit</Button>
          <Button aria-label="cancel" onClick={closeModal} variant="outline">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import ActivityFeed from './pages/app/ActivityFeed';
import AnalyticsHome from './pages/app/Analytics/AnalyticsHome';
import Dashboard from './pages/app/Dashboard/Dashboard';
import FundView from './pages/app/Funds/FundView';
import FundsList from './pages/app/Funds/FundsList';
import OrganisationCreate from './pages/app/Organisations/OrganisationCreate';
import OrganisationUpdate from './pages/app/Organisations/OrganisationUpdate';
import OrganisationsList from './pages/app/Organisations/OrganisationsList';
import ChangePassword from './pages/app/Profile/ChangePassword';
import Logout from './pages/app/Profile/Logout';
import MyProfile from './pages/app/Profile/MyProfile';
import MyTeam from './pages/app/Profile/MyTeam';
import ServiceProvidersList from './pages/app/ServiceProviders/ServiceProvidersList';
import ServiceProvidersView from './pages/app/ServiceProviders/ServiceProvidersView';
import ManageServiceUsers from './pages/app/ServiceUsers/ManageServiceUsers';
import Support from './pages/app/Support';
import UserCreate from './pages/app/Users/UserCreate';
import UserUpdate from './pages/app/Users/UserUpdate';
import UsersList from './pages/app/Users/UsersList';
import ForgotPassword from './pages/public/ForgotPassword';
import Login from './pages/public/Login';
import NotFoundError from './pages/public/NotFoundError';
import SetPassword from './pages/public/SetPassword';
import DashboardLayout from './partials/layout/DashboardLayout';

const routes = [
  { path: '/login', element: <Login /> },
  { path: '/logout', element: <Logout /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/set-password', element: <SetPassword /> },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'service-users', element: <ManageServiceUsers /> },
      { path: 'support', element: <Support /> },
      { path: 'activity', element: <ActivityFeed /> },
      { path: 'analytics', element: <AnalyticsHome/> },
      {
        path: 'portfolio',
        children: [
          {
            path: 'funds',
            children: [
              { path: '', element: <FundsList /> },
              { path: ':id', element: <FundView /> }
            ]
          },
          {
            path: 'service-providers',
            children: [
              { path: '', element: <ServiceProvidersList /> },
              { path: ':id', element: <ServiceProvidersView /> }
            ]
          }
        ]
      },
      {
        path: 'profile',
        children: [
          { path: '', element: <MyProfile /> },
          { path: 'team', element: <MyTeam /> },
          { path: 'password', element: <ChangePassword /> },
        ]
      },
      {
        path: 'root',
        children: [
          {
            path: 'organisations',
            children: [
              { path: '', element: <OrganisationsList /> },
              { path: 'create', element: <OrganisationCreate /> },
              { path: ':id', element: <OrganisationUpdate /> }
            ]
          },
          {
            path: 'users',
            children: [
              { path: '', element: <UsersList /> },
              { path: 'create', element: <UserCreate /> },
              { path: ':id', element: <UserUpdate /> }
            ]
          },
        ]
      }
    ]
  },
  { path: '*', element: <NotFoundError /> }
];

export default routes;

import { Flex, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { type UseFormRegister } from 'react-hook-form';

interface PropsType {
  name: string
  label: string
  register: UseFormRegister<any>
}

export function SwitchInput ({ name, label, register }: PropsType) {
  return (
    <FormControl as={Flex}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Switch id={name} {...register(name)}/>
    </FormControl>
  );
}

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useCreateInvestment } from '../../../../hooks/useInvestments';
import { useSuccessToast } from '../../../../hooks/useToast';
import { CurrencyInput } from '../../../../partials/input/CurrencyInput';
import DateInput from '../../../../partials/input/DateInput';
import ServiceProviderSelect from '../../../../partials/input/ServiceProviderSelect';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';

interface PropsType {
  fundId: string
  disclosure: {
    isOpen: boolean
    onClose: () => void
  }
}

interface FieldTypes {
  recipientId: string
  drawdownDate: string
  value: number
}

const schema = Joi.object({
  recipientId: Joi.string().required().label('Fund Recipient'),
  drawdownDate: Joi.string().required().label('Drawdown Date').messages({
    'string.base': '"Drawdown Date" is required',
  }),
  value: Joi.number().required().label('Investment Value'),
});

export default function CreateInvestmentModal ({ fundId, disclosure }: Readonly<PropsType>) {
  const create = useCreateInvestment(fundId);
  const success = useSuccessToast();

  const { handleSubmit, formState: { errors }, register, control, reset } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const closeModal = () => {
    disclosure.onClose();
    create.reset();
    reset();
  };

  const onSubmit = handleSubmit(async (request) => {
    create.mutate(request, {
      onSuccess: () => {
        success('Investment created');
        closeModal();
      },
    });
  });

  return (
    <Modal isOpen={disclosure.isOpen} onClose={closeModal} size='lg'>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Create Investment</ModalHeader>
        <ModalCloseButton />

        <ModalBody pb="6">
          <ErrorAlert error={create.error} />
          <Stack spacing={6}>
            <ServiceProviderSelect
              label="Service Provider"
              name="recipientId"
              control={control}
              errors={errors}
            />

            <DateInput
              name='drawdownDate'
              label='Drawdown Date'
              helperText='The date the investment was drawn down'
              control={control}
              errors={errors}
            />

            <CurrencyInput
              label="Investment Amount"
              name="value"
              placeholder="500000"
              register={register}
              errors={errors}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button aria-label='submit' type="submit" colorScheme='blue' mr="4">Save</Button>
          <Button aria-label="cancel" onClick={closeModal} variant="outline">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Tag, Button, Avatar, ButtonGroup } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useForgottenPassword } from '../../../../hooks/useAuthentication';
import { useErrorToast, useSuccessToast } from '../../../../hooks/useToast';
import { useListUsers } from '../../../../hooks/useUsers';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import Pagination from '../../../../partials/output/Pagination';
import UserStateTag from '../../../../partials/output/UserStateTag';

export default function UsersTable () {
  const { data, isLoading, isError, error, pagination } = useListUsers();
  const forgotPassword = useForgottenPassword();
  const success = useSuccessToast();
  const errorT = useErrorToast();

  const handleForgotPassword = (login: string) => {
    forgotPassword.mutate({ login }, {
      onSuccess: () => { success(`Password reset email sent to ${login}`); },
      onError: (error) => { errorT(error.message); },
    });
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  // FUTURE Add search and filter

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Name</Th>
              <Th>Login</Th>
              <Th>State</Th>
              <Th>Organisation</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.docs.map((user) => (
              <Tr key={user.id}>
                <Td>
                  <Avatar size='sm' name={user.name} />
                </Td>
                <Td>
                  {user.name}{' '}
                  {user.isRoot && <Tag colorScheme='red'>Root</Tag>}
                </Td>
                <Td>
                  <a href={`mailto:${user.login}`}>{user.login}</a>
                </Td>
                <Td>
                  <UserStateTag state={user.state} />
                </Td>
                <Td>
                  <Link to={`/root/organisations/${user.organisationId}`}>
                    {user.organisationName}
                  </Link>
                </Td>
                <Td>
                  <ButtonGroup spacing="2">
                    <Button as={Link} to={`/root/users/${user.id}`} size='sm' colorScheme='teal'>Edit</Button>
                    <Button onClick={() => { handleForgotPassword(user.login); }} size='sm' colorScheme='yellow'>Forgot Password</Button>
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination {...pagination} />
    </>
  );
}

import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PublicLayout from '../../partials/layout/PublicLayout';
import ForgotPasswordForm from './partials/ForgotPasswordForm';

export default function ForgotPassword () {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <PublicLayout>
      <Stack spacing={8}>
        <Heading size="lg">Forgot Password</Heading>
        {
          isSuccess
            ? <Text>Please check your email for a link to reset your password.</Text>
            : <ForgotPasswordForm onSuccess={() => { setIsSuccess(true); }} />
        }
        <Button variant="link" as={Link} to="/login">Back to Login</Button>
      </Stack>
    </PublicLayout>
  );
}

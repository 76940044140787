import { Card, Center, Heading } from '@chakra-ui/react';
import CircleChart from '../../../../partials/output/CircleChart';
import { type IServiceUserOverview } from '../../../../types';

export default function VulnerabilityGroupsWidget ({ data }: { data: IServiceUserOverview }) {
  const groupData = [
    {
      key: 'LTU',
      label: 'People experiencing long term unemployment',
      value: data.countVulnerabilityGroupLtu,
    },
    {
      key: 'HLN',
      label: 'People experiencing homelessness',
      value: data.countVulnerabilityGroupHln,
    },
    {
      key: 'PFE',
      label: 'People living in poverty and/or financial exclusion',
      value: data.countVulnerabilityGroupPfe,
    },
    {
      key: 'ADD',
      label: 'People dealing with addiction issues',
      value: data.countVulnerabilityGroupAdd,
    },
    {
      key: 'ILL',
      label: 'People with long-term health conditions/life threatening or terminal illness',
      value: data.countVulnerabilityGroupIll,
    },
    {
      key: 'LDN',
      label: 'People with learning disabilities and other neurodivergence',
      value: data.countVulnerabilityGroupLdn,
    },
    {
      key: 'MHN',
      label: 'People with mental health needs',
      value: data.countVulnerabilityGroupMhn,
    },
    {
      key: 'PHD',
      label: 'People with physical disabilities or sensory impairments',
      value: data.countVulnerabilityGroupPhd,
    },
    {
      key: 'CAR',
      label: 'Voluntary carers',
      value: data.countVulnerabilityGroupCar,
    },
    {
      key: 'VLP',
      label: 'Vulnerable parents',
      value: data.countVulnerabilityGroupVlp,
    },
    {
      key: 'VLC',
      label: 'Vulnerable children',
      value: data.countVulnerabilityGroupVlc,
    },
    {
      key: 'VYP',
      label: 'Vulnerable young people',
      value: data.countVulnerabilityGroupVyp,
    },
    {
      key: 'OLD',
      label: 'Older people',
      value: data.countVulnerabilityGroupOld,
    },
    {
      key: 'EXO',
      label: 'Ex/Offenders and prisoners',
      value: data.countVulnerabilityGroupExo,
    },
    {
      key: 'ABU',
      label: 'People who have experienced crime or abuse',
      value: data.countVulnerabilityGroupAbu,
    },
    {
      key: 'REF',
      label: 'Refugees, asylum seekers, undocumented and other migrants',
      value: data.countVulnerabilityGroupRef,
    }
  ];

  return (
    <Card p={4}>
      <Heading size='md'>Vulnerability Groups</Heading>
      <Center mt={4} height={{ base: 'auto', md: '500px' }}>
        <CircleChart data={groupData} />
      </Center>
    </Card>
  );
}

import { Box, Card, Center, HStack, Heading, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Currency from '../../../partials/output/Currency';
import HeadingToolTip from '../../../partials/output/HeadingToolTip';
import { AgeRange, SegmentType, ServiceUserState, type IAnalyticsSegment, type ISegmentInvestments } from '../../../types';
import { getVulnerabilityGroupByCode } from '../../../utils/vulnerabilityGroups';
import AgeRangeChart from './partials/AgeRangeChart';
import EthnicGroupsChart from './partials/EthnicGroupsChart';
import GenderChart from './partials/GenderChart';
import { DifferenceMetric } from './partials/OverviewTable';
import RegionChart from './partials/RegionChart';
import StateChart from './partials/StateChart';
import VulnerabilityGroupsChart from './partials/VulnerabilityGroupsChart';
import { getDateString, getGenderLabel, getSegmentLabel, type AnalyticsSegmentRange } from './utils';

function StateHeading ({ state }: Readonly<{ state: string }>) {
  switch (state) {
    case ServiceUserState.AwaitingService: return <Text>Service Users Awaiting Service</Text>;
    case ServiceUserState.WithService: return <Text>Service Users With Service</Text>;
    case ServiceUserState.MovedOnPositive: return <Text>Service Users Moved On (Positive)</Text>;
    case ServiceUserState.MovedOnNegative: return <Text>Service Users Moved On (Negative)</Text>;
    case ServiceUserState.MovedOnIndeterminate: return <Text>Service Users Moved On (Indeterminate)</Text>;
    default: return <Text>Not Provided</Text>;
  }
}

function AgeRangeHeading ({ ageRange }: Readonly<{ ageRange: string }>) {
  switch (ageRange) {
    case AgeRange.Under16: return <Text>Age: Under 16</Text>;
    case AgeRange.Age16To24: return <Text>Age: 16 - 24</Text>;
    case AgeRange.Age25To50: return <Text>Age: 25 - 50</Text>;
    case AgeRange.Over50: return <Text>Age: Over 50</Text>;
    default: return <Text>Age: Not Provided</Text>;
  }
}

function VulnerabilityGroupHeading ({ code }: Readonly<{ code: string }>) {
  const group = getVulnerabilityGroupByCode(code);
  return <Text>{group?.label ?? 'Not Provided'}</Text>;
}

function InvestmentHeading ({ item }: Readonly<{ item: ISegmentInvestments }>) {
  return (
    <>
      <Link as={RouterLink} to={`/portfolio/service-providers/${item.segmentId}`} >{item.fundRecipientName}</Link>{' '}
      (<Link as={RouterLink} to={`/portfolio/funds/${item.fundId}`}>
        <Currency value={item.investmentValue} />{' - '}{getDateString(item.investmentDrawdownDate)}
      </Link>)
    </>
  );
}

export function ItemHeading ({ item }: Readonly<{ item: IAnalyticsSegment }>) {
  switch (item.segmentType) {
    case SegmentType.FUND_RECIPIENT:
      return <Link as={RouterLink} to={`/portfolio/service-providers/${item.segmentId}`} >{item.fundRecipientName}</Link>;
    case SegmentType.FUND:
      return <Link as={RouterLink} to={`/portfolio/funds/${item.segmentId}`} >{item.fundName}</Link>;
    case SegmentType.INVESTMENT:
      return <InvestmentHeading item={item} />;
    case SegmentType.STATE:
      return <StateHeading state={item.segmentId} />;
    case SegmentType.ETHNIC_GROUP:
      return <>{item.ethnicGroupDescription}</>;
    case SegmentType.AGE_RANGE:
      return <AgeRangeHeading ageRange={item.segmentId as any} />;
    case SegmentType.GENDER:
      return <Text>{getGenderLabel(item.segmentId as any)}</Text>;
    case SegmentType.VULNERABILITY_GROUP:
      return <VulnerabilityGroupHeading code={item.segmentId} />;
    default: return <>{item.segmentId}</>;
  }
}

interface AnalyticsItemProps {
  segmentType: SegmentType
  startDate: string
  endDate: string
  selectedItem?: AnalyticsSegmentRange
}

export default function AnalyticsItem ({ segmentType, startDate, endDate, selectedItem }: Readonly<AnalyticsItemProps>) {
  if (selectedItem === undefined) {
    return (
      <Card py={8}>
        <Center as={Stack} spacing={8} >
          <Box maxWidth='40rem' textAlign='center'>
            <Text fontSize="3xl" color="gray.500" mb={8}>Select a {getSegmentLabel(segmentType)} to explore</Text>
          </Box>
        </Center>
      </Card>
    );
  }

  return (
    <Card p={4}>
      <Stack spacing={8}>
        <Stack spacing={2} textAlign='center' id="overviewTableLabel">
          <Heading size='lg'> <ItemHeading item={selectedItem.d2} /> </Heading>
          <Heading size='md' fontWeight='normal'> {getDateString(startDate)} - {getDateString(endDate)} </Heading>
        </Stack>

        <SimpleGrid
          columns={{
            base: 1,
            md: (segmentType === SegmentType.INVESTMENT) ? 3 : 4
          }}
          spacing={8}
        >
          <Stack spacing={1}>
            <HeadingToolTip size='sm' label='Count of Service Users, with change in this period'>Service Users</HeadingToolTip>
            <HStack spacing={2} fontSize='5xl'>
              <Text>{selectedItem.d2.countServiceUsers}</Text>
              <DifferenceMetric diff={selectedItem.d2.countServiceUsers - selectedItem.d1.countServiceUsers} />
            </HStack>
          </Stack>

          <Stack spacing={1}>
            <HeadingToolTip size='sm' label='Count of Dependents for Service Users, with change in this period'>Dependents</HeadingToolTip>
            <HStack spacing={2} fontSize='5xl'>
              <Text>{selectedItem.d2.countDependents}</Text>
              <DifferenceMetric diff={selectedItem.d2.countDependents - selectedItem.d1.countDependents} />
            </HStack>
          </Stack>

          <Stack spacing={1} display={(segmentType === SegmentType.INVESTMENT) ? 'none' : 'flex'}>
            <HeadingToolTip size='sm' label='Total amount invested, with count of Investments'>Total Invested (count)</HeadingToolTip>
            <HStack spacing={2} fontSize='5xl'>
              <Text><Currency value={selectedItem.d2.sumInvestmentValue} /> ({selectedItem.d2.countInvestments})</Text>
            </HStack>
          </Stack>

          <Stack spacing={1}>
            <HeadingToolTip size='sm' label='Average amount invested per Service User (Total Invested / Service Users)'>Av. Invested per Service User</HeadingToolTip>
            <HStack spacing={2} fontSize='5xl'>
              <Text><Currency value={selectedItem.d2.avInvestmentValuePerServiceUser} /></Text>
            </HStack>
          </Stack>
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            md: ([SegmentType.VULNERABILITY_GROUP, SegmentType.REGION, SegmentType.ETHNIC_GROUP].includes(segmentType)) ? 2 : 3,
          }}
          spacing={8}
        >
          {segmentType !== SegmentType.VULNERABILITY_GROUP && <VulnerabilityGroupsChart row={selectedItem} />}
          {segmentType !== SegmentType.REGION && <RegionChart row={selectedItem} />}
          {segmentType !== SegmentType.ETHNIC_GROUP && <EthnicGroupsChart row={selectedItem} />}
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            md: ([SegmentType.STATE, SegmentType.GENDER, SegmentType.AGE_RANGE].includes(segmentType)) ? 2 : 3
          }}
          spacing={8}
        >
          {segmentType !== SegmentType.STATE && <StateChart row={selectedItem} />}
          {segmentType !== SegmentType.GENDER && <GenderChart row={selectedItem} />}
          {segmentType !== SegmentType.AGE_RANGE && <AgeRangeChart row={selectedItem} />}
        </SimpleGrid>

        <Text fontSize='sm' color='gray.500' textAlign='center'>Report generated at {new Date().toLocaleString()}</Text>
      </Stack>
    </Card >
  );
}

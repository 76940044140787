import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { type CSSObjectWithLabel } from 'chakra-react-select';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

// Input Component
const inputStyle = definePartsStyle({
  // inherited by chakra-react-select dropdownIndicator
  addon: {
    padding: 2
  },
});

// Tooltip theme
const $arrowBg = cssVar('popper-arrow-bg');
const tooltipStyle = {
  color: 'gray.800',
  backgroundColor: 'gray.200',
  [$arrowBg.variable]: 'colors.gray.200',
};

// Custom theme
export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        // FUTURE colour scheme
        backgroundColor: 'white',
      },
    },
  },
  components: {
    Input: defineMultiStyleConfig({ baseStyle: inputStyle }),
    Tooltip: defineStyleConfig({ baseStyle: tooltipStyle })
  },
});

// React select style overrides
// Also set menuPortalTarget={document.body} on the component
export const ReactSelectStyles = {
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 })
};

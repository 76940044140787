/* eslint-disable @typescript-eslint/no-extraneous-class */

import { type ServiceUserState } from './ServiceUserExtended';

export enum SegmentType {
  FUND_RECIPIENT = 'FUND_RECIPIENT',
  FUND = 'FUND',
  INVESTMENT = 'INVESTMENT',
  STATE = 'STATE',
  ETHNIC_GROUP = 'ETHNIC_GROUP',
  GENDER = 'GENDER',
  AGE_RANGE = 'AGE_RANGE',
  VULNERABILITY_GROUP = 'VULNERABILITY_GROUP',
  REGION = 'REGION',
}

export type IAnalyticsSegment =
  ISegmentFundRecipients |
  ISegmentFunds |
  ISegmentInvestments |
  ISegmentStates |
  ISegmentEthnicGroups |
  ISegmentGenders |
  ISegmentAgeRanges |
  ISegmentVulnerabilityGroups |
  ISegmentRegions;

export interface ISegmentFundRecipients extends ISegmentMetrics {
  segmentId: string
  segmentType: SegmentType.FUND_RECIPIENT
  fundRecipientName: string
}

export interface ISegmentFunds extends ISegmentMetrics {
  segmentId: string
  segmentType: SegmentType.FUND
  fundName: string
  fundValue: number
  fundCurrency: string
}

export interface ISegmentInvestments extends ISegmentMetrics {
  segmentId: string
  segmentType: SegmentType.INVESTMENT
  investmentValue: number
  investmentCurrency: string
  investmentDrawdownDate: Date
  investmentCreatedAt: Date
  fundId: string
  fundName: string
  fundValue: number
  fundCurrency: string
  fundRecipientId: string
  fundRecipientName: string
}

export interface ISegmentStates extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.STATE
}

export interface ISegmentEthnicGroups extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.ETHNIC_GROUP
  ethnicGroupDescription: string
  ethnicGroupSection: string
  ethnicGroupSectionDescription: string
}

export interface ISegmentGenders extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.GENDER
  genderDescription: string
}

export interface ISegmentAgeRanges extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.AGE_RANGE
  ageRangeDescription: string
}

export interface ISegmentVulnerabilityGroups extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.VULNERABILITY_GROUP
  vulnerabilityGroupDescription: string
}

export interface ISegmentRegions extends ISegmentMetrics {
  segmentId: ServiceUserState
  segmentType: SegmentType.REGION
  nation: string
  countLocalAuthorities: number
  pop2020: number
  area: number
}

// Metrics common to all segments
// NOTE: For some segments, some of these metrics may consistently zero
interface ISegmentMetrics {
  segmentType: SegmentType

  countServiceUsers: number
  countDependents: number
  countFundRecipients: number
  countFunds: number
  countInvestments: number

  sumInvestmentValue: number
  avInvestmentValuePerServiceUser: number

  countStateAwaitingService: number
  countStateWithService: number
  countStateMovedOnPositive: number
  countStateMovedOnNegative: number
  countStateMovedOnIndeterminate: number

  countGenderMale: number
  countGenderFemale: number
  countGenderNotSay: number
  countGenderOther: number
  countGenderNull: number

  countAgeUnder16: number
  countAge16To24: number
  countAge25To50: number
  countAgeOver50: number
  countAgeNull: number

  countNationEngland: number
  countNationScotland: number
  countNationWales: number
  countNationNorthernIreland: number
  countNationNull: number

  countRegionEastMidlands: number
  countRegionEastEngland: number
  countRegionLondon: number
  countRegionNorthEast: number
  countRegionNorthWest: number
  countRegionNorthernIreland: number
  countRegionScotland: number
  countRegionSouthEast: number
  countRegionSouthWest: number
  countRegionWales: number
  countRegionWestMidlands: number
  countRegionYorkshireHumber: number
  countRegionNull: number

  countEthnicGroupAb: number
  countEthnicGroupAc: number
  countEthnicGroupAi: number
  countEthnicGroupAo: number
  countEthnicGroupAp: number
  countEthnicGroupBa: number
  countEthnicGroupBc: number
  countEthnicGroupBo: number
  countEthnicGroupMba: number
  countEthnicGroupMbc: number
  countEthnicGroupMo: number
  countEthnicGroupMwa: number
  countEthnicGroupOa: number
  countEthnicGroupOo: number
  countEthnicGroupWb: number
  countEthnicGroupWg: number
  countEthnicGroupWi: number
  countEthnicGroupWo: number
  countEthnicGroupWr: number
  countEthnicGroupNull: number

  countVulnerabilityGroupLtu: number
  countVulnerabilityGroupHln: number
  countVulnerabilityGroupPfe: number
  countVulnerabilityGroupAdd: number
  countVulnerabilityGroupIll: number
  countVulnerabilityGroupLdn: number
  countVulnerabilityGroupMhn: number
  countVulnerabilityGroupPhd: number
  countVulnerabilityGroupCar: number
  countVulnerabilityGroupVlp: number
  countVulnerabilityGroupVlc: number
  countVulnerabilityGroupVyc: number
  countVulnerabilityGroupVyp: number
  countVulnerabilityGroupOld: number
  countVulnerabilityGroupExo: number
  countVulnerabilityGroupAbu: number
  countVulnerabilityGroupRef: number
  countVulnerabilityGroupNull: number

  // Segment function arguments
  argOrganisationId: string
  argQueryDate: Date
}

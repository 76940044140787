import { Container, Heading } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSuccessToast } from '../../../hooks/useToast';
import { useGetUser } from '../../../hooks/useUsers';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import UserUpdateForm from './partials/UserUpdateForm';

export default function UserUpdate () {
  const success = useSuccessToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isError, error, clearCache } = useGetUser(id as string);

  const onSuccess = async () => {
    await clearCache();
    success('User updated');
    navigate('/root/users');
  };

  if (isLoading || data === undefined) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">Edit User</Heading>
      <UserUpdateForm user={data.docs} onSuccess={onSuccess} />
    </Container>
  );
}

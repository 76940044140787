import { Card, Center, Progress, SimpleGrid, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, Tooltip } from '@chakra-ui/react';
import { useInvestmentsOverview, useServiceUserOverview } from '../../../hooks/useOverview';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import { type IInvestmentOverview, type IServiceUserOverview } from '../../../types';
import ActivityWidget from './partials/ActivityWidget';
import NewsWidget from './partials/NewsWidget';

const spacing = 6;

export default function DashboardForFundManager () {
  const suOverview = useServiceUserOverview();
  const invOverview = useInvestmentsOverview();

  if (suOverview.isLoading || invOverview.isLoading) return <Loading />;

  if (suOverview.error != null) return <ErrorAlert error={suOverview.error} />;
  if (invOverview.error != null) return <ErrorAlert error={invOverview.error} />;

  const serviceUsers = suOverview.data?.docs as IServiceUserOverview;
  const investments = invOverview.data?.docs as IInvestmentOverview;

  // Show welcome guide if there are no funds or investments
  if (investments.countFunds === 0 || investments.countInvestments === 0) {
    return (
      <Stack spacing={spacing}>
        <Center>
          <Text fontSize="lg" color="gray.500">
            TODO Welcome guide for Fund Manager
          </Text>
        </Center>
      </Stack>
    );
  }

  return (
    <Stack spacing={spacing}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={spacing}>

        <SimpleGrid columns={{ base: 1 }} spacing={spacing}>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={spacing}>
            <Stat as={Card} p={4}>
              <StatLabel>
                <Tooltip hasArrow label='Count of Service Users (AKA Beneficiaries)'>Total Service Users</Tooltip>
              </StatLabel>
              <StatNumber fontSize='4xl'>{serviceUsers.countServiceUsers}</StatNumber>
            </Stat>

            <Stat as={Card} p={4}>
              <StatLabel>
                <Tooltip hasArrow label='Count of Dependents of Service Users'>Total Dependents</Tooltip>
              </StatLabel>
              <StatNumber fontSize='4xl'>{serviceUsers.sumNumDependents}</StatNumber>
            </Stat>

            <Stat as={Card} p={4}>
              <StatLabel>
                <Tooltip hasArrow label='Count of Service Providers (AKA Borrowers, Investees or Portfolio Companies)'>Service Providers</Tooltip>
              </StatLabel>
              <StatNumber fontSize='4xl'>{investments.countFundRecipients}</StatNumber>
            </Stat>
          </SimpleGrid>

          <Stat as={Card} p={4}>
            <StatNumber>{investments.percInvested}% Funds Deployed</StatNumber>
            <Progress mt={1} mb={2} value={investments.percInvested} />
            <StatHelpText><Currency value={investments.sumFundValue} /> Available via {investments.countFunds} Funds</StatHelpText>
            <StatHelpText><Currency value={investments.sumInvestmentValue} /> Deployed via {investments.countInvestments} Investments</StatHelpText>
          </Stat>

          <NewsWidget />
        </SimpleGrid>

        <ActivityWidget />

      </SimpleGrid>
    </Stack>
  );
}

import { Button, Card, Center, Container, Flex, Heading, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useListFunds } from '../../../hooks/useFunds';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import CreateFundModal from './partials/CreateFundModal';

export default function FundsList () {
  const { data, isLoading, isError, error, refetch } = useListFunds();
  const createModal = useDisclosure();

  useEffect(() => {
    if (!createModal.isOpen) {
      void refetch();
    }
  }, [createModal.isOpen]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  if (data?.docs.length === 0) {
    return (
      <Container py="4" maxW="8xl">
        <Heading>Funds</Heading>
        <Center as={Stack} mt={8} spacing={8}>
          <Text fontSize="lg" color="gray.500">Add a Fund to get started</Text>
          <Button onClick={createModal.onOpen} colorScheme="blue" mb="4">New Fund</Button>
        </Center>

        <CreateFundModal disclosure={createModal} />
      </Container>
    );
  }

  const total = {
    sumInvestments: data?.docs.reduce((acc, fund) => acc + fund.sumInvestmentsValue, 0),
    value: data?.docs.reduce((acc, fund) => acc + fund.value, 0),
    countServiceUsers: data?.docs.reduce((acc, fund) => acc + fund.countServiceUsers, 0),
    countInvestments: data?.docs.reduce((acc, fund) => acc + fund.countInvestments, 0),
  };

  return (
    <Container py="4" maxW="6xl">
      <Flex>
        <Heading mb="4">Funds</Heading>
        <Spacer />
        <Button onClick={createModal.onOpen} colorScheme="blue" mb="4">New Fund</Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" py="4">
        <Stat as={Card} p="4">
          <StatLabel>Total Invested</StatLabel>
          <StatNumber><Currency value={total.sumInvestments} /></StatNumber>
          <StatHelpText>All Funds Value: <Currency value={total.value} /></StatHelpText>
        </Stat>
        <Stat as={Card} p="4">
          <StatLabel>Supported Service Users</StatLabel>
          <StatNumber>{total.countServiceUsers}</StatNumber>
          <StatHelpText>Through {total.countInvestments} investments</StatHelpText>
        </Stat>
      </SimpleGrid>

      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Fund Value</Th>
              <Th>Investments Value<br />(Count)</Th>
              <Th>Service<br />Users</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.docs.map((fund) => (
              <Tr key={fund.id}>
                <Td>{fund.name}</Td>
                <Td><Currency value={fund.value} /></Td>
                <Td><Currency value={fund.sumInvestmentsValue} /> ({fund.countInvestments})</Td>
                <Td>{fund.countServiceUsers}</Td>
                <Td>
                  <Button as={Link} to={`/portfolio/funds/${fund.id}`} size='sm' colorScheme='teal'>Manage</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <CreateFundModal disclosure={createModal} />
    </Container>
  );
}

import { Button, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { type UseQueryResult } from '@tanstack/react-query';
import ErrorAlert from '../../../../../partials/layout/ErrorAlert';
import Loading from '../../../../../partials/layout/Loading';
import { type IOrganisation } from '../../../../../types';
import { type APIItemResponse } from '../../../../../utils/apiClient';

interface PropsTypes {
  name: string
  query: UseQueryResult<APIItemResponse<IOrganisation[]>, Error>
  deleteFn: (organisation: IOrganisation) => void
}

export default function RelationshipsTable ({ query, deleteFn }: PropsTypes) {
  if (query.isLoading) return <Loading />;
  if (query.isError) return <ErrorAlert error={query.error} />;

  return (
    <Table variant="simple" mt="4">
      <Tbody>
        {query.data?.docs.map((organisation) => (
          <Tr key={organisation.id}>
            <Td pl="1">{organisation.name}</Td>
            <Td pr="1" textAlign="right">
              <Button size="xs" colorScheme='red' aria-label='delete' onClick={() => { deleteFn(organisation); }}>Del</Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

import { Button, ButtonGroup, Center, Flex, Spacer, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import CircleChart from '../../../../partials/output/CircleChart';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import { type IAnalyticsSegment } from '../../../../types';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

const ETHNIC_GROUPS: Array<{ code: string, label: string, itemKey: keyof IAnalyticsSegment }> = [
  {
    code: 'AI',
    label: 'Indian',
    itemKey: 'countEthnicGroupAi',
  },
  {
    code: 'AP',
    label: 'Pakistani',
    itemKey: 'countEthnicGroupAp',
  },
  {
    code: 'AB',
    label: 'Bangladeshi',
    itemKey: 'countEthnicGroupAb',
  },
  {
    code: 'AC',
    label: 'Chinese',
    itemKey: 'countEthnicGroupAc',
  },
  {
    code: 'AO',
    label: 'Any other Asian background',
    itemKey: 'countEthnicGroupAo',
  },
  {
    code: 'BC',
    label: 'Caribbean',
    itemKey: 'countEthnicGroupBc',
  },
  {
    code: 'BA',
    label: 'African',
    itemKey: 'countEthnicGroupBa',
  },
  {
    code: 'BO',
    label: 'Any other Black, Black British, or Caribbean background',
    itemKey: 'countEthnicGroupBo',
  },
  {
    code: 'MBC',
    label: 'White and Black Caribbean',
    itemKey: 'countEthnicGroupMbc',
  },
  {
    code: 'MBA',
    label: 'White and Black African',
    itemKey: 'countEthnicGroupMba',
  },
  {
    code: 'MWA',
    label: 'White and Asian',
    itemKey: 'countEthnicGroupMwa',
  },
  {
    code: 'MO',
    label: 'Any other Mixed or multiple ethnic background',
    itemKey: 'countEthnicGroupMo',
  },
  {
    code: 'WB',
    label: 'English, Welsh, Scottish, Northern Irish or British',
    itemKey: 'countEthnicGroupWb',
  },
  {
    code: 'WI',
    label: 'Irish',
    itemKey: 'countEthnicGroupWi',
  },
  {
    code: 'WG',
    label: 'Gypsy or Irish Traveller',
    itemKey: 'countEthnicGroupWg',
  },
  {
    code: 'WR',
    label: 'Roma',
    itemKey: 'countEthnicGroupWr',
  },
  {
    code: 'WO',
    label: 'Any other White background',
    itemKey: 'countEthnicGroupWo',
  },
  {
    code: 'OA',
    label: 'Arab',
    itemKey: 'countEthnicGroupOa',
  },
  {
    code: 'OO',
    label: 'Any other ethnic group',
    itemKey: 'countEthnicGroupOo',
  }
];

function EthnicGroupsTable ({ row, isVisible }: { row: AnalyticsSegmentRange, isVisible: boolean }) {
  const max = Math.max(...ETHNIC_GROUPS.map(group => row.d2[group.itemKey] as number));

  return (
    <Table variant='simple' size='sm' display={isVisible ? 'table' : 'none'}>
      <Thead>
        <Tr>
          <Th>Ethnic Group</Th>
          <Th>Service Users</Th>
        </Tr>
      </Thead>
      <Tbody>
        {ETHNIC_GROUPS.map((group) => (
          <Tr key={group.label}>
            <Td>{group.label}</Td>
            <TdMetricBar key={row.segmentId} item={row} itemKey={group.itemKey} max={max} barColor='red.400' />
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function EthnicGroupsCircleChart ({ row, isVisible }: Readonly<{ row: AnalyticsSegmentRange, isVisible: boolean }>) {
  const data = ETHNIC_GROUPS.map(group => ({
    key: group.code,
    label: group.label,
    value: row.d2[group.itemKey] as number
  }));

  return (
    <Center display={isVisible ? 'flex' : 'none'} height={{ base: 'auto', md: '500px' }} >
      <CircleChart data={data} />
    </Center>
  );
}

export default function EthnicGroupsChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const [showChart, setShowChart] = useState(true);

  return (
    <Stack spacing={4}>
      <Flex align='center'>
        <HeadingToolTip size='sm' label='Distribution of Service Users by ethnic groups'>Ethnic Groups</HeadingToolTip>
        <Spacer />
        <ButtonGroup isAttached variant='outline'>
          <Button size='xs' isDisabled={showChart} onClick={() => { setShowChart(true); }} >Chart</Button>
          <Button size='xs' isDisabled={!showChart} onClick={() => { setShowChart(false); }} >Table</Button>
        </ButtonGroup>
      </Flex>

      <EthnicGroupsTable row={row} isVisible={!showChart} />
      <EthnicGroupsCircleChart row={row} isVisible={showChart} />
    </Stack >
  );
}

import { Card, Center, Heading } from '@chakra-ui/react';
import CircleChart from '../../../../partials/output/CircleChart';
import { type IServiceUserOverview } from '../../../../types';

export default function EthnicGroupsWidget ({ data }: { data: IServiceUserOverview }) {
  const groupData = [
    {
      key: 'AI',
      label: 'Indian',
      value: data.countEthnicGroupAi,
    },
    {
      key: 'AP',
      label: 'Pakistani',
      value: data.countEthnicGroupAp,
    },
    {
      key: 'AB',
      label: 'Bangladeshi',
      value: data.countEthnicGroupAb,
    },
    {
      key: 'AC',
      label: 'Chinese',
      value: data.countEthnicGroupAc,
    },
    {
      key: 'AO',
      label: 'Any other Asian background',
      value: data.countEthnicGroupAo,
    },
    {
      key: 'BC',
      label: 'Caribbean',
      value: data.countEthnicGroupBc,
    },
    {
      key: 'BA',
      label: 'African',
      value: data.countEthnicGroupBa,
    },
    {
      key: 'BO',
      label: 'Any other Black, Black British, or Caribbean background',
      value: data.countEthnicGroupBo,
    },
    {
      key: 'MBC',
      label: 'White and Black Caribbean',
      value: data.countEthnicGroupMbc,
    },
    {
      key: 'MBA',
      label: 'White and Black African',
      value: data.countEthnicGroupMba,
    },
    {
      key: 'MWA',
      label: 'White and Asian',
      value: data.countEthnicGroupMwa,
    },
    {
      key: 'MO',
      label: 'Any other Mixed or multiple ethnic background',
      value: data.countEthnicGroupMo,
    },
    {
      key: 'WB',
      label: 'English, Welsh, Scottish, Northern Irish or British',
      value: data.countEthnicGroupWb,
    },
    {
      key: 'WI',
      label: 'Irish',
      value: data.countEthnicGroupWi,
    },
    {
      key: 'WG',
      label: 'Gypsy or Irish Traveller',
      value: data.countEthnicGroupWg,
    },
    {
      key: 'WR',
      label: 'Roma',
      value: data.countEthnicGroupWr,
    },
    {
      key: 'WO',
      label: 'Any other White background',
      value: data.countEthnicGroupWo,
    },
    {
      key: 'OA',
      label: 'Arab',
      value: data.countEthnicGroupOa,
    },
    {
      key: 'OO',
      label: 'Any other ethnic group',
      value: data.countEthnicGroupOo,
    }
  ];

  return (
    <Card p={4}>
      <Heading size='md'>Ethnic Groups</Heading>
      <Center mt={4} height={{ base: 'auto', md: '500px' }}>
        <CircleChart data={groupData} />
      </Center>
    </Card>
  );
}

import { Button, Center, Container, Flex, Heading, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useListServiceUsers } from '../../../hooks/useServiceUsers';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import { type IServiceUserExtended } from '../../../types';
import CreateServiceUserModal from './partials/CreateServiceUserModal';
import EditServiceUserModal from './partials/EditServiceUserModal';
import ServiceUsersTable from './partials/ServiceUsersTable';

export default function ManageServiceUsers () {
  const { data, isLoading, isError, error, refetch } = useListServiceUsers();
  const [selectedServiceUser, setSelectedServiceUser] = useState<IServiceUserExtended | null>(null);
  const createModal = useDisclosure();
  const editModal = useDisclosure();

  const openCreateModal = () => {
    createModal.onOpen();
  };

  const openEditModal = (serviceUser: IServiceUserExtended) => {
    setSelectedServiceUser(serviceUser);
    editModal.onOpen();
  };

  useEffect(() => {
    if (createModal.isOpen || editModal.isOpen) return;
    void refetch();
    setSelectedServiceUser(null);
  }, [createModal.isOpen, editModal.isOpen]);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const serviceUsers = data?.docs as IServiceUserExtended[];

  if (serviceUsers.length === 0) {
    return (
      <Container py="4" maxW="8xl">
        <Heading>Service Users</Heading>
        <Center as={Stack} mt={8} spacing={8}>
          <Text fontSize="lg" color="gray.500">Add a Service User to get started</Text>
          <Button colorScheme="blue" onClick={openCreateModal}>Add Service User</Button>
        </Center>

        <CreateServiceUserModal disclosure={createModal} />
      </Container>
    );
  }

  return (
    <Container py="4" maxW="8xl">
      <Flex mb="4">
        <Heading>Service Users</Heading>
        <Spacer />
        <Button colorScheme="blue" onClick={openCreateModal}>
          Add New
        </Button>
      </Flex>

      <ServiceUsersTable
        serviceUsers={serviceUsers}
        editServiceUser={openEditModal}
      />

      <CreateServiceUserModal disclosure={createModal} />
      {(selectedServiceUser != null) && <EditServiceUserModal disclosure={editModal} serviceUser={selectedServiceUser} />}

    </Container>
  );
}

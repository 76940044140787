import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { useListServiceProviders } from '../../hooks/useServiceProvider';
import { ReactSelectStyles } from '../../theme';

interface PropsType {
  name: string
  label?: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

/* istanbul ignore next */
const noOptionsMessage = () => 'There are no Service Providers on your account';

export default function ServiceProviderSelect ({ name, label, control, errors }: Readonly<PropsType>) {
  const { data, isLoading } = useListServiceProviders();
  const hasError = name in errors;
  const hasLabel = label !== undefined;

  const options = data?.docs.map((organisation) => ({
    value: organisation.id,
    label: organisation.name,
  }));

  return (
    <FormControl isInvalid={name in errors}>
      {hasLabel && <FormLabel id={`${name}-label`} htmlFor={name}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            aria-labelledby={`${name}-label`}
            placeholder={'Select a service provider'}
            options={options}
            onChange={(val) => { field.onChange(val?.value); }}
            value={options?.find((option) => option.value === field.value)}
            isLoading={isLoading}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
            noOptionsMessage={noOptionsMessage}
          />
        )}
      />
      {hasError && <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>}
    </FormControl>
  );
}

import { Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useUpdateProfile } from '../../../../hooks/useProfile';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import { type IUserExtended } from '../../../../types';

interface PropsTypes {
  profile: IUserExtended
  onSuccess?: () => void
  onError?: () => void
}

interface FieldTypes {
  id?: string
  name: string
}

const schema = Joi.object({
  id: Joi.string(),
  name: Joi.string().required(),
  organisationId: Joi.string().required(),
}).options({ stripUnknown: true });

export default function ProfileForm ({ profile, onSuccess, onError }: Readonly<PropsTypes>) {
  const update = useUpdateProfile();

  const { register, handleSubmit, formState: { errors } } = useForm<FieldTypes>({
    defaultValues: profile,
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit(async (data) => {
    update.mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={update.error} />

      <TextInput
        name="name"
        label="Name"
        placeholder="Indiana Jones"
        register={register}
        errors={errors}
      />

      <ButtonGroup spacing="4">
        <Button aria-label='submit' type="submit" colorScheme='blue'>{'Save'}</Button>
        <Button aria-label="cancel" as={Link} to="/" variant="outline">Cancel</Button>
      </ButtonGroup>
    </Stack>
  );
}

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { NEWS_FEED } from '../utils/config';

interface NewsFeed {
  version: string
  title: string
  language: string
  home_page_url: string
  feed_url: string
  description: string
  items: NewsFeedItem[]
}

interface NewsFeedItem {
  id: string
  url: string
  title: string
  date_published: Date
}

// Client for news feed requests
const newsClient = axios.create({
  timeout: 2500 // 2.5 seconds
});

export function useNewsFeed ({ items }: { items?: number }) {
  return useQuery<NewsFeed | null, Error>({
    queryKey: ['NewsFeed', items],
    queryFn: async () => {
      const response = await newsClient.get(NEWS_FEED);
      response.data.items = response.data.items.slice(0, items).map((item: NewsFeedItem) => {
        item.date_published = new Date(item.date_published);
        return item;
      });
      return response.data;
    },
  });
}

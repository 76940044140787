import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { APP_VERSION } from '../../utils/config';

export default function NotFoundError () {
  return (
    <Container maxWidth="lg" textAlign="center">
      <Heading mt={14} mb={7}>Page Not Found</Heading>
      <Box as="img" src="/error-robot.gif" alt="A cute cartoon robot looking lost and confused" sx={{ mb: 6 }}/>
      <Text fontSize="lg" mb={7}>Oh no! Something has gone wrong...</Text>
      <Text fontSize="lg" mb={7}>Go back and try again</Text>
      <Text fontSize="sm" color="gray" my={20}>&copy; 2023 CM Russell Ltd. v{APP_VERSION}</Text>
    </Container>
  );
}

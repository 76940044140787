import { Box, Card, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useListInvestments } from '../../../../hooks/useFundRecipient';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import Currency from '../../../../partials/output/Currency';
import { type IInvestmentExtended } from '../../../../types';
import { round } from '../../../../utils/utils';
import { getDateString } from '../../Analytics/utils';

export default function FundManagersWidget () {
  const { data, isLoading, error } = useListInvestments();

  if (isLoading) return <Loading />;
  if (error !== null) return <ErrorAlert error={error} />;

  const metrics = data?.docs as IInvestmentExtended[];

  const sumServiceUsers = metrics.reduce((acc: number, row) => acc + row.countServiceUsers, 0) ?? 0;
  const barWidth = (value: number) => (value / sumServiceUsers) * 100;
  const percUsers = (value: number) => round((value / sumServiceUsers) * 100, 2);

  return (
    <Card p={4}>
      <Heading as='h2' size='md' mb={6}>My Investments</Heading>
      <Table size='sm' variant='simple'>
        <Thead>
          <Tr>
            <Th>Fund Manager</Th>
            <Th>Fund Name</Th>
            <Th>Investment Amount</Th>
            <Th>Investment Date</Th>
            <Th colSpan={3}>Service Users (Dependents)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {metrics.map(row => (
            <Tr key={row.id}>
              <Td>{row.fundManagerName}</Td>
              <Td>{row.fundName}</Td>
              <Td><Currency value={row.value} /></Td>
              <Td>{getDateString(row.drawdownDate)}</Td>
              <Td isNumeric>{row.countServiceUsers} ({row.countDependents})</Td>
              <Td width='200px' p={0}>
                <Box height='20px' w={`${barWidth(row.countServiceUsersAndDependents)}%`} backgroundColor='green.500' />
              </Td>
              <Td isNumeric>{percUsers(row.countServiceUsersAndDependents)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Card>
  );
}

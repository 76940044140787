import { useQuery } from '@tanstack/react-query';
import { type IAnalyticsSegment, type SegmentType } from '../types';
import { apiClient } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useSegment (type: SegmentType, date: string) {
  return useQuery({
    queryKey: ['useSegment', type, date],
    queryFn: async () => {
      const url = `${API_URL.segment.data}?type=${type}&date=${date}`;
      const { data } = await apiClient.get<{ docs: IAnalyticsSegment[], rowCount: number }>(url);
      return data;
    },
  });
}

import { Button, Card, Container, Flex, Heading, SimpleGrid, Spacer, Stat, StatHelpText, StatLabel, StatNumber, useDisclosure } from '@chakra-ui/react';
import { useListServiceProviders } from '../../../hooks/useServiceProvider';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import { type IOrganisationChild } from '../../../types';
import RequestServiceProviderModal from './partials/RequestServiceProviderModal';
import ServiceProviderTable from './partials/ServiceProviderTable';

// Service Providers are child Organisations that may/may not have received funding

export default function ServiceProvidersList () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isError, error } = useListServiceProviders();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const organisations = data?.docs as IOrganisationChild[];

  // Sum totals
  const total = {
    countInvestments: organisations.reduce((acc, organisation) => acc + organisation.countInvestments, 0),
    sumInvestmentsValue: organisations.reduce((acc, organisation) => acc + organisation.sumInvestmentsValue, 0),
    countServiceUsers: organisations.reduce((acc, organisation) => acc + organisation.countServiceUsers, 0),
    countUsers: organisations.reduce((acc, organisation) => acc + organisation.countUsers, 0),
    countServiceProviders: organisations.length,
  };

  return (
    <Container py="4" maxW="6xl">
      <Flex>
        <Heading mb="4">Service Providers</Heading>
        <Spacer />
        <Button onClick={onOpen} colorScheme="blue" mb="4">Add New</Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" py="4">
        <Stat as={Card} p="4">
          <StatLabel>Total Invested</StatLabel>
          <StatNumber><Currency value={total.sumInvestmentsValue} /></StatNumber>
          <StatHelpText>Through {total.countInvestments} Investments</StatHelpText>
        </Stat>
        <Stat as={Card} p="4">
          <StatLabel>Supported Service Users</StatLabel>
          <StatNumber>{total.countServiceUsers}</StatNumber>
          <StatHelpText>Through {total.countServiceProviders} Service Providers</StatHelpText>
        </Stat>
      </SimpleGrid>

      <ServiceProviderTable organisations={organisations} />
      <RequestServiceProviderModal isOpen={isOpen} onClose={onClose} />
    </Container>
  );
}

import { Box, Button, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { endSession } from '../hooks/useSession';
import useTnCs from '../hooks/useTnCs';
import CurrentUser from '../pages/app/Dashboard/partials/CurrentUser';
import ErrorAlert from './layout/ErrorAlert';

export const TNCS_URL = 'https://impactgo.uk/downloads/ImpactGo - Service Terms and Conditions v1.0 DRAFT.pdf';
export const TNCS_CHECKSUM = '81281d30e3c29c59b44462b08cbf1a4b'; // $ md5 file
export const TNCS_VERSION = 1; // Increment with each new version

export function AcceptTnCsModal () {
  const { mutate, isPending, isError, error } = useTnCs();

  return (
    <Modal isOpen={true} onClose={() => { endSession(); }} size='4xl'>
      <ModalOverlay bg='gray.100' />

      <ModalContent as="form" onSubmit={(e) => { e.preventDefault(); mutate(); }}>
        <ModalCloseButton />
        <ModalBody>
          <CurrentUser />
          <Stack spacing={2} color='gray.800'>
            {isError && (<ErrorAlert error={error} />)}
            <Text>Thank you for choosing ImpactGo!</Text>
            <Text>Continuing to use this service requires you to accept our Terms & Conditions.</Text>
          </Stack>
          <Box mt={4} sx={{ border: '1px solid #c9c9c9', padding: '3px', borderRadius: '3px' }}>
            <iframe title='Terms and Conditions' src={TNCS_URL} width="100%" height="500" loading='lazy' ></iframe>
          </Box>
          <Stack mt={4} spacing={2} color='gray.500'>
            <Text> If the above document fails to load, you can <Link href={TNCS_URL} isExternal sx={{ textDecoration: 'underline' }}>download our Terms & Conditions here</Link>. </Text>
            <Text>If you have any questions, please contact <Link href="mailto:support@impactgo.uk" isExternal sx={{ textDecoration: 'underline' }}>support@impactgo.uk</Link>.</Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button aria-label='submit' type='submit' colorScheme='blue' mr={4} tabIndex={1} disabled={isPending}>Continue</Button>
          <Button aria-label="cancel" onClick={() => { endSession(); }} variant="outline">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );
}

import { Tooltip } from '@chakra-ui/react';

export const dtDefaultFormat = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

export default function DateTime ({ date, dtFormat }: Readonly<{ date: Date | null, dtFormat?: Intl.DateTimeFormat }>) {
  const label = (date === null) ? '' : date.toISOString();
  const text = (date === null) ? 'Never' : (dtFormat ?? dtDefaultFormat).format(date);

  return (<Tooltip label={label}>{text}</Tooltip>);
}

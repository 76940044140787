import { Tag } from '@chakra-ui/react';
import { type UserState } from '../../types';

export default function UserStateTag ({ state }: Readonly<{ state: UserState }>) {
  if (state === 'INVITED') return (<Tag colorScheme='blue'>Invited</Tag>);
  if (state === 'ACTIVE') return (<Tag colorScheme='green'>Active</Tag>);
  if (state === 'DEACTIVATED') return (<Tag colorScheme='red'>Deactivated</Tag>);
  if (state === 'DELETED') return (<Tag colorScheme='gray'>Deleted</Tag>);
  return null;
}

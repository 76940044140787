import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type CreateUser, type IUser, type IUserExtended, type UpdateUser, type UserSearchQuery } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListUsers (searchText: string = '') {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListUsers', page],
    queryFn: async () => {
      const url = API_URL.user.list;
      const options: UserSearchQuery = { params: { searchText, page, pageSize: 10 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IUserExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useCreateUser () {
  return useMutation({
    mutationFn: async (user: CreateUser) => {
      const url = API_URL.user.create;
      const { data } = await apiClient.post<APIItemResponse<IUser>>(url, user);
      return data;
    },
  });
}

export function useUpdateUser () {
  return useMutation({
    mutationFn: async (user: UpdateUser) => {
      const url = API_URL.user.update(user.id);
      const { data } = await apiClient.post<APIItemResponse<IUserExtended>>(url, user);
      return data;
    },
  });
}

export function useGetUser (id: string) {
  const queryClient = useQueryClient();
  const queryKey = ['useGetUser', id];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.user.get(id);
      const { data } = await apiClient.get<APIItemResponse<IUserExtended>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

export function useDeleteUser () {
  return useMutation({
    mutationFn: async (id: string) => {
      const url = API_URL.user.delete(id);
      const { data } = await apiClient.delete(url);
      return data;
    },
  });
}

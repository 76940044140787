import { Button, Container, Flex, Heading, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import OrganisationsTable from './partials/OrganisationsTable';

export default function OrganisationsList () {
  return (
    <Container py="4" maxW="8xl">
      <Flex>
        <Heading mb="4">Organisations</Heading>
        <Spacer/>
        <Button as={Link} to="/root/organisations/create" colorScheme="blue" mb="4">New Organisation</Button>
      </Flex>
      <OrganisationsTable/>
    </Container>
  );
}

import { Container, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSuccessToast } from '../../../hooks/useToast';
import OrganisationCreateForm from './partials/OrganisationCreateForm';

export default function OrganisationCreate () {
  const success = useSuccessToast();
  const navigate = useNavigate();

  const onSuccess = () => {
    success('Organisation created');
    navigate('/root/organisations');
  };

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">New Organisation</Heading>
      <OrganisationCreateForm onSuccess={onSuccess} />
    </Container>
  );
}

import { Button, FormLabel, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useCreateRelationship } from '../../../../../hooks/useRelationships';
import OrganisationSelect from '../../../../../partials/input/OrganisationSelect';
import ErrorAlert from '../../../../../partials/layout/ErrorAlert';

interface PropsTypes {
  parentId?: string
  childId?: string
  onSuccess?: () => void
  onError?: () => void
}

interface FieldTypes {
  parentId: string
  childId: string
}

const schema = Joi.object({
  parentId: Joi.string().required(),
  childId: Joi.string().required(),
});

export default function RelationshipForm ({ parentId, childId, onSuccess, onError }: Readonly<PropsTypes>) {
  const { mutate, error } = useCreateRelationship();
  const { handleSubmit, formState: { errors }, control } = useForm<FieldTypes>({
    defaultValues: { parentId, childId },
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit(async (request) => {
    mutate(request, { onSuccess, onError });
  });

  // Set either Parent or Child version of form
  const showParents = childId !== undefined && parentId === undefined;
  const name = showParents ? 'parentId' : 'childId';
  const label = showParents ? 'Parent Organisations' : 'Child Organisations';
  const buttonAria = showParents ? 'add-parent' : 'add-child';

  return (
    <form onSubmit={onSubmit}>
      <ErrorAlert error={error} />
      <FormLabel id={`${name}-label`} htmlFor={name}>{label}</FormLabel>
      <Stack direction="row" spacing={2} >
        <OrganisationSelect name={name} control={control} errors={errors} />
        <Button type="submit" aria-label={buttonAria} colorScheme='blue'>Add</Button>
      </Stack>
    </form>
  );
}

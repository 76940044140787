import { Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useCreateUser } from '../../../../hooks/useUsers';
import DangerZone from '../../../../partials/input/DangerZone';
import OrganisationSelect from '../../../../partials/input/OrganisationSelect';
import { SwitchInput } from '../../../../partials/input/SwitchInput';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import { type IUserExtended } from '../../../../types';

interface PropsTypes {
  user?: IUserExtended
  onSuccess?: () => void
  onError?: () => void
}

interface FormFields {
  organisationId: string
  name: string
  login: string
  isEnabled: boolean
  isRoot: boolean
}

const schema = Joi.object({
  organisationId: Joi.string().required(),
  name: Joi.string().required(),
  login: Joi.string().required().email({ tlds: { allow: false } }),
  isRoot: Joi.boolean(),
}).options({ stripUnknown: true });

export default function UserCreateForm ({ onSuccess, onError }: Readonly<PropsTypes>) {
  const create = useCreateUser();

  const { register, handleSubmit, formState: { errors }, control } = useForm<FormFields>({
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit(async (data) => {
    create.mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={create.error} />

      <TextInput
        name="name"
        label="Name"
        placeholder="Indiana Jones"
        register={register}
        errors={errors}
      />

      <TextInput
        name="login"
        type="email"
        label="Login"
        placeholder="indiana.jones@temple-of-doom.com"
        register={register}
        errors={errors}
      />

      <OrganisationSelect
        name="organisationId"
        label="Organisation"
        control={control}
        errors={errors}
        helperText='WARNING This cannot be changed after the user is created!'
      />

      <DangerZone>
        <SwitchInput name='isRoot' label="Root User" register={register} />
      </DangerZone>

      <ButtonGroup spacing="4">
        <Button aria-label='submit' type="submit" colorScheme='blue'>{'Create'}</Button>
        <Button aria-label="cancel" as={Link} to="/root/users" variant="outline">Cancel</Button>
      </ButtonGroup>
    </Stack>
  );
}

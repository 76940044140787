import { useToast } from '@chakra-ui/react';

export function useSuccessToast () {
  const toast = useToast();

  const doToast = (title: string, description?: string) => toast({
    title,
    description,
    position: 'top',
    status: 'success',
    duration: 5000,
    isClosable: true,
  });

  return doToast;
}

export function useErrorToast () {
  const toast = useToast();

  const doToast = (title: string, description?: string) => toast({
    title,
    description,
    position: 'top',
    status: 'error',
    duration: 5000,
    isClosable: true,
  });

  return doToast;
}

export function useWarningToast () {
  const toast = useToast();

  const doToast = (title: string, description?: string) => toast({
    title,
    description,
    position: 'top',
    status: 'warning',
    duration: 5000,
    isClosable: true,
  });

  return doToast;
}

import { Container, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSuccessToast } from '../../../hooks/useToast';
import UserCreateForm from './partials/UserCreateForm';

export default function UserCreate () {
  const success = useSuccessToast();
  const navigate = useNavigate();

  const onSuccess = () => {
    success('User created');
    navigate('/root/users');
  };

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">New User</Heading>
      <UserCreateForm onSuccess={onSuccess} />
    </Container>
  );
}

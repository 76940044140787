import { FormControl, FormErrorMessage, FormLabel, type SelectProps } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type Control, Controller, type FieldErrors } from 'react-hook-form';
import { ReactSelectStyles } from '../../theme';
import { GenderCode } from '../../types/ServiceUser';

interface PropsType extends SelectProps {
  name: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

export default function GenderSelect ({ name, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;

  const options = [
    { value: GenderCode.Male, label: 'Male' },
    { value: GenderCode.Female, label: 'Female' },
    { value: GenderCode.NotSay, label: 'Prefers Not to Say' },
    { value: GenderCode.Other, label: 'Other/Not Known' },
  ];

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={name}>Gender</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            isClearable={true}
            isSearchable={true}
            placeholder="Select Gender..."
            aria-labelledby={`${name}-label`}
            options={options}
            onChange={(val) => { field.onChange(val?.value ?? null); }}
            value={options?.find((option) => option.value === field.value)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        )}
      />
      { hasError && <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage> }
    </FormControl>
  );
}


import { FormControl, FormErrorMessage, FormLabel, type SelectProps } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type Control, Controller, type FieldErrors } from 'react-hook-form';
import { ReactSelectStyles } from '../../theme';
import { localAuthorityCodes } from '../../utils/localAuthorities';

interface PropsType extends SelectProps {
  name: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

export default function LocalAuthoritySelect ({ name, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;

  const options = localAuthorityCodes.map((la) => ({ value: la.code, label: la.EN }));

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={name}>Local Authority</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            aria-labelledby={`${name}-label`}
            inputId={name}
            isClearable={true}
            isSearchable={true}
            name={name}
            onChange={(val) => { field.onChange(val?.value ?? null); }}
            options={options}
            placeholder="Select Local Authority..."
            value={options?.find((option) => option.value === field.value)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        )}
      />
      { hasError && <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage> }
    </FormControl>
  );
}

import { Button, Stack, Text } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useForgottenPassword } from '../../../hooks/useAuthentication';
import { TextInput } from '../../../partials/input/TextInput';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import { type ForgottenPasswordRequest } from '../../../types';

interface PropsTypes {
  onSuccess?: (data: unknown, variables: ForgottenPasswordRequest, context: unknown) => void
  onError?: (error: Error, variables: ForgottenPasswordRequest, context: unknown) => void
}

interface FieldTypes {
  login: string
}

const schema = Joi.object({
  login: Joi.string().required(),
});

export default function ForgotPasswordForm ({ onSuccess, onError }: Readonly<PropsTypes>) {
  const { mutate, error } = useForgottenPassword();
  const { register, handleSubmit, formState: { errors } } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit((data) => {
    mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={error} />
      <Text>
        if you have forgotten your password, please enter your email address below and we will send you a link to reset your password.
      </Text>

      <TextInput
        name="login"
        type="email"
        placeholder="Login"
        register={register}
        errors={errors}
      />

      <Button type="submit" colorScheme='blue'>Send Reset Email</Button>
    </Stack>
  );
}

import { Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useUpdateUser } from '../../../../hooks/useUsers';
import DangerZone from '../../../../partials/input/DangerZone';
import { SwitchInput } from '../../../../partials/input/SwitchInput';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import { type IUserExtended } from '../../../../types';

interface PropsTypes {
  user: IUserExtended
  onSuccess?: () => void
  onError?: () => void
}

interface FormFields {
  id: string
  name: string
  login: string
  isRoot: boolean
  isEnabled: boolean
}

const schema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
  login: Joi.string().required().email({ tlds: { allow: false } }),
  isEnabled: Joi.boolean(),
  isRoot: Joi.boolean(),
}).options({ stripUnknown: true });

export default function UserUpdateForm ({ user, onSuccess, onError }: Readonly<PropsTypes>) {
  const update = useUpdateUser();

  const { register, handleSubmit, formState: { errors } } = useForm<FormFields>({
    defaultValues: user,
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit(async (data) => {
    update.mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={update.error} />

      <TextInput
        name="name"
        label="Name"
        placeholder="Indiana Jones"
        register={register}
        errors={errors}
      />

      <TextInput
        name="login"
        type="email"
        label="Login"
        placeholder="indiana.jones@temple-of-doom.com"
        register={register}
        errors={errors}
      />

      <DangerZone>
        <SwitchInput name='isEnabled' label="Enabled" register={register} />
        <SwitchInput name='isRoot' label="Root User" register={register} />
      </DangerZone>

      <ButtonGroup spacing="4">
        <Button aria-label='submit' type="submit" colorScheme='blue'>{'Save'}</Button>
        <Button aria-label="cancel" as={Link} to="/root/users" variant="outline">Cancel</Button>
      </ButtonGroup>
    </Stack>
  );
}

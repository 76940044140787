import { traverse } from './traverse';

/**
 * Parse response data
 * @param data
 * @returns
 */
export default function parseResponse (data: any): any {
  return traverse(data, (_key, value) => {
    // Parse timestamps into Date objects
    if ((/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/).test(value)) return new Date(value);

    return value;
  });
}

import { Stack, Tag, Tooltip } from '@chakra-ui/react';
import { getVulnerabilityGroupByCode } from '../../utils/vulnerabilityGroups';

function VulnerabilityGroupTag ({ code }: Readonly<{ code: string }>) {
  const group = getVulnerabilityGroupByCode(code);
  if (group === undefined) return null;

  return (
    <Tooltip hasArrow label={group.label}>
      <Tag key={code} p="1" colorScheme='purple'>{group.shortLabel}</Tag>
    </Tooltip>
  );
}

export default function VulnerabilityGroupTags ({ codes }: Readonly<{ codes: string[] | null }>) {
  if (codes === null) return null;
  return (
    <Stack>
      {codes.map((code) => (<VulnerabilityGroupTag key={code} code={code}/>))}
    </Stack>
  );
}

import { Button, Center, Container, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';
import DashboardForFundManager from './DashboardForFundManager';
import DashboardForFundRecipient from './DashboardForFundRecipient';
import CurrentUser from './partials/CurrentUser';

function DashboardContent () {
  const { isFundRecipient, isFundManager } = usePermissions();

  if (isFundManager) return <DashboardForFundManager />;
  if (isFundRecipient) return <DashboardForFundRecipient />;

  return (
    <Center as={Stack} mt={8} spacing={4}>
      <Text fontSize="lg" color="gray.500">
        Your account has not been granted access to any services, please contact support.
      </Text>
      <Button as={Link} to='/support' colorScheme='blue' mt='4'>Contact Support</Button>
    </Center>
  );
}

export default function Dashboard () {
  return (
    <Container py="4" mb="8" maxW="8xl">
      <Stack spacing={4}>
        <CurrentUser />
        <DashboardContent />
      </Stack>
    </Container>
  );
}

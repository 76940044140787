import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type FieldErrors, Controller, type Control } from 'react-hook-form';
import { useListOrganisations } from '../../hooks/useOrganisation';
import { ReactSelectStyles } from '../../theme';

interface PropsType {
  name: string
  label?: string
  placeholder?: string
  helperText?: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

export default function OrganisationSelect ({ name, label, placeholder, helperText, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;
  const hasLabel = label !== undefined;
  const hasHelperText = helperText !== undefined;

  // FUTURE Fix for more than `pageSize` organisations
  const { data, isLoading } = useListOrganisations({ pageSize: 500 });

  const options = data?.docs.map((organisation) => ({ value: organisation.id, label: organisation.name }));

  return (
    <FormControl isInvalid={hasError}>
      {hasLabel && <FormLabel id={`${name}-label`} htmlFor={name}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            aria-labelledby={`${name}-label`}
            placeholder={placeholder ?? 'Select an organisation'}
            options={options}
            onChange={(val) => { field.onChange(val?.value); }}
            value={options?.find((option) => option.value === field.value)}
            isLoading={isLoading}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        )}
      />
      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : hasHelperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

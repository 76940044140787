import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import AgeRangeTag from '../../../../partials/output/AgeRangeTag';
import Currency from '../../../../partials/output/Currency';
import ServiceUserStateTag from '../../../../partials/output/ServiceUserStateTag';
import { VulnerabilityGroupLabel } from '../../../../partials/output/VulnerabilityGroupCodeLabel';
import { SegmentType, type IAnalyticsSegment } from '../../../../types';
import { getDateString, getGenderLabel, getSegmentLabel, type AnalyticsSegmentRange } from '../utils';

function TdDescription ({ item }: Readonly<{ item: IAnalyticsSegment }>) {
  switch (item.segmentType) {
    case SegmentType.FUND_RECIPIENT:
      return <Td>{item.fundRecipientName}</Td>;
    case SegmentType.FUND:
      return <Td>{item.fundName}</Td>;
    case SegmentType.INVESTMENT:
      return <Td>{item.fundRecipientName} (<Currency value={item.investmentValue} /> - {getDateString(item.investmentDrawdownDate)})</Td>;
    case SegmentType.STATE:
      return <Td><ServiceUserStateTag state={item.segmentId} /></Td>;
    case SegmentType.ETHNIC_GROUP:
      return <Td>{item.ethnicGroupDescription}</Td>;
    case SegmentType.AGE_RANGE:
      return <Td><AgeRangeTag ageRange={item.segmentId as any} /></Td>;
    case SegmentType.GENDER:
      return <Td>{getGenderLabel(item.segmentId as any)}</Td>;
    case SegmentType.VULNERABILITY_GROUP:
      return <Td><VulnerabilityGroupLabel code={item.segmentId} /></Td>;
    default: return <Td>{item.segmentId}</Td>;
  }
}

export function DifferenceMetric ({ diff }: Readonly<{ diff: number }>) {
  if (diff > 0) return <HStack w='3rem' color='green'><TriangleUpIcon /> <Text>{diff}</Text></HStack>;
  if (diff < 0) return <HStack w='3rem' color='red'><TriangleDownIcon /> <Text>{diff * -1}</Text></HStack>;
  return <HStack w='3rem' color='orange'><TriangleUpIcon transform='rotate(-90deg)' /> <Text>{diff}</Text></HStack>;
}

function TdMetric ({ item, itemKey }: Readonly<{ item: any, itemKey: keyof IAnalyticsSegment }>) {
  const m1 = item.d1?.[itemKey];
  const m2 = item.d2?.[itemKey];

  return (
    <Td>
      <HStack spacing={1}>
        <Text width='2rem' textAlign='left'>{m2}</Text>
        <DifferenceMetric diff={m2 - m1} />
      </HStack>
    </Td>
  );
}

export function TdMetricBar ({ item, itemKey, max, barColor }: Readonly<{ item: any, itemKey: keyof IAnalyticsSegment, max: number, barColor?: string }>) {
  const m1 = item.d1?.[itemKey];
  const m2 = item.d2?.[itemKey];
  const barWidth = (max === 0) ? 0 : m2 / max * 100;

  return (
    <Td>
      <HStack spacing={1}>
        <Text width='2rem' textAlign='left'>{m2}</Text>
        <DifferenceMetric diff={m2 - m1} />
        <Box width='6rem'>
          <Tooltip label={m2} placement='right' hasArrow>
            <Box
              height='20px'
              w={`${barWidth}%`}
              backgroundColor={barColor ?? 'teal.500'}
            />
          </Tooltip>
        </Box>
      </HStack>
    </Td >
  );
}

interface OverviewTableProps {
  segmentType: SegmentType
  data: AnalyticsSegmentRange[]
  selectedRow?: AnalyticsSegmentRange
  setSelectedRow: (row: AnalyticsSegmentRange) => void
}

export function OverviewTable ({ segmentType, data, selectedRow, setSelectedRow }: Readonly<OverviewTableProps>) {
  const segmentLabel = getSegmentLabel(segmentType);
  const maxServiceUsers = Math.max(...data.map(item => item.d2.countServiceUsers));
  const maxDependents = Math.max(...data.map(item => item.d2.countDependents));

  return (
    <Tooltip label='Scroll for more' isDisabled={data.length < 6} placement='bottom'>
      <TableContainer maxHeight='16rem' overflowY='scroll'>
        <Table variant='simple' size='sm' aria-label="OverviewTable">
          <Thead position='sticky' top={0} bgColor='white' zIndex={2}>
            <Tr>
              <Th>{segmentLabel}</Th>
              <Td>
                <Tooltip hasArrow label='Count of Service Users, with change in this period'>Service Users</Tooltip>
              </Td>
              <Td>
                <Tooltip hasArrow label='Count of dependents of Service Users, with change in this period'>Dependents of Service Users</Tooltip>
              </Td>
              <Td>
                {segmentType === SegmentType.INVESTMENT
                  ? (<Tooltip hasArrow label='Drawdown date of Investment'>Drawdown Date</Tooltip>)
                  : (<Tooltip hasArrow label='Count of Investments, with change in this period'>Investments</Tooltip>)}
              </Td>
              <Td>
                <Tooltip hasArrow label='Total amount invested'>Total Invested</Tooltip>
              </Td>
              <Td>
                <Tooltip hasArrow label='Average amount invested per service user (total invested / Service Users)'>
                  Av. per Service User
                </Tooltip>
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => (
              <Tr
                key={item.segmentId}
                bgColor={selectedRow?.segmentId === item.segmentId ? 'orange.100' : 'transparent'}
                onClick={() => { setSelectedRow(item); }}
                onKeyDown={(e) => { if (e.key === 'Enter') setSelectedRow(item); }}
                cursor='pointer'
                _hover={{ bgColor: 'orange.50' }}
                tabIndex={0}
              >
                <TdDescription item={item.d1} />
                <TdMetricBar item={item} itemKey='countServiceUsers' max={maxServiceUsers} barColor='cyan.500' />
                <TdMetricBar item={item} itemKey='countDependents' max={maxDependents} barColor='teal.500' />
                {item.d2.segmentType === SegmentType.INVESTMENT
                  ? <Td>{getDateString(item.d2.investmentDrawdownDate)}</Td>
                  : <TdMetric item={item} itemKey='countInvestments' />}
                <Td><Currency value={item.d2.sumInvestmentValue} /></Td>
                <Td><Currency value={item.d2.avInvestmentValuePerServiceUser} /></Td>
              </Tr>
            ))}
          </Tbody>
        </Table >
      </TableContainer >
    </Tooltip >
  );
}

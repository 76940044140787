import { Avatar, Box, Flex, Heading, Link, Spacer, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { type IUserActivity } from '../../types';
import Currency from './Currency';
import DateTime from './DateTime';

const dtDate = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
});

const dtFull = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

function friendly (action: string) {
  if (action === 'CREATE') return 'Created';
  if (action === 'UPDATE') return 'Updated';
  if (action === 'DELETE') return 'Deleted';
  return action;
}

function ActivityMessage ({ activity }: Readonly<{ activity: IUserActivity }>) {
  const action = friendly(activity.action);

  if (activity.entityType === 'SERVICE_USER') {
    const noun = (activity.actionCount === 1) ? 'Service User' : 'Service Users';
    return (<Text> {action} {activity.actionCount} {noun}</Text>);
  }

  if (activity.entityType === 'FUND') {
    const url = `/portfolio/funds/${activity.fundId as string}`;
    return (
      <Text>
        {action} <Link as={RouterLink} to={url} fontWeight='bold'>{activity.fundName}</Link>
      </Text>
    );
  }

  if (activity.entityType === 'INVESTMENT') {
    const recipientUrl = `/portfolio/service-providers/${activity.fundRecipientId as string}`;
    const fundUrl = `/portfolio/funds/${activity.fundId as string}`;
    return (
      <Text>
        {action} Investment for <Currency value={activity.investmentValue} />{' '}
        to <Link as={RouterLink} to={recipientUrl} fontWeight='bold'>{activity.fundRecipientName}</Link>{' '}
        from <Link as={RouterLink} to={fundUrl} fontWeight='bold'>{activity.fundName}</Link>
      </Text>
    );
  }

  return <Text> {activity.action} {activity.entityType}</Text>;
}

export default function Activity ({ activity }: Readonly<{ activity: IUserActivity }>) {
  const datetime = (activity.entityType === 'SERVICE_USER')
    ? (<DateTime date={activity.createdAt} dtFormat={dtDate} />)
    : (<DateTime date={activity.createdAt} dtFormat={dtFull} />);

  return (
    <Stack spacing={2}>
      <Flex flex='1' gap='4' flexWrap='wrap'>
        <Avatar size='sm' mt={1.5} name={activity.userName} />
        <Box>
          <Heading size='sm'>{activity.userName}</Heading>
          <Text color='gray.600'>{activity.organisationName}</Text>
        </Box>
        <Spacer />
        <Text fontSize='xs' color='gray.500'>{datetime}</Text>
      </Flex>

      <Box ml={12}>
        <ActivityMessage activity={activity} />
      </Box>
    </Stack>
  );
}

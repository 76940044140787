import { Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useUpdateOrganisation } from '../../../../hooks/useOrganisation';
import DangerZone from '../../../../partials/input/DangerZone';
import { SwitchInput } from '../../../../partials/input/SwitchInput';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import { type IOrganisationExtended } from '../../../../types';

interface PropsTypes {
  organisation?: IOrganisationExtended
  onSuccess?: () => void
  onError?: () => void
}

interface FieldTypes {
  id: string
  name: string
  isFundManager: boolean
  isFundRecipient: boolean
  isEnabled: boolean
}

const schema = Joi.object({
  id: Joi.string(),
  name: Joi.string().required(),
  isEnabled: Joi.boolean(),
  isFundManager: Joi.boolean(),
  isFundRecipient: Joi.boolean(),
}).options({ stripUnknown: true });

export default function OrganisationUpdateForm ({ organisation, onSuccess, onError }: Readonly<PropsTypes>) {
  const update = useUpdateOrganisation();

  const { register, handleSubmit, formState: { errors } } = useForm<FieldTypes>({
    defaultValues: organisation,
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit(async (data) => {
    update.mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={update.error} />

      <TextInput
        name="name"
        type="name"
        label="Name"
        placeholder="ACME Ltd"
        register={register}
        errors={errors}
      />

      <DangerZone>
        <SwitchInput name='isEnabled' label="Enabled" register={register} />
        <SwitchInput name='isFundManager' label="Fund Manager" register={register} />
        <SwitchInput name='isFundRecipient' label="Fund Recipient" register={register} />
      </DangerZone>

      <ButtonGroup spacing="4">
        <Button aria-label='submit' type="submit" colorScheme='blue'>Save</Button>
        <Button aria-label="cancel" as={Link} to="/root/organisations" variant="outline">Cancel</Button>
      </ButtonGroup>
    </Stack>
  );
}

import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { type CreateInvestment, type DeleteInvestment, type IFund, type IFundExtended, type IInvestmentExtended } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListInvestments (fund: IFund | IFundExtended) {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListInvestmentsForFund', fund.id, page],
    queryFn: async () => {
      const url = API_URL.investment.list(fund.id);
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } };
      const { data } = await apiClient.get<APIPaginatedResponse<IInvestmentExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useCreateInvestment (fundId: string) {
  return useMutation({
    mutationFn: async (investment: CreateInvestment) => {
      const url = API_URL.investment.create(fundId);
      const { data } = await apiClient.post<APIItemResponse<IInvestmentExtended>>(url, investment);
      return data;
    },
  });
}

export function useDeleteInvestment () {
  return useMutation({
    mutationFn: async (investment: DeleteInvestment) => {
      const url = API_URL.investment.delete(investment.fundId, investment.id);
      const { data } = await apiClient.delete(url);
      return data;
    },
  });
}

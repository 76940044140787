import { Progress } from '@chakra-ui/react';

export default function PasswordProgress ({ password = '' }: { password: string }) {
  // Ref: https://www.pleacher.com/mp/mlessons/algebra/entropy.html

  // 26 lowercase, 26 uppercase, 10 digits, 9 special characters
  const possibleCharacters = 26 + 26 + 10 + 9;
  const entropy = Math.log2(Math.pow(possibleCharacters, password.length));

  const entropyColor = (entropy: number) => {
    if (entropy < 28) return 'red';
    if (entropy < 36) return 'orange';
    if (entropy < 60) return 'yellow';
    return 'green';
  };

  return <Progress value={entropy} colorScheme={entropyColor(entropy)} />;
}

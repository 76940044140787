import { useEffect, useState } from 'react';
import { type AuthResponse } from '../types';
import { SESSION_KEY } from '../utils/config';
import parseResponse from '../utils/parseResponse';

export function loadSession (): AuthResponse | null {
  return parseResponse(JSON.parse(localStorage.getItem(SESSION_KEY) ?? 'null'));
}

export function saveSession (session: AuthResponse | null) {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function clearSession () {
  localStorage.removeItem(SESSION_KEY);
}

export function endSession () {
  clearSession();
  window.location.assign('/');
}

export default function useSession () {
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<AuthResponse | null>(loadSession());

  useEffect(() => {
    saveSession(session);
    setLoading(false);
  }, [session]);

  return {
    loading,
    session,
    setSession,
    startSession: setSession,
    clearSession,
    endSession
  };
}

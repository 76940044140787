import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function RequestServiceProviderModal ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request New Service Provider</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <Text>Please contact support to request a new service provider.</Text>
          <Button as={Link} to='/support' colorScheme='blue' mt='4'>Contact Support</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import { Button, ButtonGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useCreateFund } from '../../../../hooks/useFunds';
import { useSuccessToast } from '../../../../hooks/useToast';
import { CurrencyInput } from '../../../../partials/input/CurrencyInput';
import { TextInput } from '../../../../partials/input/TextInput';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';

interface FieldTypes {
  id?: string
  name: string
  value: number
}

interface PropsType {
  disclosure: {
    isOpen: boolean
    onClose: () => void
  }
}

const schema = Joi.object({
  id: Joi.string(),
  name: Joi.string().required(),
  value: Joi.number().required(),
}).options({ stripUnknown: true });

export default function CreateFundModal ({ disclosure }: Readonly<PropsType>) {
  const { mutate, error, reset: apiReset } = useCreateFund();
  const success = useSuccessToast();

  const { register, handleSubmit, reset: formReset, formState: { errors } } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const closeModal = () => {
    apiReset();
    formReset();
    disclosure.onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    mutate(data, {
      onSuccess: () => {
        success('Fund created');
        closeModal();
      },
    });
  });

  return (
    <Modal isOpen={disclosure.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Fund</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <Stack as="form" spacing={6} onSubmit={onSubmit}>
            <ErrorAlert error={error} />

            <TextInput
              name="name"
              label="Name"
              placeholder="Social Impact Fund"
              register={register}
              errors={errors}
            />

            <CurrencyInput
              name="value"
              label="Value"
              placeholder="1000000"
              register={register}
              errors={errors}
            />

            <ButtonGroup spacing="4">
              <Button aria-label='submit' type="submit" colorScheme='blue'>Create</Button>
              <Button aria-label="cancel" onClick={closeModal} variant="outline">Cancel</Button>
            </ButtonGroup>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

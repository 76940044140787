import { useQuery } from '@tanstack/react-query';
import { type IInvestmentOverview, type IServiceUserOverview } from '../types';
import { apiClient, type APIItemResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useServiceUserOverview () {
  return useQuery({
    queryKey: ['useServiceUserOverview'],
    queryFn: async () => {
      const url = API_URL.overview.serviceUser;
      const { data } = await apiClient.get<APIItemResponse<IServiceUserOverview>>(url);
      return data;
    },
  });
}

export function useInvestmentsOverview () {
  return useQuery({
    queryKey: ['useInvestmentsOverview'],
    queryFn: async () => {
      const url = API_URL.overview.investment;
      const { data } = await apiClient.get<APIItemResponse<IInvestmentOverview>>(url);
      return data;
    },
  });
}

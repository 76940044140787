import { Button, Center, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Currency from '../../../../partials/output/Currency';
import { type IOrganisationChild } from '../../../../types';

export default function ServiceProviderTable ({ organisations }: { organisations: IOrganisationChild[] }) {
  if (organisations.length === 0) {
    return (
      <Center mt={8}>
        <Text fontSize="lg" color="gray.500">
          Once Service Providers are configured for your account, they will display here
        </Text>
      </Center>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>State</Th>
            <Th>Investment Value<br />(Count)</Th>
            <Th>Service<br />Users</Th>
            <Th>User<br />Accounts</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {organisations?.map((organisation) => (
            <Tr key={organisation.id}>
              <Td>
                {organisation.name}
              </Td>
              <Td>
                {organisation.isEnabled
                  ? <Tag colorScheme='green'>Active</Tag>
                  : <Tag colorScheme='red'>Inactive</Tag>}
              </Td>
              <Td>
                <Currency value={organisation.sumInvestmentsValue} /> ({organisation.countInvestments})
              </Td>
              <Td>
                {organisation.countServiceUsers}
              </Td>
              <Td>
                {organisation.countUsers}
              </Td>
              <Td>
                <Button as={Link} to={`/portfolio/service-providers/${organisation.id}`} size='sm' colorScheme='teal'>Manage</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

import { Container, Flex, Heading, Spacer, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import UsersTable from './partials/UsersTable';

export default function UsersList () {
  return (
    <Container py="4" maxW="8xl">
      <Flex>
        <Heading mb="4">Users</Heading>
        <Spacer/>
        <Button as={Link} to="/root/users/create" colorScheme="blue" mb="4">New User</Button>
      </Flex>
      <UsersTable />
    </Container>
  );
}

import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Tag, Button, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useListOrganisations } from '../../../../hooks/useOrganisation';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import DateTime from '../../../../partials/output/DateTime';
import Pagination from '../../../../partials/output/Pagination';

export default function OrganisationsTable () {
  const { data, isLoading, isError, error, pagination } = useListOrganisations();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>State</Th>
              <Th>Type</Th>
              <Th>No. User</Th>
              <Th>Events</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.docs.map((organisation) => (
              <Tr key={organisation.id}>
                <Td>
                  {organisation.name}
                </Td>
                <Td>
                  {organisation.isEnabled
                    ? <Tag colorScheme='green'>Active</Tag>
                    : <Tag colorScheme='red'>Inactive</Tag>}
                </Td>
                <Td>
                  <Stack spacing="1">
                    {organisation.isFundManager && <Tag colorScheme='blue'>Fund Manager</Tag>}
                    {organisation.isFundRecipient && <Tag colorScheme='orange'>Fund Recipient</Tag>}
                  </Stack>
                </Td>
                <Td>
                  {organisation.countUsers}
                </Td>
                <Td>
                  <Text>Created: <DateTime date={organisation.createdAt}/></Text>
                  <Text>Updated: <DateTime date={organisation.updatedAt}/></Text>
                </Td>
                <Td>
                  <Button as={Link} to={`/root/organisations/${organisation.id}`} size='sm' colorScheme='teal'>Edit</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination {...pagination} />
    </>
  );
}

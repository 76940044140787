import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { clearSession } from '../../hooks/useSession';
import PublicLayout from '../../partials/layout/PublicLayout';
import SetPasswordForm from './partials/SetPasswordForm';

export default function SetPassword () {
  const [isSuccess, setIsSuccess] = useState(false);

  const passwordSecret = new URLSearchParams(window.location.search).get('code') ?? 'nope';

  // Clear any existing session
  clearSession();

  return (
    <PublicLayout>
      <Stack spacing={8}>
        <Heading size="lg">Set a secure password</Heading>
        {
          isSuccess
            ? <Text>Your password has been changed.</Text>
            : <SetPasswordForm passwordSecret={passwordSecret} onSuccess={() => { setIsSuccess(true); }} />
        }
        <Button variant="link" as={Link} to="/login">Back to Login</Button>
      </Stack>
    </PublicLayout>
  );
}

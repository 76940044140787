import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type CreateServiceUser, type IServiceUserExtended, type UpdateServiceUser } from '../types';
import { apiClient, type APIItemResponse, type APIPaginatedQuery, type APIPaginatedResponse } from '../utils/apiClient';
import { API_URL } from '../utils/constants';

export function useListServiceUsers () {
  const [page, setPage] = useState(1);

  const query = useQuery({
    queryKey: ['useListServiceUsers', page],
    queryFn: async () => {
      const url = API_URL.serviceUser.list;
      const options: APIPaginatedQuery = { params: { page, pageSize: 10000 } }; // TODO Paginate on UI
      const { data } = await apiClient.get<APIPaginatedResponse<IServiceUserExtended>>(url, options);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const pageCount = query.data?.pageCount ?? 1;
  const nextPage = () => { setPage(Math.min(page + 1, pageCount)); };
  const prevPage = () => { setPage(Math.max(page - 1, 1)); };

  return { ...query, pagination: { nextPage, prevPage, page, pageCount } };
}

export function useGetServiceUser (id: string) {
  const queryClient = useQueryClient();
  const queryKey = ['useGetServiceUser', id];

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const url = API_URL.serviceUser.get(id);
      const { data } = await apiClient.get<APIItemResponse<IServiceUserExtended>>(url);
      return data;
    },
  });

  return { ...query, clearCache };
}

export function useCreateServiceUser () {
  return useMutation({
    mutationFn: async (serviceUser: CreateServiceUser) => {
      const url = API_URL.serviceUser.create;
      const { data } = await apiClient.post<APIItemResponse<IServiceUserExtended>>(url, serviceUser);
      return data;
    },
  });
}

export function useUpdateServiceUser () {
  return useMutation({
    mutationFn: async (serviceUser: UpdateServiceUser) => {
      const url = API_URL.serviceUser.update(serviceUser.id);
      const { data } = await apiClient.post<APIItemResponse<IServiceUserExtended>>(url, serviceUser);
      return data;
    },
  });
}

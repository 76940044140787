import { Container, Heading, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrganisation } from '../../../hooks/useOrganisation';
import { useSuccessToast } from '../../../hooks/useToast';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import OrganisationUpdateForm from './partials/OrganisationUpdateForm';
import ChildRelationships from './partials/relationships/ChildRelationships';
import ParentRelationships from './partials/relationships/ParentRelationships';

export default function OrganisationUpdate () {
  const success = useSuccessToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isError, error, clearCache } = useGetOrganisation(id as string);

  const onSuccess = async () => {
    await clearCache();
    success('Organisation updated');
    navigate('/root/organisations');
  };

  if (isLoading || data === undefined) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  return (
    <Container py="4" maxW="md">
      <Heading mb="4">Edit Organisation</Heading>
      <OrganisationUpdateForm organisation={data.docs} onSuccess={onSuccess} />
      <Text as="hr" mt="8" />
      <ParentRelationships childId={id as string} />
      <ChildRelationships parentId={id as string} />
    </Container>
  );
}

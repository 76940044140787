import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Grid, GridItem, VisuallyHidden } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { type FieldErrors, type Control, Controller, type ControllerRenderProps } from 'react-hook-form';
import { ReactSelectStyles } from '../../theme';

interface PropsType {
  name: string
  label: string
  helperText?: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

const days = Array.from(Array(31).keys())
  .map((i) => ({
    value: i + 1,
    label: (i + 1).toString()
  }));

const months = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

// 15 years back
const thisYear = new Date().getFullYear();
const years = Array.from(Array(15).keys())
  .map((i) => ({
    value: thisYear - i,
    label: (thisYear - i).toString()
  }));

// Sub-Component so we have the `field` object from react-hook-form in Component scope
function ControlledInput ({ name, field }: { name: string, field: ControllerRenderProps<any, string> }) {
  const dayName = `${name}-date`;
  const monthName = `${name}-month`;
  const yearName = `${name}-year`;

  // Split the date string into day, month and year
  const splitDate = (value: string | null) => {
    if (typeof value !== 'string') {
      return { day: null, month: null, year: null };
    }

    const date = new Date(value);
    const obj = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
    return obj;
  };

  // Set initial state to field values
  const [day, setDay] = useState<number | null>(splitDate(field.value).day);
  const [month, setMonth] = useState<number | null>(splitDate(field.value).month);
  const [year, setYear] = useState<number | null>(splitDate(field.value).year);

  useEffect(() => {
    // Update values
    if (day !== null && month !== null && year !== null) {
      // Convert state values to a valid date
      const date = new Date(year, month, day);

      // Set the date string in react-hook-form
      field.onChange(date.toISOString().split('T')[0]);

      // Set computed values back to state
      setDay(date.getDate());
      setMonth(date.getMonth());
      setYear(date.getFullYear());
    } else if (day !== null || month !== null || year !== null) {
      field.onChange('Invalid Date');
    } else {
      field.onChange(null);
    }
  }, [day, month, year]);

  // Debugging
  // useEffect(() => { console.log(field.value); }, [field.value]);

  return (
    <>
      <Grid gap={4} templateColumns='repeat(24, 1fr)'>
        <GridItem colSpan={7}>
          <VisuallyHidden>
            <FormLabel id={`${dayName}-label`} htmlFor={dayName}>Day</FormLabel>
          </VisuallyHidden>

          <Select
            name={dayName}
            inputId={dayName}
            isClearable={true}
            isSearchable={true}
            isInvalid={day === null && (month !== null || year !== null)}
            placeholder="Day"
            aria-labelledby={`${dayName}-label`}
            options={days}
            onChange={(val) => { setDay(val?.value ?? null); }}
            value={days?.find((option) => option.value === splitDate(field.value).day)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        </GridItem>

        <GridItem colSpan={10}>
          <VisuallyHidden>
            <FormLabel id={`${monthName}-label`} htmlFor={monthName}>Month</FormLabel>
          </VisuallyHidden>

          <Select
            name={monthName}
            inputId={monthName}
            isClearable={true}
            isSearchable={true}
            isInvalid={month === null && (day !== null || year !== null)}
            placeholder="Month"
            aria-labelledby={`${monthName}-label`}
            options={months}
            onChange={(val) => { setMonth(val?.value ?? null); }}
            value={months?.find((option) => option.value === splitDate(field.value).month)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        </GridItem>

        <GridItem colSpan={7}>
          <VisuallyHidden>
            <FormLabel id={`${yearName}-label`} htmlFor={yearName}>Year</FormLabel>
          </VisuallyHidden>

          <Select
            name={yearName}
            inputId={yearName}
            isClearable={true}
            isSearchable={true}
            isInvalid={year === null && (day !== null || month !== null)}
            placeholder="Year"
            aria-labelledby={`${yearName}-label`}
            options={years}
            onChange={(val) => { setYear(val?.value ?? null); }}
            value={years?.find((option) => option.value === splitDate(field.value).year)}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        </GridItem>
      </Grid>

      <input type="hidden" data-testid="hidden" value={(typeof field.value === 'string') ? field.value : ''} />
    </>
  );
}

export default function DateInput ({ name, label, helperText, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;
  const hasHelperText = helperText !== undefined;

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={`${name}-date`}>{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (<ControlledInput name={name} field={field} />)}
      />

      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : hasHelperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

import { Box } from '@chakra-ui/react';
import { useDeleteRelationship, useGetChildrenFor } from '../../../../../hooks/useRelationships';
import { useSuccessToast } from '../../../../../hooks/useToast';
import RelationshipForm from './RelationshipForm';
import RelationshipsTable from './RelationshipsTable';

interface PropsType {
  parentId: string
}

export default function ChildRelationships ({ parentId }: PropsType) {
  const success = useSuccessToast();
  const children = useGetChildrenFor(parentId);
  const deleteRelationship = useDeleteRelationship();

  return (
    <Box mt="8" data-testid="child-relationships">
      <RelationshipForm parentId={parentId} onSuccess={async () => {
        await children.refetch();
        success('Child organisation added');
      }} />
      <RelationshipsTable name="children-table" query={children} deleteFn={async (child) => {
        await deleteRelationship.mutateAsync({ parentId, childId: child.id });
        await children.refetch();
        success('Child organisation deleted');
      }} />
    </Box>
  );
}

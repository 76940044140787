import { Container, Heading, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';
import { APP_VERSION } from '../../utils/config';

interface PublicLayoutProps {
  children?: React.ReactNode
}

export default function PublicLayout ({ children }: PublicLayoutProps) {
  const navigate = useNavigate();
  const { session } = useSession();

  useEffect(() => {
    // Send to dashboard if authenticated
    if (session?.accessToken !== undefined) navigate('/');
  }, [session]);

  return (
    <Container maxWidth="sm" height="100%" textAlign="center">
      <Heading my={14}>ImpactGo</Heading>
      {children}
      <Text fontSize="sm" color="gray" my={14}>&copy; 2023 CM Russell Ltd. v{APP_VERSION}</Text>
    </Container>
  );
}

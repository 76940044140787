import { Box, Card, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { type IServiceUserOverview } from '../../../../types';
import { round } from '../../../../utils/utils';

function TableRow ({ heading, value, maxValue, totalUsers }: { heading: string, value: number, maxValue: number, totalUsers: number }) {
  const barWidth = (value / maxValue) * 100;
  const percUsers = (value: number) => round((value / totalUsers) * 100, 2);

  return (
    <Tr>
      <Td>{heading}</Td>
      <Td isNumeric>{value}</Td>
      <Td width='100px' p={0}>
        <Box height='20px' w={`${barWidth}%`} backgroundColor='pink.500' />
      </Td>
      <Td isNumeric>{percUsers(value)}%</Td>
    </Tr>
  );
}

export default function GenderWidget ({ data }: { data: IServiceUserOverview }) {
  const maxValue = Math.max(...[
    data.countGenderMale,
    data.countGenderFemale,
    data.countGenderNotSay,
    data.countGenderOther,
  ]);

  return (
    <Card p={4}>
      <Table size='sm' variant='simple'>
        <Thead>
          <Tr>
            <Th>Gender</Th>
            <Th colSpan={3}>Service Users</Th>
          </Tr>
        </Thead>
        <Tbody>
          <TableRow
            heading='Male'
            value={data.countGenderMale}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Female'
            value={data.countGenderFemale}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Not Say'
            value={data.countGenderNotSay}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='Other'
            value={data.countGenderOther}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
          <TableRow
            heading='No Data'
            value={data.countGenderNull}
            maxValue={maxValue}
            totalUsers={data.countServiceUsers}
          />
        </Tbody>
      </Table>
    </Card>
  );
}

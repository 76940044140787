// Approx 1700 common name first & surnames
//
// Consolidated list from below datasets
// Limited to most popular 1000 names from boys & girls
// Removed names less than 3 characters long
// Removed prefix duplicates e.g. ADAM for ADA using:
//
//   names.forEach((a) => {
//     const i = names.findIndex((b) => (a === b) ? false : a.startsWith(b))
//     if (i === -1) console.log(a)
//   })
//
// Source Boys: https://www.ons.gov.uk/peoplepopulationandcommunity/birthsdeathsandmarriages/livebirths/datasets/babynamesenglandandwalesbabynamesstatisticsboys
// Source Girls: https://www.ons.gov.uk/peoplepopulationandcommunity/birthsdeathsandmarriages/livebirths/datasets/babynamesenglandandwalesbabynamesstatisticsgirls
// Source Historical: https://www.ons.gov.uk/peoplepopulationandcommunity/birthsdeathsandmarriages/livebirths/datasets/babynamesenglandandwalestop100babynameshistoricaldata
// Source Surnames: https://www.familysearch.org/en/wiki/England_Surname_Frequency_and_Geography_-_International_Institute

/**
 * Test if the text contains a commonly used name
 * @param text
 * @returns
 */
export function containsCommonName (text: string): boolean {
  const upText = text.toUpperCase();
  const index = names.findIndex(name => upText.includes(name));
  // if (index !== -1) console.log('Matched name:', names[index]);
  return index !== -1;
}

export const names = [
  'AADAM',
  'AADHYA',
  'AAFIYAH',
  'AAHIL',
  'AALIYAH',
  'AAMINAH',
  'AARAV',
  'AARIZ',
  'AARON',
  'AARYA',
  'AASIYAH',
  'AAYAN',
  'AAYAT',
  'ABBAS',
  'ABBIE',
  'ABDUL',
  'ABDURRAHMAN',
  'ABEEHA',
  'ABEL',
  'ABIGAIL',
  'ABIHA',
  'ABRAHAM',
  'ABU',
  'ACE',
  'ADA',
  'ADDISON',
  'ADELAIDE',
  'ADELE',
  'ADELINA',
  'ADELINE',
  'ADEN',
  'ADIL',
  'ADNAN',
  'ADONIS',
  'ADRIAN',
  'ADRIEL',
  'ADVIK',
  'ADYAN',
  'AGATHA',
  'AGNES',
  'AHAD',
  'AHMAD',
  'AHMED',
  'AIDA',
  'AIDEN',
  'AILA',
  'AIMEE',
  'AISHA',
  'AIYLA',
  'AIZA',
  'AJAY',
  'AKRAM',
  'ALAIA',
  'ALAINA',
  'ALAIYA',
  'ALAN',
  'ALARA',
  'ALASTAIR',
  'ALAYA',
  'ALAYNA',
  'ALBA',
  'ALBERT',
  'ALBI',
  'ALBY',
  'ALEC',
  'ALEENA',
  'ALEK',
  'ALESSANDRO',
  'ALESSIA',
  'ALESSIO',
  'ALEX',
  'ALFIE',
  'ALFRED',
  'ALI',
  'ALLAN',
  'ALLEGRA',
  'ALLEN',
  'ALMA',
  'ALORA',
  'ALVEY',
  'ALVIE',
  'ALVIN',
  'ALYA',
  'ALYS',
  'AMAAN',
  'AMAIA',
  'AMAL',
  'AMANDA',
  'AMANI',
  'AMAR',
  'AMAYA',
  'AMBER',
  'AMEERA',
  'AMELIA',
  'AMELIE',
  'AMIN',
  'AMIR',
  'AMIYAH',
  'AMMAR',
  'AMNA',
  'AMORA',
  'AMOS',
  'AMY',
  'ANA',
  'ANDRE',
  'ANDY',
  'ANGEL',
  'ANGUS',
  'ANIKA',
  'ANITA',
  'ANIYAH',
  'ANN',
  'ANTHONY',
  'ANTON',
  'ANYA',
  'AOIFE',
  'APOLLO',
  'APRIL',
  'AQSA',
  'ARABELLA',
  'ARAS',
  'ARAYA',
  'ARCHER',
  'ARCHIBALD',
  'ARCHIE',
  'ARDEN',
  'AREN',
  'ARES',
  'ARHAM',
  'ARI',
  'ARJAN',
  'ARJUN',
  'ARLA',
  'ARLEN',
  'ARLEY',
  'ARLIE',
  'ARLO',
  'ARMAAN',
  'ARMAN',
  'ARNIE',
  'ARNOLD',
  'ARON',
  'ARRAN',
  'ARTEMIS',
  'ARTHUR',
  'ARTIE',
  'ARTUR',
  'ARVIN',
  'ARWA',
  'ARWEN',
  'ARYA',
  'ASA',
  'ASEES',
  'ASHER',
  'ASHLEIGH',
  'ASHLEY',
  'ASHTON',
  'ASIYA',
  'ASMA',
  'ASTON',
  'ASTRID',
  'ATHENA',
  'ATLAS',
  'ATTICUS',
  'AUBREE',
  'AUBREY',
  'AUDREY',
  'AUGUST',
  'AURA',
  'AURELIA',
  'AURORA',
  'AUSTIN',
  'AUTUMN',
  'AVA',
  'AVERY',
  'AVI',
  'AVNEET',
  'AVYAAN',
  'AXEL',
  'AXL',
  'AYA',
  'AYDA',
  'AYDEN',
  'AYDIN',
  'AYESHA',
  'AYEZA',
  'AYLA',
  'AYLIN',
  'AYMAN',
  'AYOUB',
  'AYRA',
  'AYRTON',
  'AYSHA',
  'AYUB',
  'AYVA',
  'AYYUB',
  'AYZAL',
  'AYZEL',
  'AZAAN',
  'AZALEA',
  'AZLAN',
  'BAANI',
  'BAILEY',
  'BAKER',
  'BARBARA',
  'BARNABY',
  'BARNEY',
  'BARRIE',
  'BARRY',
  'BASIL',
  'BAXTER',
  'BEA',
  'BELLA',
  'BELLE',
  'BEN',
  'BENNETT',
  'BERNARD',
  'BERNIE',
  'BERTHA',
  'BERTIE',
  'BERTRAM',
  'BERYL',
  'BESSIE',
  'BETH',
  'BETSY',
  'BETTY',
  'BEVERLEY',
  'BIANCA',
  'BIANKA',
  'BILAL',
  'BILLIE',
  'BILLY',
  'BJORN',
  'BLAIR',
  'BLAKE',
  'BLANCHE',
  'BLANKA',
  'BLEU',
  'BLOSSOM',
  'BOBBI',
  'BOBBY',
  'BODEN',
  'BODHI',
  'BODIE',
  'BOGDAN',
  'BONNIE',
  'BORIS',
  'BOWEN',
  'BOWIE',
  'BRADLEY',
  'BRANDON',
  'BRAYDEN',
  'BRENDA',
  'BRETT',
  'BRIAN',
  'BRIDGET',
  'BRIELLE',
  'BRODIE',
  'BRODY',
  'BROOKE',
  'BROOKLYN',
  'BROWN',
  'BRUCE',
  'BRUNO',
  'BRYAN',
  'BRYONY',
  'BRYSON',
  'BUDDY',
  'BYRON',
  'CADE',
  'CADI',
  'CAELAN',
  'CAI',
  'CALEB',
  'CALI',
  'CALLAN',
  'CALLIE',
  'CALLUM',
  'CALVIN',
  'CAMERON',
  'CAMILA',
  'CAMILLA',
  'CAMILLE',
  'CAOIMHE',
  'CARA',
  'CARL',
  'CARMEN',
  'CAROL',
  'CARSON',
  'CARTER',
  'CARYS',
  'CASEY',
  'CASPAR',
  'CASPER',
  'CASPIAN',
  'CASSANDRA',
  'CASSIAN',
  'CASSIDY',
  'CASSIE',
  'CASSIUS',
  'CATALEYA',
  'CATHERINE',
  'CAYDEN',
  'CECE',
  'CECIL',
  'CELESTE',
  'CELIA',
  'CELINE',
  'CERYS',
  'CEZAR',
  'CHAIM',
  'CHANTELLE',
  'CHARLENE',
  'CHARLES',
  'CHARLIE',
  'CHARLOTTE',
  'CHASE',
  'CHAYA',
  'CHELSEA',
  'CHERYL',
  'CHESTER',
  'CHIARA',
  'CHLOE',
  'CHRISTIAN',
  'CHRISTINA',
  'CHRISTINE',
  'CHRISTOPHER',
  'CIAN',
  'CIARA',
  'CILLIAN',
  'CLAIR',
  'CLARA',
  'CLARE',
  'CLARK',
  'CLAUDE',
  'CLAUDIA',
  'CLAY',
  'CLEMENTINE',
  'CLEMMIE',
  'CLEO',
  'CLIFFORD',
  'CLIVE',
  'CLOVER',
  'COBIE',
  'COBY',
  'COCO',
  'CODY',
  'COEN',
  'COHEN',
  'COLBY',
  'COLE',
  'COLIN',
  'COLTON',
  'CONNIE',
  'CONNOR',
  'CONOR',
  'CONSTANCE',
  'COOK',
  'COOPER',
  'CORA',
  'CORDELIA',
  'COREY',
  'CORY',
  'COSMO',
  'COURTNEY',
  'CRAIG',
  'CREED',
  'CRISTIAN',
  'CRUZ',
  'CRYSTAL',
  'CURTIS',
  'CYNTHIA',
  'CYRIL',
  'CYRUS',
  'DAHLIA',
  'DAISY',
  'DAKOTA',
  'DALE',
  'DALIA',
  'DALTON',
  'DAMIAN',
  'DAMIEN',
  'DAMIR',
  'DAMON',
  'DANA',
  'DANIA',
  'DANIEL',
  'DANIYAL',
  'DANNY',
  'DANTE',
  'DANYAL',
  'DAPHNE',
  'DARCEY',
  'DARCIE',
  'DARCY',
  'DARIA',
  'DARIO',
  'DARIUS',
  'DARLA',
  'DARREN',
  'DAVID',
  'DAVIES',
  'DAVIS',
  'DAWID',
  'DAWN',
  'DAWSON',
  'DAWUD',
  'DAX',
  'DEACON',
  'DEAN',
  'DEBBIE',
  'DEBORAH',
  'DEBRA',
  'DECLAN',
  'DEEN',
  'DELIA',
  'DELILAH',
  'DEMI',
  'DENIS',
  'DENIZ',
  'DENNIS',
  'DENNY',
  'DENVER',
  'DEREK',
  'DERRICK',
  'DESMOND',
  'DESTINY',
  'DEXTER',
  'DHRUV',
  'DIANA',
  'DIANE',
  'DIEGO',
  'DIGBY',
  'DILAN',
  'DILLON',
  'DINA',
  'DION',
  'DIYA',
  'DOLCIE',
  'DOLLIE',
  'DOLLY',
  'DOMINIC',
  'DOMINIK',
  'DONALD',
  'DONNA',
  'DORA',
  'DOREEN',
  'DORIAN',
  'DORIS',
  'DOROTHEA',
  'DOROTHY',
  'DOTTIE',
  'DOTTY',
  'DOUGIE',
  'DOUGLAS',
  'DREW',
  'DUA',
  'DULCIE',
  'DUNCAN',
  'DUSTY',
  'DYLAN',
  'EADIE',
  'EBEN',
  'EBONY',
  'EDDIE',
  'EDDISON',
  'EDEN',
  'EDGAR',
  'EDIE',
  'EDISON',
  'EDITH',
  'EDMUND',
  'EDNA',
  'EDUARD',
  'EDWARD',
  'EDWARDS',
  'EDWIN',
  'EESA',
  'EFA',
  'EFFIE',
  'EFFY',
  'EILEEN',
  'EIRA',
  'EISA',
  'EKAM',
  'ELA',
  'ELEANOR',
  'ELENA',
  'ELENI',
  'ELERI',
  'ELI',
  'ELLA',
  'ELLE',
  'ELLIANA',
  'ELLIE',
  'ELLIOT',
  'ELLIS',
  'ELODIE',
  'ELOISE',
  'ELORA',
  'ELOUISE',
  'ELOWEN',
  'ELSA',
  'ELSIE',
  'ELSPETH',
  'ELWOOD',
  'ELYAS',
  'ELYSIA',
  'ELYZA',
  'EMA',
  'EMBER',
  'EMELIA',
  'EMERSON',
  'EMIL',
  'EMIR',
  'EMMA',
  'EMMELINE',
  'EMMETT',
  'EMMIE',
  'EMMY',
  'EMRE',
  'EMRYS',
  'ENID',
  'ENOLA',
  'ENYA',
  'ENZO',
  'EOIN',
  'ERIC',
  'ERIK',
  'ERIN',
  'ERNEST',
  'ERNIE',
  'ERYN',
  'ESA',
  'ESHAAL',
  'ESMAE',
  'ESME',
  'ESSA',
  'ESTELLE',
  'ESTHER',
  'ETHAN',
  'ETHEL',
  'ETTA',
  'ETTIE',
  'EUAN',
  'EVA',
  'EVANS',
  'EVE',
  'EVIE',
  'EWAN',
  'EYAD',
  'EZEKIEL',
  'EZRA',
  'FABIAN',
  'FAITH',
  'FAIZAN',
  'FALLON',
  'FANNY',
  'FARAH',
  'FARIS',
  'FATEH',
  'FATIMA',
  'FAYE',
  'FEARNE',
  'FELICITY',
  'FELIX',
  'FERGUS',
  'FERN',
  'FFION',
  'FIADH',
  'FILIP',
  'FINLAY',
  'FINLEY',
  'FINN',
  'FIONA',
  'FIONN',
  'FLETCHER',
  'FLEUR',
  'FLORA',
  'FLORENCE',
  'FLORRIE',
  'FLYNN',
  'FOREST',
  'FORREST',
  'FOX',
  'FRANCES',
  'FRANCIS',
  'FRANCO',
  'FRANK',
  'FRASER',
  'FRAZER',
  'FRED',
  'FREJA',
  'FREYA',
  'FREYJA',
  'FRIDA',
  'FYNN',
  'GABRIEL',
  'GAIA',
  'GAIL',
  'GARETH',
  'GARRY',
  'GARY',
  'GAVIN',
  'GEMMA',
  'GENE',
  'GEOFFREY',
  'GEORGE',
  'GEORGI',
  'GERALD',
  'GERARD',
  'GERTRUDE',
  'GETHIN',
  'GIA',
  'GIDEON',
  'GIGI',
  'GILBERT',
  'GILLIAN',
  'GIORGIO',
  'GIOVANNA',
  'GIOVANNI',
  'GIULIA',
  'GLADYS',
  'GLEN',
  'GLORIA',
  'GLYNIS',
  'GOLDIE',
  'GORDON',
  'GRACE',
  'GRACIE',
  'GRAEME',
  'GRAHAM',
  'GRAY',
  'GREEN',
  'GREGORY',
  'GRETA',
  'GREYSON',
  'GRIFFIN',
  'GRIFFITHS',
  'GRUFFYDD',
  'GURBAAZ',
  'GURFATEH',
  'GUS',
  'GUY',
  'GWEN',
  'HAADI',
  'HAANIYA',
  'HAARIS',
  'HADI',
  'HADLEY',
  'HAFSA',
  'HAIDER',
  'HAILEY',
  'HAKEEM',
  'HALEEMA',
  'HALIMA',
  'HALL',
  'HALLE',
  'HALLIE',
  'HAMISH',
  'HAMZA',
  'HANA',
  'HANIA',
  'HANIYA',
  'HANNA',
  'HARI',
  'HARLAN',
  'HARLEEN',
  'HARLEN',
  'HARLEY',
  'HARLIE',
  'HARLOW',
  'HARMONY',
  'HAROLD',
  'HAROON',
  'HARPER',
  'HARRI',
  'HARRIS',
  'HARRY',
  'HARUN',
  'HARVEY',
  'HASAN',
  'HASHIM',
  'HASHIR',
  'HASSAN',
  'HATTIE',
  'HAWA',
  'HAWWA',
  'HAYA',
  'HAYDEN',
  'HAYLEY',
  'HAZEL',
  'HEATH',
  'HECTOR',
  'HEIDI',
  'HELEN',
  'HENDRIX',
  'HENLEY',
  'HENRI',
  'HENRY',
  'HERBERT',
  'HERBIE',
  'HERMIONE',
  'HETTIE',
  'HIBA',
  'HIDAYAH',
  'HILARY',
  'HILDA',
  'HILL',
  'HOLLIE',
  'HOLLY',
  'HONEY',
  'HOORAIN',
  'HOPE',
  'HORACE',
  'HOWARD',
  'HUBERT',
  'HUDA',
  'HUDSON',
  'HUGH',
  'HUGHES',
  'HUGO',
  'HUMAIRA',
  'HUNTER',
  'HUSNA',
  'HUSSAIN',
  'HUXLEY',
  'HUZAIFA',
  'IAIN',
  'IAN',
  'IBRAHEEM',
  'IBRAHIM',
  'IDA',
  'IDREES',
  'IDRIS',
  'IGA',
  'IGNACY',
  'IGOR',
  'ILYAS',
  'IMAAN',
  'IMAN',
  'IMOGEN',
  'IMRAN',
  'INAAYA',
  'INARA',
  'INAYA',
  'INDI',
  'INDY',
  'INES',
  'INGRID',
  'IOAN',
  'IONA',
  'IOSIF',
  'IQRA',
  'IRENE',
  'IRHA',
  'IRIS',
  'ISA',
  'ISHA',
  'ISLA',
  'ISMAEEL',
  'ISMAEL',
  'ISMAIL',
  'ISOBEL',
  'ISRA',
  'ISSA',
  'IVAAN',
  'IVAN',
  'IVAR',
  'IVIE',
  'IVO',
  'IVY',
  'IYLA',
  'IZAAN',
  'IZABELA',
  'IZABELLA',
  'IZAIAH',
  'IZHAAN',
  'JAC',
  'JACKSON',
  'JAD',
  'JAGO',
  'JAI',
  'JAKE',
  'JAKOB',
  'JAKUB',
  'JAMAL',
  'JAMES',
  'JAMIE',
  'JAN',
  'JASMIN',
  'JASON',
  'JASPER',
  'JAX',
  'JAY',
  'JEAN',
  'JEEVAN',
  'JEFFREY',
  'JEMIMA',
  'JEMMA',
  'JENNA',
  'JENNIFER',
  'JENNY',
  'JENSEN',
  'JENSON',
  'JEREMIAH',
  'JEREMY',
  'JESSE',
  'JESSICA',
  'JESSIE',
  'JETT',
  'JIBREEL',
  'JILL',
  'JIM',
  'JIYA',
  'JOAN',
  'JODIE',
  'JOE',
  'JOHN',
  'JONAH',
  'JONAS',
  'JONATHAN',
  'JONES',
  'JONI',
  'JORDAN',
  'JORGE',
  'JORGIE',
  'JOSE',
  'JOSH',
  'JOSIAH',
  'JOSIE',
  'JOVAN',
  'JOY',
  'JUDAH',
  'JUDE',
  'JUDITH',
  'JULIA',
  'JULIE',
  'JULIUS',
  'JUNE',
  'JUNIOR',
  'JUNIPER',
  'JUNO',
  'JUSTIN',
  'JUSTYNA',
  'KABIR',
  'KACPER',
  'KADE',
  'KAHN',
  'KAI',
  'KAJA',
  'KAJUS',
  'KALEB',
  'KAMARI',
  'KAMIL',
  'KANE',
  'KARA',
  'KAREEM',
  'KAREN',
  'KARIM',
  'KARINA',
  'KARL',
  'KARSON',
  'KARTER',
  'KASPER',
  'KATE',
  'KATHERINE',
  'KATHLEEN',
  'KATHRYN',
  'KATIE',
  'KATY',
  'KAY',
  'KEANU',
  'KEEGAN',
  'KEHLANI',
  'KEIRA',
  'KEITH',
  'KELLY',
  'KENDALL',
  'KENDRICK',
  'KENNETH',
  'KENNY',
  'KENZA',
  'KENZO',
  'KERRY',
  'KEVIN',
  'KEYAAN',
  'KEZIAH',
  'KHADIJA',
  'KHALID',
  'KHALIL',
  'KHAN',
  'KIAAN',
  'KIAN',
  'KIARA',
  'KIERA',
  'KILLIAN',
  'KIM',
  'KING',
  'KINZA',
  'KIRA',
  'KIRSTY',
  'KIT',
  'KIYAAN',
  'KIYAN',
  'KLARA',
  'KLAY',
  'KOA',
  'KOBE',
  'KOBI',
  'KOBY',
  'KODA',
  'KODY',
  'KORNELIA',
  'KRISH',
  'KRISTIAN',
  'KYAN',
  'KYLA',
  'KYLE',
  'KYLIAN',
  'KYLO',
  'KYRA',
  'KYRIE',
  'KYRO',
  'LACEY',
  'LACHLAN',
  'LAIBA',
  'LAILA',
  'LAINEY',
  'LAITH',
  'LAKE',
  'LANA',
  'LANDO',
  'LARA',
  'LAURA',
  'LAUREN',
  'LAURIE',
  'LAVINIA',
  'LAWRENCE',
  'LAWSON',
  'LAYAN',
  'LAYLA',
  'LAYTH',
  'LAYTON',
  'LEA',
  'LEE',
  'LEIA',
  'LEIGH',
  'LEILA',
  'LENA',
  'LENI',
  'LENNIE',
  'LENNON',
  'LENNOX',
  'LENNY',
  'LEO',
  'LESLEY',
  'LESLIE',
  'LEVI',
  'LEWIS',
  'LEXI',
  'LEYLA',
  'LIA',
  'LIBBY',
  'LIBERTY',
  'LILA',
  'LILI',
  'LILLIAN',
  'LILLIE',
  'LILLY',
  'LILY',
  'LINA',
  'LINCOLN',
  'LINDA',
  'LINDSAY',
  'LINDSEY',
  'LIONEL',
  'LISA',
  'LIVIA',
  'LIYA',
  'LLOYD',
  'LOCHLAN',
  'LOCKIE',
  'LOGAN',
  'LOIS',
  'LOKI',
  'LOLA',
  'LORCAN',
  'LORELAI',
  'LORENA',
  'LORENZO',
  'LORRAINE',
  'LOTTIE',
  'LOUIE',
  'LOUIS',
  'LOWEN',
  'LOWRI',
  'LUAN',
  'LUCA',
  'LUCCA',
  'LUCIA',
  'LUCIE',
  'LUCY',
  'LUELLA',
  'LUIS',
  'LUKA',
  'LUKE',
  'LULA',
  'LUNA',
  'LYDIA',
  'LYLA',
  'LYNDA',
  'LYNDSEY',
  'LYNN',
  'LYNSEY',
  'LYRA',
  'MABEL',
  'MABLI',
  'MACEY',
  'MACIE',
  'MACSEN',
  'MACY',
  'MADDIE',
  'MADDISON',
  'MADDOX',
  'MADELEINE',
  'MADELINE',
  'MADIHA',
  'MADINA',
  'MADISON',
  'MAE',
  'MAGGIE',
  'MAGNUS',
  'MAHDI',
  'MAHIRA',
  'MAHNOOR',
  'MAIA',
  'MAIRA',
  'MAISIE',
  'MAISY',
  'MAJA',
  'MAKSYMILIAN',
  'MALACHI',
  'MALAIKA',
  'MALAK',
  'MALCOLM',
  'MALEK',
  'MALI',
  'MANDY',
  'MANHA',
  'MARA',
  'MARC',
  'MARGARET',
  'MARGAUX',
  'MARGERY',
  'MARGO',
  'MARI',
  'MARJORIE',
  'MARK',
  'MARLA',
  'MARLENE',
  'MARLEY',
  'MARLIE',
  'MARLO',
  'MARNIE',
  'MARSHALL',
  'MARTHA',
  'MARTIN',
  'MARTYN',
  'MARWA',
  'MARY',
  'MASON',
  'MATEI',
  'MATEO',
  'MATHEW',
  'MATHIAS',
  'MATIAS',
  'MATILDA',
  'MATTEO',
  'MATTHEW',
  'MATTHIAS',
  'MATTIAS',
  'MAUD',
  'MAUREEN',
  'MAURICE',
  'MAVERICK',
  'MAVIS',
  'MAX',
  'MAY',
  'MCKENZIE',
  'MEADOW',
  'MEDINA',
  'MEGAN',
  'MELANIA',
  'MELANIE',
  'MELINA',
  'MELISA',
  'MELISSA',
  'MELODY',
  'MELVYN',
  'MEREDITH',
  'MERRYN',
  'MIA',
  'MICAH',
  'MICHAEL',
  'MICHAL',
  'MICHELE',
  'MICHELLE',
  'MIGUEL',
  'MIKAEEL',
  'MIKAEL',
  'MIKAIL',
  'MIKAYLA',
  'MIKEY',
  'MIKHAIL',
  'MIKOLAJ',
  'MILA',
  'MILDRED',
  'MILENA',
  'MILES',
  'MILEY',
  'MILLER',
  'MILLICENT',
  'MILLIE',
  'MILLY',
  'MILO',
  'MIMI',
  'MINA',
  'MINHA',
  'MINNIE',
  'MIRA',
  'MIRHA',
  'MIRIAM',
  'MIRUNA',
  'MITCHELL',
  'MIYA',
  'MOHAMAD',
  'MOHAMED',
  'MOHAMMAD',
  'MOHAMMED',
  'MOLLIE',
  'MOLLY',
  'MONICA',
  'MONROE',
  'MONTAGUE',
  'MONTGOMERY',
  'MONTY',
  'MOORE',
  'MORGAN',
  'MORRIS',
  'MOSES',
  'MOSHE',
  'MUHAMMAD',
  'MUHAMMED',
  'MURIEL',
  'MURPHY',
  'MUSA',
  'MUSTAFA',
  'MYA',
  'MYLA',
  'MYLES',
  'MYLO',
  'MYRA',
  'NADIA',
  'NAHLA',
  'NAIA',
  'NAILA',
  'NALA',
  'NANCIE',
  'NANCY',
  'NANSI',
  'NAOMI',
  'NATALIA',
  'NATALIE',
  'NATAN',
  'NATASHA',
  'NATE',
  'NATHAN',
  'NAVY',
  'NAYA',
  'NAYLA',
  'NEAVE',
  'NED',
  'NEIL',
  'NEL',
  'NEO',
  'NERIAH',
  'NEVAEH',
  'NEVE',
  'NEVILLE',
  'NIA',
  'NICHOLA',
  'NICO',
  'NIGEL',
  'NIKITA',
  'NIKO',
  'NINA',
  'NIYA',
  'NOA',
  'NOEL',
  'NOLA',
  'NOOR',
  'NORA',
  'NORMA',
  'NOUR',
  'NOVA',
  'NUALA',
  'NUSAYBAH',
  'NYLA',
  'NYRA',
  'OAKLEY',
  'OBI',
  'OCEAN',
  'OCTAVIA',
  'ODIN',
  'OISIN',
  'OLA',
  'OLIVE',
  'OLIVIA',
  'OLIVIER',
  'OLIWIA',
  'OLIWIER',
  'OLLIE',
  'OLLY',
  'OMAR',
  'OMER',
  'OONA',
  'OPHELIA',
  'OREN',
  'ORHAN',
  'ORIN',
  'ORION',
  'ORLA',
  'ORSON',
  'OSCAR',
  'OSIAN',
  'OSKAR',
  'OSMAN',
  'OTIS',
  'OTTILIE',
  'OTTO',
  'OVIE',
  'OWAIS',
  'OWEN',
  'OZZIE',
  'OZZY',
  'PABLO',
  'PADDY',
  'PAIGE',
  'PAISLEY',
  'PALOMA',
  'PAMELA',
  'PARIS',
  'PARKER',
  'PATRICIA',
  'PATRICK',
  'PAUL',
  'PAYTON',
  'PEARL',
  'PEGGY',
  'PENELOPE',
  'PENNY',
  'PERCIVAL',
  'PERCY',
  'PERSEPHONE',
  'PETER',
  'PEYTON',
  'PHILIP',
  'PHILLIP',
  'PHILLIPS',
  'PHOEBE',
  'PHOENIX',
  'PHYLLIS',
  'PIA',
  'PIPER',
  'PIPPA',
  'PIXIE',
  'POLA',
  'POLLY',
  'POPPIE',
  'POPPY',
  'PRESLEY',
  'PRESTON',
  'PRICE',
  'PRIMROSE',
  'PRINCE',
  'PRISHA',
  'PRIYA',
  'QASIM',
  'QUINN',
  'RACHAEL',
  'RACHEL',
  'RAE',
  'RAFAEL',
  'RAFE',
  'RAFFERTY',
  'RAFI',
  'RAHEEM',
  'RAHMA',
  'RAIDEN',
  'RAIFE',
  'RAINA',
  'RAINE',
  'RAISA',
  'RALPH',
  'RAMI',
  'RANIA',
  'RAPHAEL',
  'RARES',
  'RAUL',
  'RAVEN',
  'RAY',
  'REAGAN',
  'REBECA',
  'REBECCA',
  'REBEKAH',
  'REECE',
  'REEVA',
  'REGAN',
  'REGGIE',
  'REGINALD',
  'REHMAT',
  'REID',
  'REIGN',
  'REINA',
  'REMI',
  'REMY',
  'REN',
  'REUBEN',
  'REX',
  'REYA',
  'RHEA',
  'RHIANNON',
  'RHYS',
  'RIA',
  'RICARDO',
  'RICHARD',
  'RICHIE',
  'RICKY',
  'RICO',
  'RILEY',
  'RIO',
  'RISHI',
  'RITA',
  'RIVA',
  'RIVER',
  'RIYA',
  'ROAN',
  'ROB',
  'ROCCO',
  'ROCKY',
  'RODNEY',
  'ROGAN',
  'ROGER',
  'ROHAN',
  'ROISIN',
  'ROLAND',
  'ROMA',
  'ROME',
  'ROMI',
  'ROMY',
  'RONALD',
  'RONAN',
  'RONNIE',
  'RONNY',
  'RORY',
  'ROSA',
  'ROSCOE',
  'ROSE',
  'ROSIE',
  'ROSINA',
  'ROSS',
  'ROUX',
  'ROWAN',
  'ROXANNE',
  'ROY',
  'RUAIRI',
  'RUBEN',
  'RUBY',
  'RUDI',
  'RUDY',
  'RUEBEN',
  'RUFUS',
  'RUMAYSA',
  'RUPERT',
  'RUQAYYAH',
  'RUSSELL',
  'RUTH',
  'RYAN',
  'RYDER',
  'RYKER',
  'RYLEE',
  'RYLEY',
  'SAAD',
  'SAANVI',
  'SABRINA',
  'SADIE',
  'SAFA',
  'SAFFRON',
  'SAFIYA',
  'SAFIYYAH',
  'SAFWAN',
  'SAGE',
  'SAHIB',
  'SAIF',
  'SAINT',
  'SALAHUDDIN',
  'SALLY',
  'SALMA',
  'SAM',
  'SANA',
  'SANDRA',
  'SANTIAGO',
  'SANTINO',
  'SAOIRSE',
  'SAPPHIRE',
  'SARA',
  'SASHA',
  'SASKIA',
  'SAUL',
  'SAVANNA',
  'SAYED',
  'SCARLET',
  'SCOTT',
  'SEAN',
  'SEB',
  'SELENA',
  'SELINA',
  'SEREN',
  'SETH',
  'SHANAYA',
  'SHANE',
  'SHANNON',
  'SHARON',
  'SHAUN',
  'SHAW',
  'SHAY',
  'SHEIKH',
  'SHEILA',
  'SHELBY',
  'SHILOH',
  'SHIRLEY',
  'SIA',
  'SID',
  'SIENA',
  'SIENNA',
  'SIERRA',
  'SILAS',
  'SIMON',
  'SIYA',
  'SKY',
  'SMITH',
  'SOFIA',
  'SOLOMON',
  'SONIA',
  'SONNY',
  'SOPHIA',
  'SOPHIE',
  'SORAYA',
  'SPENCER',
  'STACEY',
  'STANISLAW',
  'STANLEY',
  'STANTON',
  'STEFAN',
  'STELLA',
  'STEPHANIE',
  'STEPHEN',
  'STEVEN',
  'STEVIE',
  'STEWART',
  'STORM',
  'STUART',
  'SUBHAN',
  'SUFYAN',
  'SULAIMAN',
  'SULAYMAN',
  'SULEIMAN',
  'SULLIVAN',
  'SULTAN',
  'SUMAYYAH',
  'SUMMER',
  'SUNDAY',
  'SUNNY',
  'SUSAN',
  'SUZANNE',
  'SYBIL',
  'SYDNEY',
  'SYED',
  'SYLVIA',
  'SYLVIE',
  'SZYMON',
  'TABITHA',
  'TADHG',
  'TAHA',
  'TAHLIA',
  'TALHA',
  'TALIA',
  'TALLULAH',
  'TAMARA',
  'TANYA',
  'TARA',
  'TARON',
  'TATE',
  'TAYLOR',
  'TED',
  'TEO',
  'TERENCE',
  'TERESA',
  'TERRY',
  'TESSA',
  'THALIA',
  'THEA',
  'THEIA',
  'THELMA',
  'THEO',
  'THERESA',
  'THIAGO',
  'THOMAS',
  'THOMPSON',
  'TIA',
  'TIFFANY',
  'TILLIE',
  'TILLY',
  'TIMOTHY',
  'TIMUR',
  'TINA',
  'TOBIAS',
  'TOBY',
  'TOM',
  'TONI',
  'TONY',
  'TRACEY',
  'TRACY',
  'TRAVIS',
  'TRENT',
  'TREVOR',
  'TRISTAN',
  'TROY',
  'TUDOR',
  'TURNER',
  'TYLER',
  'TYMON',
  'TYMOTEUSZ',
  'TYSON',
  'UMAIZA',
  'UMAR',
  'UNA',
  'USMAN',
  'UTHMAN',
  'UZAIR',
  'VALENCIA',
  'VALENTINA',
  'VALENTINO',
  'VALERIE',
  'VANESSA',
  'VEER',
  'VERA',
  'VERITY',
  'VERONICA',
  'VIAAN',
  'VICTOR',
  'VIENNA',
  'VIHAAN',
  'VIKTOR',
  'VINCENT',
  'VINCENZO',
  'VINNIE',
  'VINNY',
  'VIOLET',
  'VIVIENNE',
  'VLAD',
  'WALKER',
  'WALTER',
  'WARD',
  'WARREN',
  'WATSON',
  'WAYNE',
  'WENDY',
  'WESLEY',
  'WHITE',
  'WIKTOR',
  'WILBUR',
  'WILF',
  'WILLIAM',
  'WILLOW',
  'WILSON',
  'WINIFRED',
  'WINNIE',
  'WINSTON',
  'WINTER',
  'WOOD',
  'WOODY',
  'WREN',
  'WRIGHT',
  'WYATT',
  'WYNTER',
  'XANDER',
  'XANTHE',
  'XAVIER',
  'YAHYA',
  'YAQUB',
  'YARA',
  'YASEEN',
  'YASIN',
  'YASIR',
  'YASMIN',
  'YAZAN',
  'YOUSEF',
  'YOUSIF',
  'YOUSSEF',
  'YOUSUF',
  'YUNUS',
  'YUSHA',
  'YUSRA',
  'YUSUF',
  'YUVRAJ',
  'YVONNE',
  'ZAC',
  'ZADIE',
  'ZAHRA',
  'ZAID',
  'ZAIN',
  'ZAK',
  'ZANDER',
  'ZANE',
  'ZARA',
  'ZAVIYAR',
  'ZAYAAN',
  'ZAYAN',
  'ZAYD',
  'ZAYN',
  'ZAYYAN',
  'ZELDA',
  'ZENDAYA',
  'ZEPHYR',
  'ZEYNEP',
  'ZIAN',
  'ZIDAN',
  'ZIGGY',
  'ZIMAL',
  'ZION',
  'ZOE',
  'ZOFIA',
  'ZOHA',
  'ZORAWAR',
  'ZOYA',
  'ZUNAIRA',
  'ZURI',
  'ZUZANNA',
];

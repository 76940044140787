import { Card, Container, Heading, SimpleGrid, Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { useGetServiceProvider } from '../../../hooks/useServiceProvider';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import Currency from '../../../partials/output/Currency';
import { type IOrganisationChild } from '../../../types';
import ManageInvestments from './partials/ManageInvestments';
import ManageUsers from './partials/ManageUsers';

export default function ServiceProvidersView () {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useGetServiceProvider(id as string);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  // non-null because of the isLoading and isError checks above
  const organisation = data?.docs as IOrganisationChild;

  return (
    <Container py="4" maxW="6xl">
      <Link to="/portfolio/service-providers">&larr; Back to Service Providers</Link>

      <Heading mb="4">{organisation.name}</Heading>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" py="4">
        <Stat as={Card} p="4">
          <StatLabel>Total Invested</StatLabel>
          <StatNumber><Currency value={organisation.sumInvestmentsValue} /></StatNumber>
          <StatHelpText>Through {organisation.countInvestments} Investments</StatHelpText>
        </Stat>
        <Stat as={Card} p="4">
          <StatLabel>Supported Service Users</StatLabel>
          <StatNumber>{organisation.countServiceUsers}</StatNumber>
          <StatHelpText>Through {organisation.countInvestments} Investments</StatHelpText>
        </Stat>
      </SimpleGrid>

      <ManageInvestments organisationId={organisation.id} />
      <ManageUsers organisationId={organisation.id} />
    </Container>
  );
}


import { FormControl, FormErrorMessage, FormHelperText, FormLabel, type SelectProps } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type Control, Controller, type FieldErrors } from 'react-hook-form';
import { ReactSelectStyles } from '../../theme';
import { vulnerabilityGroups } from '../../utils/vulnerabilityGroups';

interface PropsType extends SelectProps {
  name: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

export default function VulnerabilityGroupSelect ({ name, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;

  const options = vulnerabilityGroups.map((vg) => ({ value: vg.code, label: vg.label }));

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={name}>Vulnerability Groups</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            isClearable={true}
            isSearchable={true}
            isMulti={true}
            placeholder="Select Vulnerability Groups..."
            aria-labelledby={`${name}-label`}
            options={options}
            onChange={(val) => { field.onChange(val?.map((v) => v.value)); }}
            value={field.value?.map((v: string) => options?.find((option) => option.value === v))}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
          />
        )}
      />
      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : <FormHelperText>The needs or circumstances of the service user</FormHelperText>
      }
    </FormControl>
  );
}

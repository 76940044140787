import { Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import ServiceUserStateTag from '../../../../partials/output/ServiceUserStateTag';
import { ServiceUserState, type IAnalyticsSegment } from '../../../../types';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

const STATES: Array<{ code: ServiceUserState, itemKey: keyof IAnalyticsSegment }> = [
  {
    code: ServiceUserState.AwaitingService,
    itemKey: 'countStateAwaitingService',
  },
  {
    code: ServiceUserState.WithService,
    itemKey: 'countStateWithService',
  },
  {
    code: ServiceUserState.MovedOnPositive,
    itemKey: 'countStateMovedOnPositive',
  },
  {
    code: ServiceUserState.MovedOnNegative,
    itemKey: 'countStateMovedOnNegative',
  },
  {
    code: ServiceUserState.MovedOnIndeterminate,
    itemKey: 'countStateMovedOnIndeterminate',
  },
];

export default function StateChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const max = Math.max(...STATES.map(({ itemKey }) => row.d2[itemKey] as number));

  return (
    <Stack spacing={4}>
      <HeadingToolTip size='sm' label='Distribution of Service Users by service lifecycle'>Service Lifecycle</HeadingToolTip>
      <Table variant='simple' size='sm' >
        <Thead>
          <Tr>
            <Th>Lifecycle</Th>
            <Th>Service Users</Th>
          </Tr>
        </Thead>
        <Tbody>
          {STATES.map(({ code, itemKey }) => (
            <Tr key={code}>
              <Td><ServiceUserStateTag state={code} /></Td>
              <TdMetricBar key={row.segmentId} item={row} itemKey={itemKey} max={max} barColor='teal.500' />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack >
  );
}

import { Center, Container, Flex, Heading, Spacer, Stack, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useServiceUserOverview } from '../../../hooks/useOverview';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import { SegmentType } from '../../../types';
import AnalyticsSegment from './AnalyticsSegment';
import SegmentDateSelect, { defaultOptions } from './partials/SegmentDateSelect';
import SegmentTypeSelect from './partials/SegmentTypeSelect';
import { getQuarterEnds, getQuarterStarts } from './utils';

export default function AnalyticsHome () {
  const { data, isLoading, isError, error } = useServiceUserOverview();
  const [searchParams, setSearchParams] = useSearchParams();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  if (data?.docs === undefined || data?.docs === null || data?.docs.countServiceUsers === 0) {
    return (
      <Container py="4" maxW="8xl">
        <Heading>Analytics</Heading>
        <Center as={Stack} mt={8} spacing={8}>
          <Text fontSize="xl" color="gray.500">Your analytics will be available once your Service Providers begin submitting data.</Text>
        </Center>
      </Container>
    );
  }

  const startDateOptions = [...defaultOptions, ...getQuarterStarts()];
  const endDateOptions = [...defaultOptions, ...getQuarterEnds()];

  const segmentType = searchParams.get('segmentType') as SegmentType ?? SegmentType.FUND_RECIPIENT;
  const startDate = searchParams.get('startDate') ?? defaultOptions[1].date;
  const endDate = searchParams.get('endDate') ?? defaultOptions[0].date;

  return (
    <Container maxW='8xl' pb={16}>
      <Flex alignItems='center' gap={4} py={4}>
        <Heading>Analytics</Heading>
        <Spacer />
        <Text fontWeight='bold'>Show</Text>
        <SegmentTypeSelect
          segmentType={segmentType}
          setSegmentType={(segmentType) => { setSearchParams({ segmentType, startDate, endDate }); }}
        />
        <Text fontWeight='bold'>from</Text>
        <SegmentDateSelect
          segmentDate={startDate}
          setSegmentDate={(startDate) => { setSearchParams({ segmentType, startDate, endDate }); }}
          options={startDateOptions}
          label='StartDateSelect'
        />
        <Text fontWeight='bold'>to</Text>
        <SegmentDateSelect
          segmentDate={endDate}
          setSegmentDate={(endDate) => { setSearchParams({ segmentType, startDate, endDate }); }}
          options={endDateOptions}
          label='EndDateSelect'
        />
      </Flex>

      <AnalyticsSegment
        segmentType={segmentType}
        startDate={startDate}
        endDate={endDate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </Container>
  );
}

import { Button, Center, Flex, Heading, Spacer, Stack, Table, Tbody, Td, Text, Th, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useListInvestmentsForServiceProvider } from '../../../../hooks/useServiceProvider';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import Currency from '../../../../partials/output/Currency';
import { type IInvestmentExtended } from '../../../../types';

export default function ManageInvestments ({ organisationId }: Readonly<{ organisationId: string }>) {
  const { data, isLoading, isError, error } = useListInvestmentsForServiceProvider(organisationId);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const investments = data?.docs as IInvestmentExtended[];

  if (investments.length === 0) {
    return (
      <>
        <Heading size="lg">Investments</Heading>
        <Center as={Stack} mt={8} spacing={8} textAlign='center'>
          <Text fontSize="lg" color="gray.500">Add an Investment for this Service Provider</Text>
          <Button as={Link} to="/portfolio/funds" colorScheme="blue" >Manage Investments</Button>
          <Text color="gray.500">
            After an Investment is created for a Service Provider,<br />
            they can begin managing Service Users in their Dashboard
          </Text>
        </Center>
      </>
    );
  }

  return (
    <>
      <Flex>
        <Heading mb="4" size="lg">Investments</Heading>
        <Spacer />
        <Button as={Link} to="/portfolio/funds" colorScheme="teal" mb="4" size="sm">Manage Investments</Button>
      </Flex>

      <Table variant="simple" mt="4">
        <Tbody>
          <Tr>
            <Th pl="1">Fund Name</Th>
            <Th>Fund Amount</Th>
            <Th>Investment Amount</Th>
            <Th pr="1">Num. Service Users</Th>
          </Tr>
          {investments.map((investment) => (
            <Tr key={investment.id}>
              <Td pl="1">{investment.fundName}</Td>
              <Td><Currency value={investment.fundValue} /></Td>
              <Td><Currency value={investment.value} /></Td>
              <Td>{investment.countServiceUsers}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

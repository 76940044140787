
/**
 * Traverse an Pojo calling function on each key/value pair
 * and returning a new object
 * @param obj
 * @param func
 * @returns
 */
export const traverse = (obj: any, func: (key: string | number, value: any) => any): any => {
  // Traverse a object calling function on each key/value pair returning a new object
  const traverseObjectReturn = (obj: any, func: (key: string | number, value: any) => any): any => {
    // If is a primitive, return primitive
    if (obj !== Object(obj)) return obj;

    // If is an array, handle as array
    if (Array.isArray(obj)) return traverseArrayReturn(obj, func);

    const newObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
        newObj[key] = traverseObjectReturn(value, func);
      } else {
        newObj[key] = func(key, value);
      }
    }
    return newObj;
  };

  // Traverse an array calling function on each element returning a new array
  const traverseArrayReturn = (arr: any[], func: (key: number | string, value: any) => any): any => {
    const newArr: any[] = [];
    arr.forEach((value, index) => {
      if (typeof value === 'object') {
        newArr.push(traverseObjectReturn(value, func));
      } else {
        newArr.push(func(index, value));
      }
    });
    return newArr;
  };

  return traverseObjectReturn(obj, func);
};

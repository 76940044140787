import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { useListInvestments } from '../../hooks/useFundRecipient';
import { getDateString } from '../../pages/app/Analytics/utils';
import { ReactSelectStyles } from '../../theme';

interface PropsType {
  name: string
  placeholder?: string
  helperText?: string
  control: Control<any, any>
  errors: FieldErrors<any>
}

/* istanbul ignore next */
const noOptionsMessage = () => 'Your Fund Manager has not yet assigned you to an Investment';

export default function InvestmentSelect ({ name, placeholder, helperText, control, errors }: Readonly<PropsType>) {
  const hasError = name in errors;
  const hasHelperText = helperText !== undefined;

  const { data, isLoading } = useListInvestments();

  const options = data?.docs.map((investment) => {
    const valueStr = investment.value.toLocaleString();
    return {
      value: investment.id,
      label: `${investment.fundManagerName}: ${investment.fundName} (£${valueStr}) ${getDateString(investment.drawdownDate)}`
    };
  });

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel id={`${name}-label`} htmlFor={name}>Investment*</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            name={name}
            inputId={name}
            aria-labelledby={`${name}-label`}
            placeholder={placeholder ?? 'Select Investment...'}
            isSearchable={true}
            options={options}
            onChange={(val) => { field.onChange(val?.value); }}
            value={options?.find((option) => option.value === field.value)}
            isLoading={isLoading}
            menuPortalTarget={document.body}
            styles={ReactSelectStyles}
            noOptionsMessage={noOptionsMessage}
          />
        )}
      />
      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : hasHelperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

import { Box, Flex, Spacer, Button } from '@chakra-ui/react';

interface PaginationProps {
  nextPage: () => void
  prevPage: () => void
  page: number
  pageCount: number
}

export default function Pagination ({ nextPage, prevPage, page, pageCount }: PaginationProps) {
  if (pageCount === 1) return null;

  return (
    <Flex alignItems="center" gap="4" width="100%" mt="4">
      <Spacer/>
      <Button onClick={prevPage} isDisabled={page === 1}>Prev</Button>
      <Box>{page} of {pageCount}</Box>
      <Button onClick={nextPage} isDisabled={page === pageCount}>Next</Button>
      <Spacer/>
    </Flex>
  );
}

import { Button, Center, Flex, Heading, Spacer, Stack, Table, Tbody, Td, Text, Th, Tr, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDeleteInvestment, useListInvestments } from '../../../../hooks/useInvestments';
import { useErrorToast, useSuccessToast, useWarningToast } from '../../../../hooks/useToast';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import Currency from '../../../../partials/output/Currency';
import { type IFund, type IFundExtended, type IInvestmentExtended } from '../../../../types';
import { getDateString } from '../../Analytics/utils';
import CreateInvestmentModal from './CreateInvestmentModal';

interface PropsType {
  fund: IFund | IFundExtended
  onChange: () => void
}

export default function ManageInvestments ({ fund, onChange }: Readonly<PropsType>) {
  const createModal = useDisclosure();
  const success = useSuccessToast();
  const warning = useWarningToast();
  const danger = useErrorToast();
  const investments = useListInvestments(fund);
  const destroy = useDeleteInvestment();

  useEffect(() => {
    if (!createModal.isOpen) {
      void investments.refetch();
      onChange();
    }
  }, [createModal.isOpen]);

  const onDelete = (investment: IInvestmentExtended) => {
    if (investment.countServiceUsers > 0) {
      warning('Cannot delete an Investment with Service Users');
    } else {
      destroy.mutate(investment, {
        onSuccess: () => {
          success('Investment deleted');
          void investments.refetch(); // Reload investments list
          onChange();
        },
        onError: (error) => {
          danger(error.message);
        }
      });
    }
  };

  if (investments.isLoading) return <Loading />;
  if (investments.isError) return <ErrorAlert error={investments.error} />;

  if (investments.data?.docs.length === 0) {
    return (
      <>
        <Heading size="lg">Investments</Heading>
        <Center as={Stack} mt={8} spacing={8} textAlign='center'>
          <Text fontSize="lg" color="gray.500">Add an Investment to your Fund</Text>
          <Button onClick={createModal.onOpen} colorScheme="blue">Add Investment</Button>
          <Text color="gray.500">
            After an Investment is created for a Service Provider,<br />
            they can begin managing Service Users in their Dashboard
          </Text>
        </Center>

        <CreateInvestmentModal fundId={fund.id} disclosure={createModal} />
      </>
    );
  }

  return (
    <>
      <Flex my="4">
        <Heading size="lg">Investments</Heading>
        <Spacer />
        <Button size="sm" onClick={createModal.onOpen} colorScheme="green">Add</Button>
      </Flex>

      <Table variant="simple" mt="4">
        <Tbody>
          <Tr>
            <Th pl="1">Fund Recipient</Th>
            <Th>Investment Amount</Th>
            <Th>Drawdown Date</Th>
            <Th>No. Service Users</Th>
            <Th pr="1"></Th>
          </Tr>
          {investments.data?.docs.map((investment) => (
            <Tr key={investment.id}>
              <Td pl="1">{investment.fundRecipientName}</Td>
              <Td><Currency value={investment.value} /></Td>
              <Td>{getDateString(investment.drawdownDate)}</Td>
              <Td>{investment.countServiceUsers}</Td>
              <Td pr="1" textAlign="right">
                <Button
                  size="xs"
                  colorScheme={investment.countServiceUsers > 0 ? 'blackAlpha' : 'red'}
                  aria-label='delete'
                  onClick={() => { onDelete(investment); }}
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <CreateInvestmentModal fundId={fund.id} disclosure={createModal} />
    </>
  );
}

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import { theme } from './theme';

const queryClient = new QueryClient();

export default function App () {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <RouterProvider router={createBrowserRouter(routes)} />
        </ChakraProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

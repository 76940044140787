import { Tag, Tooltip } from '@chakra-ui/react';
import { ServiceUserState } from '../../types';

export default function ServiceUserStateTag ({ state }: Readonly<{ state: ServiceUserState }>) {
  if (state === ServiceUserState.AwaitingService) {
    return (
      <Tooltip label='Service Users who have not yet begun to receive support' hasArrow>
        <Tag colorScheme='yellow'>Awaiting Service</Tag>
      </Tooltip>
    );
  }
  if (state === ServiceUserState.WithService) {
    return (
      <Tooltip label='Service Users currently receiving support from a Service Provider' hasArrow>
        <Tag colorScheme='blue'>With Service</Tag>
      </Tooltip>
    );
  }
  if (state === ServiceUserState.MovedOnPositive) {
    return (
      <Tooltip label='Service Users who have stopped receiving support, with a positive outcome' hasArrow>
        <Tag colorScheme='green'>Positive Move On</Tag>
      </Tooltip>
    );
  }
  if (state === ServiceUserState.MovedOnNegative) {
    return (
      <Tooltip label='Service Users who have stopped receiving support, with a negative outcome' hasArrow>
        <Tag colorScheme='red'>Negative Move On</Tag>
      </Tooltip>
    );
  }
  if (state === ServiceUserState.MovedOnIndeterminate) {
    return (
      <Tooltip label='Service Users who have stopped receiving support, with an unknown outcome' hasArrow>
        <Tag colorScheme='orange'>Indeterminate Move On</Tag>
      </Tooltip>
    );
  }
  return null;
}

import { Heading, Tooltip, type HeadingProps } from '@chakra-ui/react';

interface HeadingToolTipProps extends HeadingProps {
  label: string

}

export default function HeadingToolTip (props: HeadingToolTipProps) {
  return (
    <Heading {...props}>
      <Tooltip label={props.label} placement='bottom' hasArrow>
        {props.children}
      </Tooltip>
    </Heading>
  );
}

import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { type UseFormRegister, type FieldErrors } from 'react-hook-form';

interface TextInputProps {
  name: string
  type?: string
  placeholder?: string
  label?: string
  helperText?: string
  register: UseFormRegister<any>
  errors: FieldErrors<any>
}

export function CurrencyInput ({ name, type, placeholder, label, helperText, register, errors }: TextInputProps) {
  const hasError = name in errors;
  const hasLabel = label !== undefined;
  const hasHelperText = helperText !== undefined;

  return (
    <FormControl isInvalid={name in errors}>
      {hasLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <InputLeftAddon>£</InputLeftAddon>
        <Input type={type ?? 'number'} id={name} autoComplete='off' placeholder={placeholder} {...register(name)} />
      </InputGroup>
      {
        hasError
          ? <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
          : hasHelperText && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

import { Button, Stack } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useLogin } from '../../../hooks/useAuthentication';
import { TextInput } from '../../../partials/input/TextInput';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import { type LoginRequest, type AuthResponse } from '../../../types';

interface PropsTypes {
  onSuccess?: (data: AuthResponse, variables: LoginRequest, context: unknown) => void
  onError?: (error: Error, variables: LoginRequest, context: unknown) => void
}

interface FieldTypes {
  login: string
  password: string
}

const schema = Joi.object({
  login: Joi.string().required(),
  password: Joi.string().required()
});

export default function LoginForm ({ onSuccess, onError }: Readonly<PropsTypes>) {
  const { mutate, error } = useLogin();
  const { register, handleSubmit, formState: { errors } } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit((data) => {
    mutate(data, { onSuccess, onError });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit}>
      <ErrorAlert error={error} />

      <TextInput
        name="login"
        type="email"
        placeholder="Login"
        register={register}
        errors={errors}
      />

      <TextInput
        name="password"
        type="password"
        placeholder="Password"
        register={register}
        errors={errors}
      />

      <Button type="submit" colorScheme='blue'>Sign In</Button>
    </Stack>
  );
}

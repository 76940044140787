import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useWhoAmI } from '../../../../hooks/useAuthentication';
import ErrorAlert from '../../../../partials/layout/ErrorAlert';
import Loading from '../../../../partials/layout/Loading';
import { type AuthResponseOrganisation, type AuthResponseUser } from '../../../../types';
import Wave from './Wave';

export default function CurrentUser () {
  const { data, isLoading, isError, error } = useWhoAmI();

  if (isLoading) return <Loading />;
  if (isError) return <ErrorAlert error={error} />;

  const user = data?.docs.user as AuthResponseUser;
  const organisation = data?.docs.organisation as AuthResponseOrganisation;

  return (
    <Stack spacing={1} my={6}>
      <Flex>
        <Heading>Welcome {user.name}!</Heading>
        <Wave />
      </Flex>
      <Text fontSize="md" color="gray.500">{organisation.name}</Text>
    </Stack>
  );
}

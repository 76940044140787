import { NotAllowedIcon } from '@chakra-ui/icons';
import { Avatar, HStack, Text } from '@chakra-ui/react';
import { getVulnerabilityGroupByCode } from '../../utils/vulnerabilityGroups';

export function VulnerabilityGroupLabel ({ code }: Readonly<{ code: string }>) {
  const group = getVulnerabilityGroupByCode(code);
  const avatar = group === undefined
    ? <Avatar icon={<NotAllowedIcon />} bg='gray.400' size="xs" />
    : <Avatar name={group.shortLabel} size="xs" />;
  return <HStack>{avatar}<Text>{group?.label ?? 'Not Provided'}</Text></HStack>;
}

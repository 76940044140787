import { ChevronDownIcon, DownloadIcon } from '@chakra-ui/icons';
import { Button, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { loadSession } from '../../../../hooks/useSession';
import { type SegmentType } from '../../../../types';
import { API_URL } from '../../../../utils/constants';

interface Props {
  segmentType: SegmentType
  startDate: string
  endDate: string
}

export default function SegmentDownloadButton ({ segmentType, startDate, endDate }: Props) {
  const session = loadSession();
  const token = session?.accessToken as string;
  const startURL = `${API_URL.segment.download}?type=${segmentType}&date=${startDate}&token=${token}`;
  const endURL = `${API_URL.segment.download}?type=${segmentType}&date=${endDate}&token=${token}`;
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<DownloadIcon />}
        rightIcon={<ChevronDownIcon />}
        colorScheme='green'
        variant='outline'
        size='sm'
      >
        Download Data
      </MenuButton>
      <MenuList zIndex={999}>
        <MenuItem icon={<DownloadIcon />} colorScheme='teal' as={Link} href={startURL}>{startDate} (CSV)</MenuItem>
        <MenuItem icon={<DownloadIcon />} colorScheme='teal' as={Link} href={endURL}>{endDate} (CSV)</MenuItem>
      </MenuList>
    </Menu>
  );
}

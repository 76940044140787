import { Box, SimpleGrid } from '@chakra-ui/react';
import { type IServiceUserExtended } from '../../types';

export default function ServiceDates ({ serviceUser }: Readonly<{ serviceUser: IServiceUserExtended }>) {
  if (serviceUser.serviceStartDate === null) return null;

  const dateFmt: Intl.DateTimeFormatOptions = { day: 'numeric', year: 'numeric', month: 'short' };
  const startDate = new Date(serviceUser.serviceStartDate).toLocaleDateString(undefined, dateFmt);
  const endDate = (serviceUser.serviceEndDate === null) ? '-' : new Date(serviceUser.serviceEndDate).toLocaleDateString(undefined, dateFmt);

  return (
    <SimpleGrid columns={2} spacing={2} width="200px">
      <Box>Joined:</Box> <Box>{startDate}</Box>
      <Box>Left:</Box> <Box>{endDate}</Box>
      <Box>With Service:</Box> <Box>{serviceUser.daysDuration} days</Box>
    </SimpleGrid>
  );
}

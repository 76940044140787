import { TimeIcon } from '@chakra-ui/icons';
import { InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { daysAgo, getDateString, toISODateString } from '../utils';

export const defaultOptions = [
  { label: 'Now', date: toISODateString(new Date()) },
  { label: '7 Days Ago', date: toISODateString(daysAgo(7)) },
  { label: '30 Days Ago', date: toISODateString(daysAgo(30)) },
  { label: '90 Days Ago', date: toISODateString(daysAgo(90)) },
];

interface Props {
  segmentDate: string
  setSegmentDate: (arg0: string) => void
  options: Array<{ label: string, date: string }>
  label: string
}

export default function SegmentDateSelect ({ segmentDate, setSegmentDate, options, label }: Props) {
  // Add the segment date to the options if it is not already there
  if (options.find((option) => option.date === segmentDate) === undefined) {
    options.push({ label: getDateString(segmentDate), date: segmentDate });
  }

  return (
    <InputGroup maxW='11rem' bg='orange.50'>
      <InputLeftElement pointerEvents='none'>
        <TimeIcon />
      </InputLeftElement>
      <Select
        style={{ paddingInlineStart: '2.2rem' }}
        onChange={(e) => { setSegmentDate(e.target.value); }}
        value={segmentDate}
        aria-label={label}
      >
        {options.map((option) => (
          <option
            key={option.label}
            value={option.date}>
            {option.label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
}

// UK Local authority codes
//
// Source: https://geoportal.statistics.gov.uk/datasets/ons::local-authority-districts-april-2023-names-and-codes-in-the-united-kingdom/explore
// Published: 5 April 2023
// Data Retrieved: 19 June 2023
//
// Restructure details published here:
// https://www.ons.gov.uk/methodology/geography/ukgeographies/administrativegeography/ourchanginggeography/localgovernmentrestructuring

export interface LocalAuthority {
  code: string
  EN: string // English name
  CY?: string // Welsh name
  objectId: number
}

export const localAuthorityCodes: LocalAuthority[] = [
  { code: 'E06000001', EN: 'Hartlepool', objectId: 1 },
  { code: 'E06000002', EN: 'Middlesbrough', objectId: 2 },
  { code: 'E06000003', EN: 'Redcar and Cleveland', objectId: 3 },
  { code: 'E06000004', EN: 'Stockton-on-Tees', objectId: 4 },
  { code: 'E06000005', EN: 'Darlington', objectId: 5 },
  { code: 'E06000006', EN: 'Halton', objectId: 6 },
  { code: 'E06000007', EN: 'Warrington', objectId: 7 },
  { code: 'E06000008', EN: 'Blackburn with Darwen', objectId: 8 },
  { code: 'E06000009', EN: 'Blackpool', objectId: 9 },
  { code: 'E06000010', EN: 'Kingston upon Hull, City of', objectId: 10 },
  { code: 'E06000011', EN: 'East Riding of Yorkshire', objectId: 11 },
  { code: 'E06000012', EN: 'North East Lincolnshire', objectId: 12 },
  { code: 'E06000013', EN: 'North Lincolnshire', objectId: 13 },
  { code: 'E06000014', EN: 'York', objectId: 14 },
  { code: 'E06000015', EN: 'Derby', objectId: 15 },
  { code: 'E06000016', EN: 'Leicester', objectId: 16 },
  { code: 'E06000017', EN: 'Rutland', objectId: 17 },
  { code: 'E06000018', EN: 'Nottingham', objectId: 18 },
  { code: 'E06000019', EN: 'Herefordshire, County of', objectId: 19 },
  { code: 'E06000020', EN: 'Telford and Wrekin', objectId: 20 },
  { code: 'E06000021', EN: 'Stoke-on-Trent', objectId: 21 },
  { code: 'E06000022', EN: 'Bath and North East Somerset', objectId: 22 },
  { code: 'E06000023', EN: 'Bristol, City of', objectId: 23 },
  { code: 'E06000024', EN: 'North Somerset', objectId: 24 },
  { code: 'E06000025', EN: 'South Gloucestershire', objectId: 25 },
  { code: 'E06000026', EN: 'Plymouth', objectId: 26 },
  { code: 'E06000027', EN: 'Torbay', objectId: 27 },
  { code: 'E06000030', EN: 'Swindon', objectId: 28 },
  { code: 'E06000031', EN: 'Peterborough', objectId: 29 },
  { code: 'E06000032', EN: 'Luton', objectId: 30 },
  { code: 'E06000033', EN: 'Southend-on-Sea', objectId: 31 },
  { code: 'E06000034', EN: 'Thurrock', objectId: 32 },
  { code: 'E06000035', EN: 'Medway', objectId: 33 },
  { code: 'E06000036', EN: 'Bracknell Forest', objectId: 34 },
  { code: 'E06000037', EN: 'West Berkshire', objectId: 35 },
  { code: 'E06000038', EN: 'Reading', objectId: 36 },
  { code: 'E06000039', EN: 'Slough', objectId: 37 },
  { code: 'E06000040', EN: 'Windsor and Maidenhead', objectId: 38 },
  { code: 'E06000041', EN: 'Wokingham', objectId: 39 },
  { code: 'E06000042', EN: 'Milton Keynes', objectId: 40 },
  { code: 'E06000043', EN: 'Brighton and Hove', objectId: 41 },
  { code: 'E06000044', EN: 'Portsmouth', objectId: 42 },
  { code: 'E06000045', EN: 'Southampton', objectId: 43 },
  { code: 'E06000046', EN: 'Isle of Wight', objectId: 44 },
  { code: 'E06000047', EN: 'County Durham', objectId: 45 },
  { code: 'E06000049', EN: 'Cheshire East', objectId: 46 },
  { code: 'E06000050', EN: 'Cheshire West and Chester', objectId: 47 },
  { code: 'E06000051', EN: 'Shropshire', objectId: 48 },
  { code: 'E06000052', EN: 'Cornwall', objectId: 49 },
  { code: 'E06000053', EN: 'Isles of Scilly', objectId: 50 },
  { code: 'E06000054', EN: 'Wiltshire', objectId: 51 },
  { code: 'E06000055', EN: 'Bedford', objectId: 52 },
  { code: 'E06000056', EN: 'Central Bedfordshire', objectId: 105 },
  { code: 'E06000057', EN: 'Northumberland', objectId: 106 },
  { code: 'E06000058', EN: 'Bournemouth, Christchurch and Poole', objectId: 107 },
  { code: 'E06000059', EN: 'Dorset', objectId: 108 },
  { code: 'E06000060', EN: 'Buckinghamshire', objectId: 109 },
  { code: 'E06000061', EN: 'North Northamptonshire', objectId: 110 },
  { code: 'E06000062', EN: 'West Northamptonshire', objectId: 111 },
  { code: 'E06000063', EN: 'Cumberland', objectId: 112 },
  { code: 'E06000064', EN: 'Westmorland and Furness', objectId: 113 },
  { code: 'E06000065', EN: 'North Yorkshire', objectId: 114 },
  { code: 'E06000066', EN: 'Somerset', objectId: 115 },
  { code: 'E07000008', EN: 'Cambridge', objectId: 116 },
  { code: 'E07000009', EN: 'East Cambridgeshire', objectId: 117 },
  { code: 'E07000010', EN: 'Fenland', objectId: 118 },
  { code: 'E07000011', EN: 'Huntingdonshire', objectId: 119 },
  { code: 'E07000012', EN: 'South Cambridgeshire', objectId: 120 },
  { code: 'E07000032', EN: 'Amber Valley', objectId: 121 },
  { code: 'E07000033', EN: 'Bolsover', objectId: 122 },
  { code: 'E07000034', EN: 'Chesterfield', objectId: 123 },
  { code: 'E07000035', EN: 'Derbyshire Dales', objectId: 124 },
  { code: 'E07000036', EN: 'Erewash', objectId: 125 },
  { code: 'E07000037', EN: 'High Peak', objectId: 126 },
  { code: 'E07000038', EN: 'North East Derbyshire', objectId: 127 },
  { code: 'E07000039', EN: 'South Derbyshire', objectId: 128 },
  { code: 'E07000040', EN: 'East Devon', objectId: 129 },
  { code: 'E07000041', EN: 'Exeter', objectId: 130 },
  { code: 'E07000042', EN: 'Mid Devon', objectId: 131 },
  { code: 'E07000043', EN: 'North Devon', objectId: 132 },
  { code: 'E07000044', EN: 'South Hams', objectId: 133 },
  { code: 'E07000045', EN: 'Teignbridge', objectId: 134 },
  { code: 'E07000046', EN: 'Torridge', objectId: 135 },
  { code: 'E07000047', EN: 'West Devon', objectId: 136 },
  { code: 'E07000061', EN: 'Eastbourne', objectId: 137 },
  { code: 'E07000062', EN: 'Hastings', objectId: 138 },
  { code: 'E07000063', EN: 'Lewes', objectId: 139 },
  { code: 'E07000064', EN: 'Rother', objectId: 140 },
  { code: 'E07000065', EN: 'Wealden', objectId: 141 },
  { code: 'E07000066', EN: 'Basildon', objectId: 142 },
  { code: 'E07000067', EN: 'Braintree', objectId: 143 },
  { code: 'E07000068', EN: 'Brentwood', objectId: 144 },
  { code: 'E07000069', EN: 'Castle Point', objectId: 145 },
  { code: 'E07000070', EN: 'Chelmsford', objectId: 146 },
  { code: 'E07000071', EN: 'Colchester', objectId: 147 },
  { code: 'E07000072', EN: 'Epping Forest', objectId: 148 },
  { code: 'E07000073', EN: 'Harlow', objectId: 149 },
  { code: 'E07000074', EN: 'Maldon', objectId: 150 },
  { code: 'E07000075', EN: 'Rochford', objectId: 151 },
  { code: 'E07000076', EN: 'Tendring', objectId: 152 },
  { code: 'E07000077', EN: 'Uttlesford', objectId: 153 },
  { code: 'E07000078', EN: 'Cheltenham', objectId: 154 },
  { code: 'E07000079', EN: 'Cotswold', objectId: 155 },
  { code: 'E07000080', EN: 'Forest of Dean', objectId: 156 },
  { code: 'E07000081', EN: 'Gloucester', objectId: 53 },
  { code: 'E07000082', EN: 'Stroud', objectId: 54 },
  { code: 'E07000083', EN: 'Tewkesbury', objectId: 55 },
  { code: 'E07000084', EN: 'Basingstoke and Deane', objectId: 56 },
  { code: 'E07000085', EN: 'East Hampshire', objectId: 57 },
  { code: 'E07000086', EN: 'Eastleigh', objectId: 58 },
  { code: 'E07000087', EN: 'Fareham', objectId: 59 },
  { code: 'E07000088', EN: 'Gosport', objectId: 60 },
  { code: 'E07000089', EN: 'Hart', objectId: 61 },
  { code: 'E07000090', EN: 'Havant', objectId: 62 },
  { code: 'E07000091', EN: 'New Forest', objectId: 63 },
  { code: 'E07000092', EN: 'Rushmoor', objectId: 64 },
  { code: 'E07000093', EN: 'Test Valley', objectId: 65 },
  { code: 'E07000094', EN: 'Winchester', objectId: 66 },
  { code: 'E07000095', EN: 'Broxbourne', objectId: 67 },
  { code: 'E07000096', EN: 'Dacorum', objectId: 68 },
  { code: 'E07000098', EN: 'Hertsmere', objectId: 69 },
  { code: 'E07000099', EN: 'North Hertfordshire', objectId: 70 },
  { code: 'E07000102', EN: 'Three Rivers', objectId: 71 },
  { code: 'E07000103', EN: 'Watford', objectId: 72 },
  { code: 'E07000105', EN: 'Ashford', objectId: 73 },
  { code: 'E07000106', EN: 'Canterbury', objectId: 74 },
  { code: 'E07000107', EN: 'Dartford', objectId: 75 },
  { code: 'E07000108', EN: 'Dover', objectId: 76 },
  { code: 'E07000109', EN: 'Gravesham', objectId: 77 },
  { code: 'E07000110', EN: 'Maidstone', objectId: 78 },
  { code: 'E07000111', EN: 'Sevenoaks', objectId: 79 },
  { code: 'E07000112', EN: 'Folkestone and Hythe', objectId: 80 },
  { code: 'E07000113', EN: 'Swale', objectId: 81 },
  { code: 'E07000114', EN: 'Thanet', objectId: 82 },
  { code: 'E07000115', EN: 'Tonbridge and Malling', objectId: 83 },
  { code: 'E07000116', EN: 'Tunbridge Wells', objectId: 84 },
  { code: 'E07000117', EN: 'Burnley', objectId: 85 },
  { code: 'E07000118', EN: 'Chorley', objectId: 86 },
  { code: 'E07000119', EN: 'Fylde', objectId: 87 },
  { code: 'E07000120', EN: 'Hyndburn', objectId: 88 },
  { code: 'E07000121', EN: 'Lancaster', objectId: 89 },
  { code: 'E07000122', EN: 'Pendle', objectId: 90 },
  { code: 'E07000123', EN: 'Preston', objectId: 91 },
  { code: 'E07000124', EN: 'Ribble Valley', objectId: 92 },
  { code: 'E07000125', EN: 'Rossendale', objectId: 93 },
  { code: 'E07000126', EN: 'South Ribble', objectId: 94 },
  { code: 'E07000127', EN: 'West Lancashire', objectId: 95 },
  { code: 'E07000128', EN: 'Wyre', objectId: 96 },
  { code: 'E07000129', EN: 'Blaby', objectId: 97 },
  { code: 'E07000130', EN: 'Charnwood', objectId: 98 },
  { code: 'E07000131', EN: 'Harborough', objectId: 99 },
  { code: 'E07000132', EN: 'Hinckley and Bosworth', objectId: 100 },
  { code: 'E07000133', EN: 'Melton', objectId: 101 },
  { code: 'E07000134', EN: 'North West Leicestershire', objectId: 102 },
  { code: 'E07000135', EN: 'Oadby and Wigston', objectId: 103 },
  { code: 'E07000136', EN: 'Boston', objectId: 104 },
  { code: 'E07000137', EN: 'East Lindsey', objectId: 157 },
  { code: 'E07000138', EN: 'Lincoln', objectId: 158 },
  { code: 'E07000139', EN: 'North Kesteven', objectId: 159 },
  { code: 'E07000140', EN: 'South Holland', objectId: 160 },
  { code: 'E07000141', EN: 'South Kesteven', objectId: 161 },
  { code: 'E07000142', EN: 'West Lindsey', objectId: 162 },
  { code: 'E07000143', EN: 'Breckland', objectId: 163 },
  { code: 'E07000144', EN: 'Broadland', objectId: 164 },
  { code: 'E07000145', EN: 'Great Yarmouth', objectId: 165 },
  { code: 'E07000146', EN: "King's Lynn and West Norfolk", objectId: 166 },
  { code: 'E07000147', EN: 'North Norfolk', objectId: 167 },
  { code: 'E07000148', EN: 'Norwich', objectId: 168 },
  { code: 'E07000149', EN: 'South Norfolk', objectId: 169 },
  { code: 'E07000170', EN: 'Ashfield', objectId: 170 },
  { code: 'E07000171', EN: 'Bassetlaw', objectId: 171 },
  { code: 'E07000172', EN: 'Broxtowe', objectId: 172 },
  { code: 'E07000173', EN: 'Gedling', objectId: 173 },
  { code: 'E07000174', EN: 'Mansfield', objectId: 174 },
  { code: 'E07000175', EN: 'Newark and Sherwood', objectId: 175 },
  { code: 'E07000176', EN: 'Rushcliffe', objectId: 176 },
  { code: 'E07000177', EN: 'Cherwell', objectId: 177 },
  { code: 'E07000178', EN: 'Oxford', objectId: 178 },
  { code: 'E07000179', EN: 'South Oxfordshire', objectId: 179 },
  { code: 'E07000180', EN: 'Vale of White Horse', objectId: 180 },
  { code: 'E07000181', EN: 'West Oxfordshire', objectId: 181 },
  { code: 'E07000192', EN: 'Cannock Chase', objectId: 182 },
  { code: 'E07000193', EN: 'East Staffordshire', objectId: 183 },
  { code: 'E07000194', EN: 'Lichfield', objectId: 184 },
  { code: 'E07000195', EN: 'Newcastle-under-Lyme', objectId: 185 },
  { code: 'E07000196', EN: 'South Staffordshire', objectId: 186 },
  { code: 'E07000197', EN: 'Stafford', objectId: 187 },
  { code: 'E07000198', EN: 'Staffordshire Moorlands', objectId: 188 },
  { code: 'E07000199', EN: 'Tamworth', objectId: 189 },
  { code: 'E07000200', EN: 'Babergh', objectId: 190 },
  { code: 'E07000202', EN: 'Ipswich', objectId: 191 },
  { code: 'E07000203', EN: 'Mid Suffolk', objectId: 192 },
  { code: 'E07000207', EN: 'Elmbridge', objectId: 193 },
  { code: 'E07000208', EN: 'Epsom and Ewell', objectId: 194 },
  { code: 'E07000209', EN: 'Guildford', objectId: 195 },
  { code: 'E07000210', EN: 'Mole Valley', objectId: 196 },
  { code: 'E07000211', EN: 'Reigate and Banstead', objectId: 197 },
  { code: 'E07000212', EN: 'Runnymede', objectId: 198 },
  { code: 'E07000213', EN: 'Spelthorne', objectId: 199 },
  { code: 'E07000214', EN: 'Surrey Heath', objectId: 200 },
  { code: 'E07000215', EN: 'Tandridge', objectId: 201 },
  { code: 'E07000216', EN: 'Waverley', objectId: 202 },
  { code: 'E07000217', EN: 'Woking', objectId: 203 },
  { code: 'E07000218', EN: 'North Warwickshire', objectId: 204 },
  { code: 'E07000219', EN: 'Nuneaton and Bedworth', objectId: 205 },
  { code: 'E07000220', EN: 'Rugby', objectId: 206 },
  { code: 'E07000221', EN: 'Stratford-on-Avon', objectId: 207 },
  { code: 'E07000222', EN: 'Warwick', objectId: 208 },
  { code: 'E07000223', EN: 'Adur', objectId: 310 },
  { code: 'E07000224', EN: 'Arun', objectId: 311 },
  { code: 'E07000225', EN: 'Chichester', objectId: 312 },
  { code: 'E07000226', EN: 'Crawley', objectId: 313 },
  { code: 'E07000227', EN: 'Horsham', objectId: 314 },
  { code: 'E07000228', EN: 'Mid Sussex', objectId: 315 },
  { code: 'E07000229', EN: 'Worthing', objectId: 316 },
  { code: 'E07000234', EN: 'Bromsgrove', objectId: 317 },
  { code: 'E07000235', EN: 'Malvern Hills', objectId: 318 },
  { code: 'E07000236', EN: 'Redditch', objectId: 319 },
  { code: 'E07000237', EN: 'Worcester', objectId: 320 },
  { code: 'E07000238', EN: 'Wychavon', objectId: 321 },
  { code: 'E07000239', EN: 'Wyre Forest', objectId: 322 },
  { code: 'E07000240', EN: 'St Albans', objectId: 323 },
  { code: 'E07000241', EN: 'Welwyn Hatfield', objectId: 324 },
  { code: 'E07000242', EN: 'East Hertfordshire', objectId: 325 },
  { code: 'E07000243', EN: 'Stevenage', objectId: 326 },
  { code: 'E07000244', EN: 'East Suffolk', objectId: 327 },
  { code: 'E07000245', EN: 'West Suffolk', objectId: 328 },
  { code: 'E08000001', EN: 'Bolton', objectId: 329 },
  { code: 'E08000002', EN: 'Bury', objectId: 330 },
  { code: 'E08000003', EN: 'Manchester', objectId: 331 },
  { code: 'E08000004', EN: 'Oldham', objectId: 332 },
  { code: 'E08000005', EN: 'Rochdale', objectId: 333 },
  { code: 'E08000006', EN: 'Salford', objectId: 334 },
  { code: 'E08000007', EN: 'Stockport', objectId: 335 },
  { code: 'E08000008', EN: 'Tameside', objectId: 336 },
  { code: 'E08000009', EN: 'Trafford', objectId: 337 },
  { code: 'E08000010', EN: 'Wigan', objectId: 338 },
  { code: 'E08000011', EN: 'Knowsley', objectId: 339 },
  { code: 'E08000012', EN: 'Liverpool', objectId: 340 },
  { code: 'E08000013', EN: 'St. Helens', objectId: 341 },
  { code: 'E08000014', EN: 'Sefton', objectId: 342 },
  { code: 'E08000015', EN: 'Wirral', objectId: 343 },
  { code: 'E08000016', EN: 'Barnsley', objectId: 344 },
  { code: 'E08000017', EN: 'Doncaster', objectId: 345 },
  { code: 'E08000018', EN: 'Rotherham', objectId: 346 },
  { code: 'E08000019', EN: 'Sheffield', objectId: 347 },
  { code: 'E08000021', EN: 'Newcastle upon Tyne', objectId: 348 },
  { code: 'E08000022', EN: 'North Tyneside', objectId: 349 },
  { code: 'E08000023', EN: 'South Tyneside', objectId: 350 },
  { code: 'E08000024', EN: 'Sunderland', objectId: 351 },
  { code: 'E08000025', EN: 'Birmingham', objectId: 352 },
  { code: 'E08000026', EN: 'Coventry', objectId: 353 },
  { code: 'E08000027', EN: 'Dudley', objectId: 354 },
  { code: 'E08000028', EN: 'Sandwell', objectId: 355 },
  { code: 'E08000029', EN: 'Solihull', objectId: 356 },
  { code: 'E08000030', EN: 'Walsall', objectId: 357 },
  { code: 'E08000031', EN: 'Wolverhampton', objectId: 358 },
  { code: 'E08000032', EN: 'Bradford', objectId: 359 },
  { code: 'E08000033', EN: 'Calderdale', objectId: 360 },
  { code: 'E08000034', EN: 'Kirklees', objectId: 361 },
  { code: 'E08000035', EN: 'Leeds', objectId: 209 },
  { code: 'E08000036', EN: 'Wakefield', objectId: 210 },
  { code: 'E08000037', EN: 'Gateshead', objectId: 211 },
  { code: 'E09000001', EN: 'City of London', objectId: 212 },
  { code: 'E09000002', EN: 'Barking and Dagenham', objectId: 213 },
  { code: 'E09000003', EN: 'Barnet', objectId: 214 },
  { code: 'E09000004', EN: 'Bexley', objectId: 215 },
  { code: 'E09000005', EN: 'Brent', objectId: 216 },
  { code: 'E09000006', EN: 'Bromley', objectId: 217 },
  { code: 'E09000007', EN: 'Camden', objectId: 218 },
  { code: 'E09000008', EN: 'Croydon', objectId: 219 },
  { code: 'E09000009', EN: 'Ealing', objectId: 220 },
  { code: 'E09000010', EN: 'Enfield', objectId: 221 },
  { code: 'E09000011', EN: 'Greenwich', objectId: 222 },
  { code: 'E09000012', EN: 'Hackney', objectId: 223 },
  { code: 'E09000013', EN: 'Hammersmith and Fulham', objectId: 224 },
  { code: 'E09000014', EN: 'Haringey', objectId: 225 },
  { code: 'E09000015', EN: 'Harrow', objectId: 226 },
  { code: 'E09000016', EN: 'Havering', objectId: 227 },
  { code: 'E09000017', EN: 'Hillingdon', objectId: 228 },
  { code: 'E09000018', EN: 'Hounslow', objectId: 229 },
  { code: 'E09000019', EN: 'Islington', objectId: 230 },
  { code: 'E09000020', EN: 'Kensington and Chelsea', objectId: 231 },
  { code: 'E09000021', EN: 'Kingston upon Thames', objectId: 232 },
  { code: 'E09000022', EN: 'Lambeth', objectId: 233 },
  { code: 'E09000023', EN: 'Lewisham', objectId: 234 },
  { code: 'E09000024', EN: 'Merton', objectId: 235 },
  { code: 'E09000025', EN: 'Newham', objectId: 236 },
  { code: 'E09000026', EN: 'Redbridge', objectId: 237 },
  { code: 'E09000027', EN: 'Richmond upon Thames', objectId: 238 },
  { code: 'E09000028', EN: 'Southwark', objectId: 239 },
  { code: 'E09000029', EN: 'Sutton', objectId: 240 },
  { code: 'E09000030', EN: 'Tower Hamlets', objectId: 241 },
  { code: 'E09000031', EN: 'Waltham Forest', objectId: 242 },
  { code: 'E09000032', EN: 'Wandsworth', objectId: 243 },
  { code: 'E09000033', EN: 'Westminster', objectId: 244 },
  { code: 'N09000001', EN: 'Antrim and Newtownabbey', objectId: 245 },
  { code: 'N09000002', EN: 'Armagh City, Banbridge and Craigavon', objectId: 246 },
  { code: 'N09000003', EN: 'Belfast', objectId: 247 },
  { code: 'N09000004', EN: 'Causeway Coast and Glens', objectId: 248 },
  { code: 'N09000005', EN: 'Derry City and Strabane', objectId: 249 },
  { code: 'N09000006', EN: 'Fermanagh and Omagh', objectId: 250 },
  { code: 'N09000007', EN: 'Lisburn and Castlereagh', objectId: 251 },
  { code: 'N09000008', EN: 'Mid and East Antrim', objectId: 252 },
  { code: 'N09000009', EN: 'Mid Ulster', objectId: 253 },
  { code: 'N09000010', EN: 'Newry, Mourne and Down', objectId: 254 },
  { code: 'N09000011', EN: 'Ards and North Down', objectId: 255 },
  { code: 'S12000005', EN: 'Clackmannanshire', objectId: 256 },
  { code: 'S12000006', EN: 'Dumfries and Galloway', objectId: 257 },
  { code: 'S12000008', EN: 'East Ayrshire', objectId: 258 },
  { code: 'S12000010', EN: 'East Lothian', objectId: 259 },
  { code: 'S12000011', EN: 'East Renfrewshire', objectId: 260 },
  { code: 'S12000013', EN: 'Na h-Eileanan Siar', objectId: 261 },
  { code: 'S12000014', EN: 'Falkirk', objectId: 262 },
  { code: 'S12000017', EN: 'Highland', objectId: 263 },
  { code: 'S12000018', EN: 'Inverclyde', objectId: 264 },
  { code: 'S12000019', EN: 'Midlothian', objectId: 265 },
  { code: 'S12000020', EN: 'Moray', objectId: 266 },
  { code: 'S12000021', EN: 'North Ayrshire', objectId: 267 },
  { code: 'S12000023', EN: 'Orkney Islands', objectId: 268 },
  { code: 'S12000026', EN: 'Scottish Borders', objectId: 269 },
  { code: 'S12000027', EN: 'Shetland Islands', objectId: 270 },
  { code: 'S12000028', EN: 'South Ayrshire', objectId: 271 },
  { code: 'S12000029', EN: 'South Lanarkshire', objectId: 272 },
  { code: 'S12000030', EN: 'Stirling', objectId: 273 },
  { code: 'S12000033', EN: 'Aberdeen City', objectId: 274 },
  { code: 'S12000034', EN: 'Aberdeenshire', objectId: 275 },
  { code: 'S12000035', EN: 'Argyll and Bute', objectId: 276 },
  { code: 'S12000036', EN: 'City of Edinburgh', objectId: 277 },
  { code: 'S12000038', EN: 'Renfrewshire', objectId: 278 },
  { code: 'S12000039', EN: 'West Dunbartonshire', objectId: 279 },
  { code: 'S12000040', EN: 'West Lothian', objectId: 280 },
  { code: 'S12000041', EN: 'Angus', objectId: 281 },
  { code: 'S12000042', EN: 'Dundee City', objectId: 282 },
  { code: 'S12000045', EN: 'East Dunbartonshire', objectId: 283 },
  { code: 'S12000047', EN: 'Fife', objectId: 284 },
  { code: 'S12000048', EN: 'Perth and Kinross', objectId: 285 },
  { code: 'S12000049', EN: 'Glasgow City', objectId: 286 },
  { code: 'S12000050', EN: 'North Lanarkshire', objectId: 287 },
  { code: 'W06000001', EN: 'Isle of Anglesey', CY: 'Ynys Môn', objectId: 288 },
  { code: 'W06000002', EN: 'Gwynedd', CY: 'Gwynedd', objectId: 289 },
  { code: 'W06000003', EN: 'Conwy', CY: 'Conwy', objectId: 290 },
  { code: 'W06000004', EN: 'Denbighshire', CY: 'Sir Ddinbych', objectId: 291 },
  { code: 'W06000005', EN: 'Flintshire', CY: 'Sir y Fflint', objectId: 292 },
  { code: 'W06000006', EN: 'Wrexham', CY: 'Wrecsam', objectId: 293 },
  { code: 'W06000008', EN: 'Ceredigion', CY: 'Ceredigion', objectId: 294 },
  { code: 'W06000009', EN: 'Pembrokeshire', CY: 'Sir Benfro', objectId: 295 },
  { code: 'W06000010', EN: 'Carmarthenshire', CY: 'Sir Gaerfyrddin', objectId: 296 },
  { code: 'W06000011', EN: 'Swansea', CY: 'Abertawe', objectId: 297 },
  { code: 'W06000012', EN: 'Neath Port Talbot', CY: 'Castell-nedd Port Talbot', objectId: 298 },
  { code: 'W06000013', EN: 'Bridgend', CY: 'Pen-y-bont ar Ogwr', objectId: 299 },
  { code: 'W06000014', EN: 'Vale of Glamorgan', CY: 'Bro Morgannwg', objectId: 300 },
  { code: 'W06000015', EN: 'Cardiff', CY: 'Caerdydd', objectId: 301 },
  { code: 'W06000016', EN: 'Rhondda Cynon Taf', CY: 'Rhondda Cynon Taf', objectId: 302 },
  { code: 'W06000018', EN: 'Caerphilly', CY: 'Caerffili', objectId: 303 },
  { code: 'W06000019', EN: 'Blaenau Gwent', CY: 'Blaenau Gwent', objectId: 304 },
  { code: 'W06000020', EN: 'Torfaen', CY: 'Torfaen', objectId: 305 },
  { code: 'W06000021', EN: 'Monmouthshire', CY: 'Sir Fynwy', objectId: 306 },
  { code: 'W06000022', EN: 'Newport', CY: 'Casnewydd', objectId: 307 },
  { code: 'W06000023', EN: 'Powys', CY: 'Powys', objectId: 308 },
  { code: 'W06000024', EN: 'Merthyr Tydfil', CY: 'Merthyr Tudful', objectId: 309 }
];

/**
 * Get a Local Authority by code
 * @param code
 * @returns
 */
export function getLocalAuthorityByCode (code: string | null): LocalAuthority | undefined {
  return localAuthorityCodes.find(row => row.code === code);
}

import { Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import AgeRangeTag from '../../../../partials/output/AgeRangeTag';
import HeadingToolTip from '../../../../partials/output/HeadingToolTip';
import { AgeRange, type IAnalyticsSegment } from '../../../../types';
import { type AnalyticsSegmentRange } from '../utils';
import { TdMetricBar } from './OverviewTable';

const AGE_RANGES: Array<{ code: AgeRange | 'NONE', itemKey: keyof IAnalyticsSegment }> = [
  {
    code: AgeRange.Under16,
    itemKey: 'countAgeUnder16',
  },
  {
    code: AgeRange.Age16To24,
    itemKey: 'countAge16To24',
  },
  {
    code: AgeRange.Age25To50,
    itemKey: 'countAge25To50',
  },
  {
    code: AgeRange.Over50,
    itemKey: 'countAgeOver50',
  },
  {
    code: 'NONE',
    itemKey: 'countAgeNull',
  },
];

export default function AgeRangeChart ({ row }: Readonly<{ row: AnalyticsSegmentRange }>) {
  const max = Math.max(...AGE_RANGES.map(({ itemKey }) => row.d2[itemKey] as number));

  return (
    <Stack spacing={4}>
      <HeadingToolTip size='sm' label='Distribution of Service Users by age range'>Age Range</HeadingToolTip>
      <Table variant='simple' size='sm' >
        <Thead>
          <Tr>
            <Th>Age Range</Th>
            <Th>Service Users</Th>
          </Tr>
        </Thead>
        <Tbody>
          {AGE_RANGES.map(({ code, itemKey }) => (
            <Tr key={code}>
              <Td><AgeRangeTag ageRange={code as any} /></Td>
              <TdMetricBar key={row.segmentId} item={row} itemKey={itemKey} max={max} barColor='purple.500' />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack >
  );
}

import { Box } from '@chakra-ui/react';
import { useDeleteRelationship, useGetParentsFor } from '../../../../../hooks/useRelationships';
import { useSuccessToast } from '../../../../../hooks/useToast';
import RelationshipForm from './RelationshipForm';
import RelationshipsTable from './RelationshipsTable';

interface PropsType {
  childId: string
}

export default function ParentRelationships ({ childId }: PropsType) {
  const success = useSuccessToast();
  const parents = useGetParentsFor(childId);
  const deleteRelationship = useDeleteRelationship();

  return (
    <Box mt="8" data-testid="parent-relationships" >
      <RelationshipForm childId={childId} onSuccess={async () => {
        await parents.refetch();
        success('Parent organisation added');
      }} />
      <RelationshipsTable name="parents-table" query={parents} deleteFn={async (parent) => {
        await deleteRelationship.mutateAsync({ childId, parentId: parent.id });
        await parents.refetch();
        success('Parent organisation deleted');
      }} />
    </Box>
  );
}

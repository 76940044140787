
interface CurrencyProps {
  value?: number | null
  currency?: string
  precision?: number
}

export default function Currency ({ value, currency = 'GBP', precision = 0 }: Readonly<CurrencyProps>) {
  const opts = {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
  };

  if (value === undefined || value === null) return (<span>-</span>);
  return (
    <span>{value.toLocaleString(undefined, opts)}</span>
  );
}

import { Avatar, Box, Text, Tooltip } from '@chakra-ui/react';

interface CircleChartData {
  key: string
  value: number
  label: string
}

function DataCircle ({ group, maxValue }: { group: CircleChartData, maxValue: number }) {
  // Scale the circle size and opacity based on the value
  const scale = group.value === 0 ? 0 : group.value / maxValue;
  const minCircleSize = 30;
  const maxCircleSize = 70;
  const size = `${maxCircleSize * scale + minCircleSize}px`;
  const opacity = group.value === 0 ? 0.2 : 1;

  // https://v2.chakra-ui.com/docs/hooks/use-breakpoint-value

  return (
    <Tooltip
      hasArrow
      label={
        <Box>
          <Text>{group.label}</Text>
          <Text>{group.value} Service Users</Text>
        </Box>
      }
    >
      <Avatar
        name={group.label}
        showBorder
        style={{
          width: size,
          height: size,
          opacity,
          cursor: 'pointer',
        }}
        _hover={{
          transform: 'scale(1.1)',
          transition: 'transform .15s ease',
          zIndex: 1,
        }}
        _even={{ alignSelf: 'flex-end' }}
        _odd={{ alignSelf: 'flex-start' }}
      >
      </Avatar>
    </Tooltip>
  );
}

export default function CircleChart ({ data }: { data: CircleChartData[] }) {
  const maxValue = Math.max(...data.map(d => d.value));

  return (
    <Box style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }} role="figure">
      {data.map(d => (<DataCircle key={d.key} group={d} maxValue={maxValue} />))}
    </Box>
  );
}

import {
  Flex,
  Heading,
  Spacer,
  ButtonGroup,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Avatar,
  Text,
  Stack,
  Center,
  type FlexProps,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import useSession from '../../hooks/useSession';

const FundRecipientNav = () => {
  return (
    <>
      <Button as={Link} to="/" variant="ghost" colorScheme='gray'>Dashboard</Button>
      <Button as={Link} to="/service-users" variant="ghost" colorScheme="gray">Service Users</Button>
    </>
  );
};

const FundManagerNav = () => {
  return (
    <>
      <Button as={Link} to="/" variant="ghost" colorScheme='gray'>Dashboard</Button>
      <Button as={Link} to="/analytics" variant="ghost" colorScheme='gray'>Analytics</Button>
      <Menu>
        <MenuButton as={Button} variant="ghost" colorScheme='gray'>Portfolio</MenuButton>
        <MenuList>
          <MenuItem as={Link} to="/portfolio/service-providers">Service Providers</MenuItem>
          <MenuItem as={Link} to="/portfolio/funds">Funds & Investments</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

const RootNav = () => {
  return (
    <Menu>
      <MenuButton as={Button} variant="ghost" colorScheme='red'>Root Admin</MenuButton>
      <MenuList>
        <MenuItem as={Link} to="/root/organisations">Organisations</MenuItem>
        <MenuItem as={Link} to="/root/users">Users</MenuItem>
      </MenuList>
    </Menu>
  );
};

const UserNav = () => {
  const { session } = useSession();

  return (
    <Menu>
      <MenuButton>
        <Avatar size="sm" name={session?.user.name} />
      </MenuButton>
      <MenuList>
        <Stack as={Center} direction="column" py={4} px={2}>
          <Avatar name={session?.user.name} size="lg" mb={4} />
          <Heading size="sm">{session?.user.name}</Heading>
          <Text>{session?.user.login}</Text>
          <Text>{session?.organisation.name}</Text>
        </Stack>
        <MenuDivider />
        <MenuItem as={Link} to="/profile">My Profile</MenuItem>
        <MenuItem as={Link} to="/profile/team">My Team</MenuItem>
        <MenuItem as={Link} to="/profile/password">Change Password</MenuItem>
        <MenuDivider />
        <MenuItem as={Link} to="/logout">Sign Out</MenuItem>
        <MenuItem as={Link} to="/support">Support</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default function NavBar (props: Readonly<FlexProps>) {
  const { isFundManager, isFundRecipient, isRoot } = usePermissions();

  return (
    <Flex
      alignItems='center'
      gap='2'
      py="2"
      px="4"
      borderBottom="1px solid RGBA(0, 0, 0, 0.08)"
      backgroundColor="white"
      zIndex="dropdown"
      {...props}
    >
      <Heading size='md'>ImpactGo</Heading>
      <ButtonGroup gap='2' ml="2">
        {isFundRecipient && <FundRecipientNav />}
        {isFundManager && <FundManagerNav />}
      </ButtonGroup>
      <Spacer />
      <ButtonGroup gap='2'>
        {isRoot && <RootNav />}
        <UserNav />
      </ButtonGroup>
    </Flex>
  );
}

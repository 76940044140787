import { Box, Card, Center, Flex, Heading, Spacer, Stack, Text } from '@chakra-ui/react';
import { useSegment } from '../../../hooks/useSegment';
import ErrorAlert from '../../../partials/layout/ErrorAlert';
import Loading from '../../../partials/layout/Loading';
import { SegmentType, type IAnalyticsSegment } from '../../../types';
import { monthDiff } from '../../../utils/utils';
import AnalyticsItem from './AnalyticsItem';
import { OverviewTable } from './partials/OverviewTable';
import SegmentDownloadButton from './partials/SegmentDownloadButton';
import { getDateString, getSegmentLabel, mergeData, type AnalyticsSegmentRange } from './utils';

interface Props {
  segmentType: SegmentType
  startDate: string
  endDate: string
  searchParams: URLSearchParams
  setSearchParams: (params: any) => void
}

function MetricsError ({ message }: Readonly<{ message: string }>) {
  return (
    <Center as={Stack} spacing={4} mt={8} rounded='5px'>
      <Text fontSize="xl" color="gray.500" >{message}</Text>
      <Text fontSize="xl" color="gray.500" >Please try again</Text>
      <Box as="img" src="/error-robot.gif" alt="A cute cartoon robot looking lost and confused" sx={{ mb: 6 }} />
    </Center>
  );
}

export default function AnalyticsSegment ({ segmentType, startDate, endDate, searchParams, setSearchParams }: Readonly<Props>) {
  if (!Object.values(SegmentType).includes(segmentType)) {
    return <MetricsError message={`The provided segment type is not recognised: '${segmentType}'.`} />;
  }
  if (isNaN(Date.parse(startDate))) {
    return <MetricsError message={`The provided start date is not recognised: '${startDate}'.`} />;
  }
  if (isNaN(Date.parse(endDate))) {
    return <MetricsError message={`The provided end date is not recognised: '${endDate}'.`} />;
  }
  if (new Date(startDate) >= new Date(endDate)) {
    return <MetricsError message={'The "from" date must be earlier than the "to" date.'} />;
  }
  if (monthDiff(startDate, endDate) > 24) {
    return <MetricsError message={'The "from" and "to" dates cannot be more than 24 months apart'} />;
  }

  const segmentLabel = getSegmentLabel(segmentType);
  const fromDateStr = getDateString(startDate);
  const toDateStr = getDateString(endDate);

  const d1 = useSegment(segmentType, startDate);
  const d2 = useSegment(segmentType, endDate);

  if (d1.isLoading || d2.isLoading) return <Loading />;
  if (d1.isError) return <ErrorAlert error={d1.error} />;
  if (d2.isError) return <ErrorAlert error={d2.error} />;

  const data1 = d1.data?.docs as IAnalyticsSegment[];
  const data2 = d2.data?.docs as IAnalyticsSegment[];
  const data = mergeData(data1, data2);

  const segmentId = searchParams.get('segmentId') ?? data[0].segmentId;
  const selectedItem = data.find((item) => item.segmentId === segmentId);

  const setSelectedRow = (row: AnalyticsSegmentRange) => {
    setSearchParams({ segmentType, startDate, endDate, segmentId: row.segmentId });
  };

  return (
    <Stack spacing={8}>
      <Card p={4}>

        <Flex alignItems='center' gap={2} mb={4} backgroundColor="white" >
          <Heading size='md' fontWeight='normal'>
            {segmentLabel}s from {fromDateStr} to {toDateStr}
          </Heading>
          <Spacer />
          <SegmentDownloadButton
            segmentType={segmentType}
            startDate={startDate}
            endDate={endDate}
          />
        </Flex>

        <OverviewTable
          segmentType={segmentType}
          data={data}
          selectedRow={selectedItem}
          setSelectedRow={setSelectedRow}
        />
      </Card>

      <AnalyticsItem segmentType={segmentType} selectedItem={selectedItem} startDate={startDate} endDate={endDate} />

    </Stack>
  );
}

import { GenderCode, SegmentType, type IAnalyticsSegment } from '../../../types';

export const daysAgo = (days: number) => new Date(new Date().setDate(new Date().getDate() - days));

export const toISODateString = (date: Date) => date.toISOString().split('T')[0];

export function getDateString (date: Date | string) {
  const dtFormat = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return dtFormat.format(new Date(date));
}

// FUTURE These functions give the correct dates in the UK, but do not respect time zones
export const getQuarterStarts = () => {
  const today = new Date();
  const arr = [];
  const start = new Date(2023, 0, 1);
  let date = start;
  let i = 0;
  while (date < today) {
    date = new Date(start.getUTCFullYear(), start.getUTCMonth() + (3 * i++), 1, 12);
    if (date < today) {
      arr.push({
        label: `${date.getUTCFullYear()} Q${Math.floor(date.getUTCMonth() / 3) + 1} Start`,
        date: toISODateString(date),
      });
    }
  }
  return arr.reverse();
};

export const getQuarterEnds = () => {
  const today = new Date();
  const arr = [];
  const start = new Date(2023, 0, 1);
  let date = start;
  let i = 0;
  while (date < today) {
    date = new Date(start.getUTCFullYear(), start.getUTCMonth() + (3 * i++) + 3, 0, 12);
    if (date < today) {
      arr.push({
        label: `${date.getUTCFullYear()} Q${Math.floor(date.getUTCMonth() / 3) + 1} End`,
        date: toISODateString(date),
      });
    }
  }
  return arr.reverse();
};

export function getSegmentLabel (segmentType: SegmentType) {
  switch (segmentType) {
    case SegmentType.FUND_RECIPIENT: return 'Service Provider';
    case SegmentType.FUND: return 'Fund';
    case SegmentType.INVESTMENT: return 'Investment';
    case SegmentType.STATE: return 'Service User Lifecycle';
    case SegmentType.ETHNIC_GROUP: return 'Ethnic Group';
    case SegmentType.GENDER: return 'Gender';
    case SegmentType.AGE_RANGE: return 'Age Range';
    case SegmentType.VULNERABILITY_GROUP: return 'Vulnerability Group';
    case SegmentType.REGION: return 'Geographic Region';
  }
}

export function getGenderLabel (code: GenderCode | null) {
  switch (code) {
    case GenderCode.Male: return 'Male';
    case GenderCode.Female: return 'Female';
    case GenderCode.NotSay: return 'Prefers Not to Say';
    case GenderCode.Other: return 'Other';
    default: return 'Not Provided';
  }
}

export interface AnalyticsSegmentRange {
  segmentId: string
  d1: IAnalyticsSegment
  d2: IAnalyticsSegment
}

// Merge the data from two dates into a single array
export function mergeData (d1: IAnalyticsSegment[], d2: IAnalyticsSegment[]): AnalyticsSegmentRange[] {
  const d1SegmentIds = d1.map(item => item.segmentId);
  const d2SegmentIds = d2.map(item => item.segmentId);
  const allSegmentIds = Array.from(new Set([...d1SegmentIds, ...d2SegmentIds]));

  return allSegmentIds.map(segmentId => {
    const d1Item = d1.find(item => item.segmentId === segmentId) as IAnalyticsSegment;
    const d2Item = d2.find(item => item.segmentId === segmentId) as IAnalyticsSegment;

    return {
      segmentId,
      d1: d1Item,
      d2: d2Item,
    };
  });
}

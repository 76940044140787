import { Box, Card, Center, Heading, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { type IServiceUserOverview } from '../../../../types';
import paths from './ukRegionsPaths.json';

const mapScale = 0.5;

const baseRegionStyle = {
  fill: '#38A169',
  stroke: 'RGBA(0, 0, 0, 0.5)',
  strokeWidth: '1',
  cursor: 'pointer',
};

const rectStyle = {
  fill: 'none',
  stroke: 'RGBA(0, 0, 0, 0.5)',
  strokeWidth: '1',
};

interface RegionData {
  value: number
  path: {
    key: string
    label: string
    toolTip?: JSX.Element
    d: string
  }
}

function Path ({ data, maxValue }: { data: RegionData, maxValue: number }) {
  const [isHover, setIsHover] = useState(false);
  return (
    <Tooltip
      hasArrow
      label={
        <Box>
          <Text>{data.path.label}</Text>
          <Text>{data.value} Service Users</Text>
        </Box>
      }
    >
      <path
        d={data.path.d}
        id={data.path.key}
        style={{
          ...baseRegionStyle,
          fillOpacity: (data.value === 0) ? 0 : data.value / maxValue,
          strokeWidth: isHover ? '3' : '1',
          zIndex: isHover ? 9999 : 0,
        }}
        onMouseEnter={() => { setIsHover(true); }}
        onMouseLeave={() => { setIsHover(false); }}
      />
    </Tooltip>
  );
}

export default function RegionsWidget ({ data }: { data: IServiceUserOverview }) {
  // Data for each region
  const regionData: RegionData[] = [
    {
      value: data.countRegionYorkshireHumber,
      path: paths.yorkshire_and_the_humber
    },
    {
      value: data.countRegionWestMidlands,
      path: paths.west_midlands
    },
    {
      value: data.countRegionWales,
      path: paths.wales
    },
    {
      value: data.countRegionSouthWest,
      path: paths.south_west
    },
    {
      value: data.countRegionSouthEast,
      path: paths.south_east
    },
    {
      value: data.countRegionScotland,
      path: paths.scotland
    },
    {
      value: data.countRegionNorthWest,
      path: paths.north_west
    },
    {
      value: data.countRegionNorthernIreland,
      path: paths.northern_ireland
    },
    {
      value: data.countRegionNorthEast,
      path: paths.north_east
    },
    {
      value: data.countRegionLondon,
      path: paths.london
    },
    {
      value: data.countRegionEastEngland,
      path: paths.east_of_england
    },
    {
      value: data.countRegionEastMidlands,
      path: paths.east_midlands
    },
  ];

  const maxValue = Math.max(...regionData.map(v => v.value));

  return (
    <Card p={4}>
      <Heading as='h3' size='md'>Service Users by Region</Heading>

      <Center mt={4} height={{ base: 'auto', md: '500px' }}>
        <svg width={mapScale * 690} height={mapScale * 982} viewBox='0 0 690 982'>
          <rect width="180" height="275" x="469.79999" y="8" id="shetlands-orkneys-frame" style={rectStyle} />
          {regionData.map((d, i) => (<Path key={d.path.key} data={d} maxValue={maxValue} />))}
        </svg>
      </Center>
    </Card>
  );
}
